import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { gtm } from "../../../lib/gtm";
import CloseIcon from "@mui/icons-material/Close";
import { Link as RouterLink } from "react-router-dom";
import SubjectIcon from '@mui/icons-material/Subject';
import MessageIcon from '@mui/icons-material/Message';

import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import { Scrollbar } from "../../../components/scrollbar";
import { useMounted } from "../../../hooks/use-mounted";
import { topicsApi } from "../../../api/topic-api";
import SearchIcon from "@mui/icons-material/Search";
import { Comment as CommentIcon, Delete, Reply, RestoreFromTrash, Send } from "@mui/icons-material";
import { userApi } from "../../../api/user-api";
import { format } from "date-fns";

const sortOptions = [
  {
    label: "Date de création la plus proche",
    value: "created_at|desc",
  },
  {
    label: "Date de création la plus éloignée",
    value: "created_at|asc",
  },
];

const sortPosts = [
  {
    label: "Message le plus récent",
    value: "created_at|asc",
  },
  {
    label: "Message le plus ancien",
    value: "created_at|desc",
  },
];

const categorieColor = {
  "Problèmes techniques": "#ff471a",
  "Question sur la formation": "#C39E45",
  Autre: "grey",
  Information: "blue",
};

const Forum = () => {
  const isMounted = useMounted();
  const [topics, setTopics] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [answerTopics, setAnswerTopics] = useState(false);
  const [answerPost, setAnswerPost] = useState([]);
  const [sort, setSort] = useState("created_at|desc");
  const [sortPostsOpt, setSortPostsOpt] = useState("");
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [openMessagesView, setOpenMessagesView] = useState(false);
  const [topicIndex, setTopicIndex] = useState(0);
  const [posts, setPosts] = useState([]);
  const [limit, setLimit] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [audits, setAudits] = useState([]);
  const [viewMode, setViewMode] = useState("viewMessages");
  const [selectedItem, setSelectedItem] = useState(null);
  const [postReplies, setPostReplies] = useState(null);

  const { user } = useAuth();

  const answerRef = useRef("");
  const postRef = useRef("");

  const handleDeleteTopic = async () => {
    await topicsApi.deleteTopic(
      user.admin_id,
      paginatedSessions[topicIndex].id
    );
    getTopics();
    let data = await topicsApi.getPostsByTopic(
      user.admin_id,
      paginatedSessions[topicIndex].id
    );
    setPosts(data);
  };

  const handleReactivateTopic = async () => {
    if (viewMode === "viewTopics") {
      await topicsApi.reactivateTopic(
        user.admin_id,
        paginatedSessions[topicIndex].id
      );
      getTopics();
    } else if (viewMode === "viewMessages") {
      await topicsApi.reactivatePost(user.admin_id, selectedItem.id);
      getTopics();
      window.location.reload();
    }
  };

  const handleDeletePost = async (posts_id) => {
    if (viewMode === "viewTopics") {
      await topicsApi.deletePost(user.admin_id, posts_id);
      let data = await topicsApi.getPostsByTopic(
        user.admin_id,
        paginatedSessions[topicIndex].id
      );
      setPosts(data);
    } else if (viewMode === "viewMessages") {
      await topicsApi.deletePost(user.admin_id, posts_id);
      let data = await topicsApi.getPostsByTopic(user.admin_id, posts_id);
      setPosts(data);
      window.location.reload();
    }
  };

  const handleReactivatePost = async (posts_id) => {
    await topicsApi.reactivatePost(user.admin_id, posts_id);
    let data = await topicsApi.getPostsByTopic(
      user.admin_id,
      paginatedSessions[topicIndex].id
    );
    setPosts(data);
    if(viewMode === "viewMessages"){
      window.location.reload();
    }
  };

  const handleDeleteReply = async (reply_id) => {
    await topicsApi.deleteReply(user.admin_id, reply_id);
    let data = await topicsApi.getPostsByTopic(
      user.admin_id,
      paginatedSessions[topicIndex].id
    );
    setPosts(data);
    if(viewMode === "viewMessages"){
      window.location.reload();
    }
  };

  const handleReactivateReply = async (reply_id) => {
    await topicsApi.reactivateReply(user.admin_id, reply_id);
    let data = await topicsApi.getPostsByTopic(
      user.admin_id,
      paginatedSessions[topicIndex].id
    );
    setPosts(data);
    if(viewMode === "viewMessages"){
      window.location.reload();
    }
  };

  const updateFieldPost = (index) => {
    let updatedAnswerPost = [...answerPost];
    if (!updatedAnswerPost[index]) {
      updatedAnswerPost = Array(answerPost.length).fill(false);
      updatedAnswerPost[index] = true;
    } else {
      updatedAnswerPost[index] = false;
    }
    setAnswerTopics(false);
    setAnswerPost(updatedAnswerPost);
  };

  const updateFieldTopic = () => {
    let updatedAnswerPost = [...answerPost];
    updatedAnswerPost = Array(answerPost.length).fill(false);

    setAnswerPost(updatedAnswerPost);
    setAnswerTopics(!answerTopics);
  };

  const handleSendClick = async (topics_id) => {
    const answer = answerRef.current.value;
    await topicsApi.createPost(user.admin_id, topics_id, answer);
    setAnswerTopics(false);
    handleClickOpen(topics_id, topicIndex);
  };

  const handleSendClickPost = async (topics_id, posts_id, index) => {
    const postAnswer = postRef.current.value;
    await topicsApi.createReply(user.admin_id, topics_id, posts_id, postAnswer);
    updateFieldPost(index);
    if (viewMode === "viewTopics") {
      handleClickOpen(topics_id, topicIndex);
    } else if (viewMode === "viewMessages") {
      handleClickOpen(posts_id, topicIndex);
    }
  };

  const handleClickOpen = async (id, index) => {
    if (viewMode === "viewTopics") {
      let data = await topicsApi.getPostsByTopic(user.admin_id, id);
      setPosts(data);
      setTopicIndex(index);
      setOpen(true);
      setAnswerPost(Array(data.length).fill(false));
    } else if (viewMode === "viewMessages") {
      setOpenMessagesView(false);
    }
  };
  const handleClickOpenMessagesView = async (item) => {
    setSelectedItem(item);
    setOpenMessagesView(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseMessagesView = () => {
    setSelectedItem(null);
    setOpenMessagesView(false);
  };

  const handleViewModeChange = (e, value) => {
    setViewMode(value);
  };

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
    if (viewMode === "viewTopics") {
      setFilteredTopics(
        topics.filter(
          (el) =>
            `${el.title}`
              .toLowerCase()
              .includes(event.target.value.toLowerCase()) ||
            `${el.body}`
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
        )
      );
      setPage(0);
    } else if (viewMode === "viewMessages") {
      setFilteredMessages(
        allPosts.filter((el) =>
          `${el.body}`.toLowerCase().includes(event.target.value.toLowerCase())
        )
      );
      setPage(0);
    }
  };

  const handleChangeAuditSearch = (value) => {
    if (viewMode === "viewTopics") {
      setFilteredTopics(
        topics.filter((el) =>
          `${el.audit}`.toLowerCase().includes(value.toLowerCase())
        )
      );
      setPage(0);
    } else if (viewMode === "viewMessages") {
      setFilteredMessages(
        allPosts.filter((el) =>
          `${el.audit_name}`.toLowerCase().includes(value.toLowerCase())
        )
      );
      setPage(0);
    }
  };

  const handleSortChange = (event, value) => {
    if (typeof event.target === "undefined") {
      setSort(value);
    } else {
      setSort(event.target.value);
    }
  };

  const handleSortPostsChange = (event, value) => {
    if (typeof event.target === "undefined") {
      setSortPostsOpt(value);
    } else {
      setSortPostsOpt(event.target.value);
    }
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const formatDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const getTopics = useCallback(async () => {
    try {
      const data = await topicsApi.getAll(user.admin_id);
      const formattedData = data.map(topic => ({
        ...topic,
        created_at: formatDate(topic.created_at),
      }));
      if (isMounted()) {
        setTopics(formattedData);
        setFilteredTopics(formattedData);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const formatDatePost = (dateString) => {
    // Séparer la date et l'heure
    const [datePart, timePart] = dateString.split(' ');
    // Formater la date
    const [day, month, year] = datePart.split('/');
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    // Retourner la date formatée avec l'heure d'origine
    return `${formattedDate}`;
  };

  const getAllPosts = useCallback(async () => {
    try {
      const data = await topicsApi.getAllPosts(user.admin_id);
      const formattedData = data.map(post => ({
        ...post,
        created_at: formatDatePost(post.created_at),
      }));
      if (isMounted()) {
        setAllPosts(formattedData);
        setFilteredMessages(formattedData);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const applySort = (data, sort) => {
    const [orderBy, order] = sort.split("|");
    const sortedData = [...data]; // Copie des données pour éviter la mutation

    sortedData.sort((a, b) => {
      let comparison = 0;

      // Comparaison en fonction de la clé de tri
      if (a[orderBy] > b[orderBy]) {
        comparison = 1;
      } else if (a[orderBy] < b[orderBy]) {
        comparison = -1;
      }

      // Inversion si l'ordre est descendant
      if (order === 'desc') {
        comparison *= -1;
      }

      return comparison;
    });

    return sortedData;
  };

  const applyPagination = (sessions, page, limit) =>
    sessions.slice(page * limit, page * limit + limit);

  const sortedTopics = applySort(filteredTopics, sort);
  const paginatedSessions = applyPagination(sortedTopics, page, limit);

  const sortedPosts = applySort(posts, sortPostsOpt);

  useEffect(() => {
    getTopics();
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    getGraphics();
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    getAllPosts();
    gtm.push({ event: "page_view" });
  }, []);

  const sortedAllPosts = applySort(filteredMessages, sort);
  const paginatedPosts = applyPagination(sortedAllPosts, page, limit);
  const getGraphics = useCallback(async () => {
    try {
      const data = await userApi.getEppByType(user.admin_id, "audit");
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    if (selectedAudits.length) {
      setSelectedAudits([]);
    }
  }, [topics]);

  const handleSearchChange = (event, value) => {
    setSearchTerm(value);
  };

  const handleSelectAudit = (event, value) => {
    setSelectedAudit(value);
    if (value && value.name) {
      handleChangeAuditSearch(value.name);
    } else {
      // Si value n'existe pas ou ne possède pas de propriété 'name', réinitialisez les filtres
      setFilteredTopics(topics);
      setFilteredMessages(allPosts);
      setPage(0);
    }
  };

  // useEffect(() => {
  //   console.log(selectedItem);
  // }, [selectedItem]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 11,
        }}
      >
        <Container maxWidth="x1">
          <Box sx={{ mb: 5 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#F5F6FC" : "#1c2531",
                      fontFamily: "Montserrat"
                  }}
                  variant="h4"
                >
                  Administration du forum
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: -1,
                p: 3,
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: "100%",
                  width: 450,
                }}
              >
                <TextField
                  value={searchName}
                  onChange={handleChangeSearch}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Chercher un sujet"
                />
              </Box>
              <Box
                sx={{
                  m: 1,
                  width: 300,
                }}
              >
                <TextField
                  label="Trier par"
                  name="sort"
                  onChange={handleSortChange}
                  select
                  SelectProps={{ native: true }}
                  value={sort}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                >
                  {sortOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Box>
              <Box
                sx={{
                  m: 1,
                  width: 300,
                }}
              >
                <Autocomplete
                  value={selectedAudit}
                  onChange={handleSelectAudit}
                  inputValue={searchTerm}
                  onInputChange={handleSearchChange}
                  options={audits}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Chercher par audit"
                      variant="outlined"
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  width: '10%',
                  marginLeft :'auto'
                }}
              >
                {/* Votre ToggleButtonGroup pour les deux états */}
                <ToggleButtonGroup
                  value={viewMode} // 'viewMode' serait votre état actuel, utilisez l'état de votre composant
                  exclusive
                  onChange={handleViewModeChange} // Fonction de gestion du changement d'état
                  aria-label="text alignment"
                >
                  <Tooltip title="Vue Sujets" arrow>
                    <ToggleButton
                      value="viewTopics"
                      disabled={viewMode === "viewTopics"}
                    >
                      <SubjectIcon />
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip title="Vue Messages" arrow>
                    <ToggleButton
                      value="viewMessages"
                      disabled={viewMode === "viewMessages"}
                    >
                      <CommentIcon />
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Box>
            </Box>
            <Scrollbar>
              {viewMode && viewMode === "viewTopics" && (
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ p: 2 }}>
                        <Typography
                          sx={{ mx: 3 }}
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Sujet
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13} width={150}>
                          Date de création
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Audit associé
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Catégorie
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Créateur
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedSessions
                      ? paginatedSessions.map((item, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              backgroundColor: item.is_deleted ? "#cbd0d2" : "",
                              "&.MuiTableRow-root:hover": {
                                backgroundColor: item.is_deleted
                                  ? "#cbd0d2"
                                  : "",
                              },
                            }}
                          >
                            <TableCell>
                              <Box padding={1}>
                                <Button
                                  onClick={() =>
                                    handleClickOpen(item.id, index)
                                  }
                                >
                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      textAlign={"left"}
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? "#F5F6FC"
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontSize={18}
                                      fontWeight="bold"
                                    >
                                      {item.title.charAt(0).toUpperCase() +
                                        item.title.slice(1)}
                                    </Typography>
                                    {item.is_deleted ? (
                                      <Typography
                                        textAlign={"left"}
                                        fontSize={15}
                                        fontStyle={"italic"}
                                        color={"red"}
                                      >
                                        [Supprimé]
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                    <Typography
                                      textAlign={"left"}
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? "#F5F6FC"
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontSize={15}
                                    >
                                      {item.body}
                                    </Typography>
                                  </Box>
                                </Button>
                                <Dialog
                                  fullWidth
                                  maxWidth={"lg"}
                                  onClose={handleClose}
                                  open={open}
                                >
                                  <AppBar sx={{ position: "relative" }}>
                                    <Toolbar>
                                      <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleClose}
                                        aria-label="close"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                      <Typography
                                        textAlign={"center"}
                                        sx={{ flex: 1 }}
                                        color="secondary"
                                        variant="h4"
                                        component="div"
                                      >
                                        {paginatedSessions[topicIndex].title
                                          .charAt(0)
                                          .toUpperCase() +
                                          paginatedSessions[
                                            topicIndex
                                          ].title.slice(1)}
                                      </Typography>
                                    </Toolbar>
                                  </AppBar>
                                  <Grid>
                                    <DialogContent>
                                      <Grid marginTop={2}>
                                        <Card>
                                          <Grid
                                            backgroundColor={
                                              paginatedSessions[topicIndex]
                                                .is_deleted
                                                ? "#6B6E7B"
                                                : "#4A5076"
                                            }
                                          >
                                            <Typography
                                              color={"white"}
                                              display="inline"
                                              paddingTop={1}
                                              marginLeft={1}
                                              fontSize={13}
                                              fontWeight="bold"
                                            >
                                              {
                                                paginatedSessions[topicIndex]
                                                  .user
                                              }
                                            </Typography>

                                            <Typography
                                              color={"white"}
                                              display="inline"
                                              paddingTop={1}
                                              fontSize={14}
                                            >
                                              {" le " +
                                                paginatedSessions[topicIndex]
                                                  .created_at}
                                            </Typography>
                                            {paginatedSessions[topicIndex]
                                              .is_deleted ? (
                                              <Typography
                                                display="inline"
                                                paddingTop={1}
                                                fontSize={15}
                                                fontStyle={"italic"}
                                                marginLeft={"2%"}
                                                color={"darkred"}
                                              >
                                                [Supprimé]
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                            <Typography
                                              color={"white"}
                                              marginTop={1}
                                              marginLeft={3}
                                              typography={"h5"}
                                            >
                                              {paginatedSessions[
                                                topicIndex
                                              ].title
                                                .charAt(0)
                                                .toUpperCase() +
                                                paginatedSessions[
                                                  topicIndex
                                                ].title.slice(1)}
                                            </Typography>
                                            <Typography
                                              color={"white"}
                                              paddingTop={1}
                                              paddingBottom={2}
                                              marginLeft={3}
                                            >
                                              {
                                                paginatedSessions[topicIndex]
                                                  .body
                                              }
                                            </Typography>
                                            <Grid display={"flex"}>
                                              <Grid
                                                marginLeft={"auto"}
                                                marginRight={"1%"}
                                              >
                                                {paginatedSessions[topicIndex]
                                                  .is_deleted ? (
                                                  <RestoreFromTrash
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    sx={{ color: "lightgrey" }}
                                                    onClick={() =>
                                                      handleReactivateTopic()
                                                    }
                                                  />
                                                ) : (
                                                  <Grid>
                                                    <Reply
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      sx={{
                                                        color: "lightgrey",
                                                      }}
                                                      onClick={() =>
                                                        updateFieldTopic()
                                                      }
                                                    />
                                                    <Delete
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      sx={{
                                                        color: "lightgrey",
                                                      }}
                                                      onClick={() =>
                                                        handleDeleteTopic()
                                                      }
                                                    />
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </Grid>
                                            <Grid fullWidth p={1}>
                                              {answerTopics ? (
                                                <Grid display={"flex"}>
                                                  <TextField
                                                    inputRef={answerRef}
                                                    label="Entrez une réponse au topic"
                                                    fullWidth
                                                    sx={{
                                                      input: { color: "white" },
                                                      "& .MuiOutlinedInput-root.Mui-focused":
                                                        {
                                                          "& > fieldset": {
                                                            borderColor:
                                                              "white",
                                                          },
                                                        },
                                                      "& .MuiFormLabel-root": {
                                                        color: "#dfe1e6",
                                                      },
                                                      "& .MuiOutlinedInput-root:hover":
                                                        {
                                                          "& > fieldset": {
                                                            borderColor:
                                                              "white",
                                                          },
                                                        },
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                        handleSendClick(
                                                          paginatedSessions[
                                                            topicIndex
                                                          ].id
                                                        );
                                                      }
                                                    }}
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleSendClick(
                                                              paginatedSessions[
                                                                topicIndex
                                                              ].id
                                                            )
                                                          }
                                                        >
                                                          <Send />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </Grid>
                                              ) : (
                                                //else
                                                ""
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Card>
                                        <Grid marginTop={3}>
                                          <TextField
                                            label="Trier par"
                                            name="sort"
                                            onChange={handleSortPostsChange}
                                            select
                                            SelectProps={{ native: true }}
                                            value={sortPostsOpt}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                          >
                                            {sortPosts.map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid>
                                          {sortedPosts.map((item, index) => (
                                            <Grid marginTop={4} key={index}>
                                              <Card>
                                                <Grid
                                                  backgroundColor={
                                                    item.is_deleted
                                                      ? "#cbd0d2"
                                                      : "#EBEEFF"
                                                  }
                                                >
                                                  <Typography
                                                    display="inline"
                                                    paddingTop={1}
                                                    marginLeft={1}
                                                    fontSize={13}
                                                    fontWeight="bold"
                                                  >
                                                    {item.user}
                                                  </Typography>
                                                  <Typography
                                                    display="inline"
                                                    paddingTop={1}
                                                    fontSize={14}
                                                  >
                                                    {" le " + item.created_at}
                                                  </Typography>
                                                  {item.is_deleted ? (
                                                    <Typography
                                                      display="inline"
                                                      paddingTop={1}
                                                      fontSize={15}
                                                      fontStyle={"italic"}
                                                      marginLeft={"2%"}
                                                      color={"red"}
                                                    >
                                                      [Supprimé]
                                                    </Typography>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <Typography
                                                    paddingTop={0.5}
                                                    marginLeft={1}
                                                    paddingLeft={2}
                                                  >
                                                    {item.body}
                                                  </Typography>
                                                  {!paginatedSessions[
                                                    topicIndex
                                                  ].is_deleted ? (
                                                    <Grid display={"flex"}>
                                                      <Grid
                                                        marginLeft={"auto"}
                                                        marginRight={"1%"}
                                                      >
                                                        {item.is_deleted ? (
                                                          <RestoreFromTrash
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            sx={{
                                                              color: "#4A5076",
                                                            }}
                                                            onClick={() =>
                                                              handleReactivatePost(
                                                                item.id
                                                              )
                                                            }
                                                          />
                                                        ) : (
                                                          <>
                                                            <Reply
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              sx={{
                                                                color:
                                                                  "#4A5076",
                                                              }}
                                                              onClick={() =>
                                                                updateFieldPost(
                                                                  index
                                                                )
                                                              }
                                                            />
                                                            <Delete
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              sx={{
                                                                color:
                                                                  "#4A5076",
                                                              }}
                                                              onClick={() =>
                                                                handleDeletePost(
                                                                  item.id
                                                                )
                                                              }
                                                            />
                                                          </>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {answerPost[index] ? (
                                                    <Grid
                                                      display={"flex"}
                                                      p={0.5}
                                                    >
                                                      <TextField
                                                        inputRef={postRef}
                                                        label="Entrez une réponse au post"
                                                        fullWidth
                                                        sx={{
                                                          borderColor: "black",
                                                          input: {
                                                            color: "#000000DD",
                                                          },
                                                          "& .MuiOutlinedInput-root":
                                                            {
                                                              "& fieldset": {
                                                                borderColor:
                                                                  "black",
                                                              },
                                                              "&:hover fieldset":
                                                                {
                                                                  borderColor:
                                                                    "black",
                                                                },
                                                              "&.Mui-focused fieldset":
                                                                {
                                                                  borderColor:
                                                                    "black",
                                                                },
                                                            },
                                                        }}
                                                        onKeyDown={(e) => {
                                                          if (
                                                            e.key === "Enter"
                                                          ) {
                                                            handleSendClickPost(
                                                              paginatedSessions[
                                                                topicIndex
                                                              ].id,
                                                              item.id,
                                                              index
                                                            );
                                                          }
                                                        }}
                                                        InputProps={{
                                                          endAdornment: (
                                                            <InputAdornment
                                                              position="end"
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() =>
                                                                handleSendClickPost(
                                                                  paginatedSessions[
                                                                    topicIndex
                                                                  ].id,
                                                                  item.id,
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <Send />
                                                            </InputAdornment>
                                                          ),
                                                        }}
                                                      />
                                                    </Grid>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Grid>
                                              </Card>
                                              <Grid marginLeft={7}>
                                                {item.replies.map(
                                                  (reply, index_reply) => (
                                                    <Grid
                                                      marginTop={2}
                                                      key={index_reply}
                                                    >
                                                      <Card>
                                                        <Grid
                                                          backgroundColor={
                                                            reply.is_deleted
                                                              ? "#cbd0d2"
                                                              : "#F7F8FF"
                                                          }
                                                        >
                                                          <Typography
                                                            display="inline"
                                                            paddingTop={1}
                                                            marginLeft={1}
                                                            fontSize={13}
                                                            fontWeight="bold"
                                                          >
                                                            {reply.user}
                                                          </Typography>
                                                          <Typography
                                                            display="inline"
                                                            paddingTop={1}
                                                            fontSize={14}
                                                          >
                                                            {" le " +
                                                              reply.created_at}
                                                          </Typography>
                                                          {reply.is_deleted ? (
                                                            <Typography
                                                              display="inline"
                                                              paddingTop={1}
                                                              fontSize={14}
                                                              fontStyle={
                                                                "italic"
                                                              }
                                                              marginLeft={
                                                                "1.5%"
                                                              }
                                                              color={"red"}
                                                            >
                                                              [Supprimé]
                                                            </Typography>
                                                          ) : (
                                                            ""
                                                          )}
                                                          <Typography
                                                            paddingTop={0.5}
                                                            marginLeft={1}
                                                            paddingLeft={2}
                                                            paddingBottom={1}
                                                          >
                                                            {reply.body}
                                                          </Typography>
                                                          <Grid
                                                            display={"flex"}
                                                          >
                                                            {!paginatedSessions[
                                                              topicIndex
                                                            ].is_deleted &&
                                                            !item.is_deleted ? (
                                                              <Grid
                                                                marginLeft={
                                                                  "auto"
                                                                }
                                                                marginRight={
                                                                  "1%"
                                                                }
                                                              >
                                                                {reply.is_deleted ? (
                                                                  <RestoreFromTrash
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    sx={{
                                                                      color:
                                                                        "#4A5076",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleReactivateReply(
                                                                        reply.id
                                                                      )
                                                                    }
                                                                  />
                                                                ) : (
                                                                  <Delete
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    sx={{
                                                                      color:
                                                                        "#4A5076",
                                                                    }}
                                                                    onClick={() =>
                                                                      handleDeleteReply(
                                                                        reply.id
                                                                      )
                                                                    }
                                                                  />
                                                                )}
                                                              </Grid>
                                                            ) : (
                                                              ""
                                                            )}
                                                          </Grid>
                                                        </Grid>
                                                      </Card>
                                                    </Grid>
                                                  )
                                                )}
                                              </Grid>
                                            </Grid>
                                          ))}
                                        </Grid>
                                      </Grid>
                                    </DialogContent>
                                  </Grid>
                                </Dialog>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box sx={{ ml: 1 }}>
                                <Typography
                                  color={(theme) =>
                                    theme.palette.mode === "dark"
                                      ? "#F5F6FC"
                                      : process.env.REACT_APP_PRIMARY_COLOR
                                  }
                                  fontSize={18}
                                >
                                  {item.created_at}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box sx={{ ml: 1 }}>
                                <Typography
                                  color={(theme) =>
                                    theme.palette.mode === "dark"
                                      ? "#F5F6FC"
                                      : process.env.REACT_APP_PRIMARY_COLOR
                                  }
                                  fontSize={18}
                                >
                                  {item.audit}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box sx={{ ml: 1 }}>
                                <Typography
                                  fontSize={18}
                                  fontWeight="bold"
                                  color={categorieColor[item.categorie]}
                                >
                                  {item.categorie}
                                </Typography>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box sx={{ ml: 1 }}>
                                <RouterLink
                                  to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants/${item.user_id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    fontSize={18}
                                  >
                                    {item.user}
                                  </Typography>
                                </RouterLink>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      : NULL}
                  </TableBody>
                </Table>
              )}
              {viewMode && viewMode === "viewMessages" && (
                <Box>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ p: 2 }}>
                          <Typography
                            sx={{ mx: 3 }}
                            fontWeight="Bold"
                            fontSize={13}
                          >
                            Message
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="Bold" fontSize={13} width={100}>
                            Date de création
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="Bold" fontSize={13}>
                            Audit associé
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="Bold" fontSize={13}>
                            Catégorie
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography fontWeight="Bold" fontSize={13}>
                            Créateur
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {paginatedPosts
                      ? paginatedPosts.map((item, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              backgroundColor: item.is_deleted ? "#cbd0d2" : "",
                              "&.MuiTableRow-root:hover": {
                                backgroundColor: item.is_deleted
                                  ? "#cbd0d2"
                                  : "",
                              },
                            }}
                          >
                            <TableCell sx={{ p: 2 }}>
                              <Box padding={1}>
                                <Button
                                  onClick={() =>
                                    handleClickOpenMessagesView(item)
                                  }
                                >
                                  <Box sx={{ ml: 1 }}>
                                    <Typography
                                      textAlign={"left"}
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? "#F5F6FC"
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontSize={18}
                                    >
                                      {item.body.charAt(0).toUpperCase() +
                                        item.body.slice(1)}
                                    </Typography>
                                    {item.is_deleted ? (
                                      <Typography
                                        textAlign={"left"}
                                        fontSize={15}
                                        fontStyle={"italic"}
                                        color={"red"}
                                      >
                                        [Supprimé]
                                      </Typography>
                                    ) : (
                                      ""
                                    )}
                                    {/* <Typography
                                      textAlign={"left"}
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? "#F5F6FC"
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontSize={15}
                                    >
                                      {item.replies.length === 0
                                        ? "Il n'y a pas de réponses pour ce message"
                                        : item.replies.length === 1
                                        ? `Il y a ${item.replies.length} réponse pour ce message`
                                        : `Il y a ${item.replies.length} réponses pour ce message`}
                                    </Typography> */}
                                  </Box>
                                </Button>
                              </Box>
                              {selectedItem && (
                                <Dialog
                                  fullWidth
                                  maxWidth={"lg"}
                                  onClose={handleCloseMessagesView}
                                  open={openMessagesView}
                                >
                                  <AppBar sx={{ position: "relative" }}>
                                    <Toolbar>
                                      <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={handleCloseMessagesView}
                                        aria-label="close"
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                      <Typography
                                        textAlign={"center"}
                                        sx={{ flex: 1 }}
                                        color="secondary"
                                        variant="h4"
                                        component="div"
                                      >
                                        {selectedItem.body
                                          .charAt(0)
                                          .toUpperCase() +
                                          selectedItem.body.slice(1)}
                                      </Typography>
                                    </Toolbar>
                                  </AppBar>
                                  <Grid>
                                    <DialogContent>
                                      <Grid marginTop={2}>
                                        <Card>
                                          <Grid
                                            backgroundColor={
                                              selectedItem.is_deleted
                                                ? "#6B6E7B"
                                                : "#4A5076"
                                            }
                                          >
                                            <Typography
                                              color={"white"}
                                              display="inline"
                                              paddingTop={1}
                                              marginLeft={1}
                                              fontSize={13}
                                              fontWeight="bold"
                                            >
                                              {selectedItem.user}
                                            </Typography>

                                            <Typography
                                              color={"white"}
                                              display="inline"
                                              paddingTop={1}
                                              fontSize={14}
                                            >
                                              {" le " + selectedItem.created_at}
                                            </Typography>
                                            {selectedItem.is_deleted ? (
                                              <Typography
                                                display="inline"
                                                paddingTop={1}
                                                fontSize={15}
                                                fontStyle={"italic"}
                                                marginLeft={"2%"}
                                                color={"darkred"}
                                              >
                                                [Supprimé]
                                              </Typography>
                                            ) : (
                                              ""
                                            )}
                                            <Typography
                                              color={"white"}
                                              paddingTop={1}
                                              paddingBottom={2}
                                              marginLeft={3}
                                              typography={"h5"}
                                            >
                                              {selectedItem.body.toUpperCase()}
                                            </Typography>
                                            <Grid display={"flex"}>
                                              <Grid
                                                marginLeft={"auto"}
                                                marginRight={"1%"}
                                              >
                                                {selectedItem.is_deleted ? (
                                                  <RestoreFromTrash
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    sx={{ color: "lightgrey" }}
                                                    onClick={() =>
                                                      handleReactivateTopic()
                                                    }
                                                  />
                                                ) : (
                                                  <Grid>
                                                    <Reply
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      sx={{
                                                        color: "lightgrey",
                                                      }}
                                                      onClick={() =>
                                                        updateFieldTopic()
                                                      }
                                                    />
                                                    <Delete
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      sx={{
                                                        color: "lightgrey",
                                                      }}
                                                      onClick={() =>
                                                        handleDeletePost(
                                                          selectedItem.id
                                                        )
                                                      }
                                                    />
                                                  </Grid>
                                                )}
                                              </Grid>
                                            </Grid>
                                            <Grid fullWidth p={1}>
                                              {selectedItem.replies ? (
                                                <Grid display={"flex"}>
                                                  <TextField
                                                    inputRef={postRef}
                                                    label="Entrez une réponse au message"
                                                    fullWidth
                                                    sx={{
                                                      input: { color: "white" },
                                                      "& .MuiOutlinedInput-root.Mui-focused":
                                                        {
                                                          "& > fieldset": {
                                                            borderColor:
                                                              "white",
                                                          },
                                                        },
                                                      "& .MuiFormLabel-root": {
                                                        color: "#dfe1e6",
                                                      },
                                                      "& .MuiOutlinedInput-root:hover":
                                                        {
                                                          "& > fieldset": {
                                                            borderColor:
                                                              "white",
                                                          },
                                                        },
                                                    }}
                                                    onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                        handleSendClickPost(
                                                          selectedItem.topics_id,
                                                          selectedItem.id,
                                                          index
                                                        );
                                                      }
                                                    }}
                                                    InputProps={{
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleSendClickPost(
                                                              selectedItem.topics_id,
                                                              selectedItem.id,
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <Send />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </Grid>
                                              ) : (
                                                //else
                                                ""
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Card>
                                        <Grid marginTop={3}>
                                          <TextField
                                            label="Trier par"
                                            name="sort"
                                            onChange={handleSortPostsChange}
                                            select
                                            SelectProps={{ native: true }}
                                            value={sortPostsOpt}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                          >
                                            {sortPosts.map((option) => (
                                              <option
                                                key={option.value}
                                                value={option.value}
                                              >
                                                {option.label}
                                              </option>
                                            ))}
                                          </TextField>
                                        </Grid>
                                        <Grid>
                                          {selectedItem &&
                                            selectedItem.replies.map(
                                              (item, index) => (
                                                <Grid marginTop={4} key={index}>
                                                  <Card>
                                                    <Grid
                                                      backgroundColor={
                                                        item.is_deleted
                                                          ? "#cbd0d2"
                                                          : "#EBEEFF"
                                                      }
                                                    >
                                                      <Typography
                                                        display="inline"
                                                        paddingTop={1}
                                                        marginLeft={1}
                                                        fontSize={13}
                                                        fontWeight="bold"
                                                      >
                                                        {item.user}
                                                      </Typography>
                                                      <Typography
                                                        display="inline"
                                                        paddingTop={1}
                                                        fontSize={14}
                                                      >
                                                        {" le " +
                                                          item.created_at}
                                                      </Typography>
                                                      {item.is_deleted ? (
                                                        <Typography
                                                          display="inline"
                                                          paddingTop={1}
                                                          fontSize={15}
                                                          fontStyle={"italic"}
                                                          marginLeft={"2%"}
                                                          color={"red"}
                                                        >
                                                          [Supprimé]
                                                        </Typography>
                                                      ) : (
                                                        ""
                                                      )}
                                                      <Typography
                                                        paddingTop={0.5}
                                                        marginLeft={1}
                                                        paddingLeft={2}
                                                      >
                                                        {item.body}
                                                      </Typography>
                                                      <Grid display={"flex"}>
                                                        <Grid
                                                          marginLeft={"auto"}
                                                          marginRight={"1%"}
                                                        >
                                                          {item.is_deleted ? (
                                                            <RestoreFromTrash
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              sx={{
                                                                color:
                                                                  "#4A5076",
                                                              }}
                                                              onClick={() =>
                                                                handleReactivateReply(
                                                                  item.id
                                                                )
                                                              }
                                                            />
                                                          ) : (
                                                            <Delete
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              sx={{
                                                                color:
                                                                  "#4A5076",
                                                              }}
                                                              onClick={() =>
                                                                handleDeleteReply(
                                                                  item.id
                                                                )
                                                              }
                                                            />
                                                          )}
                                                        </Grid>
                                                      </Grid>
                                                      {/* {!selectedItem.is_deleted ? (
                                                        <Grid display={"flex"}>
                                                          <Grid
                                                            marginLeft={"auto"}
                                                            marginRight={"1%"}
                                                          >
                                                            {item.is_deleted ? (
                                                              <RestoreFromTrash
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                sx={{
                                                                  color:
                                                                    "#4A5076",
                                                                }}
                                                                onClick={() =>
                                                                  handleReactivatePost(
                                                                    item.id
                                                                  )
                                                                }
                                                              />
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </Grid>
                                                        </Grid>
                                                      ) : (
                                                        ""
                                                      )} */}
                                                    </Grid>
                                                  </Card>
                                                  <Grid marginLeft={7}></Grid>
                                                </Grid>
                                              )
                                            )}
                                        </Grid>
                                      </Grid>
                                    </DialogContent>
                                  </Grid>
                                </Dialog>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: 18 }}
                              color={(theme) =>
                                theme.palette.mode === "dark"
                                  ? "#F5F6FC"
                                  : process.env.REACT_APP_PRIMARY_COLOR
                              }
                            >
                              {item.created_at}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: 18 }}
                              color={(theme) =>
                                theme.palette.mode === "dark"
                                  ? "#F5F6FC"
                                  : process.env.REACT_APP_PRIMARY_COLOR
                              }
                            >
                              {item.audit_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: 18,
                                fontWeight: "bold",
                                color: categorieColor[item.category_name],
                              }}
                            >
                              {item.category_name}
                            </TableCell>
                            <TableCell
                              sx={{ fontSize: 18 }}
                              color={(theme) =>
                                theme.palette.mode === "dark"
                                  ? "#F5F6FC"
                                  : process.env.REACT_APP_PRIMARY_COLOR
                              }
                            >
                              {item.user}
                            </TableCell>
                          </TableRow>
                        ))
                      : null}
                  </Table>
                </Box>
              )}
            </Scrollbar>
            {viewMode && viewMode === "viewTopics" && (
              <TablePagination
                component="div"
                count={topics.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                labelRowsPerPage="Lignes par page :"
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              />
            )}
            {viewMode && viewMode === "viewMessages" && (
              <TablePagination
                component="div"
                count={allPosts.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                labelRowsPerPage="Lignes par page :"
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
              />
            )}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Forum;

import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
  Divider,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent, 
  DialogActions,
  CardContent,
  Grid, // Ajout de Grid pour le design du messag
  MenuItem,
  Select,
    
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
  import { Scrollbar } from '../../scrollbar';
  import SearchIcon from '@mui/icons-material/Search';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import { Link as RouterLink } from 'react-router-dom';
  import { auditsApi } from '../../../api/audit-api';
  import { useAuth } from '../../../hooks/use-auth';
  import { useNavigate } from "react-router-dom";
  import { Delete as DeleteIcon, FileCopy as DuplicateIcon } from '@mui/icons-material';
  import toast from 'react-hot-toast';
import LockIcon from "@mui/icons-material/Lock";
import { useState, useEffect } from 'react';


export const TcsList = (props) => {
  const { tcs, getGraphics } = props;
  const { user } = useAuth();
  const [filteredTcs, setFilteredTcs] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [createBool, setCreateBool] = useState(false);
  const [createTcsName, setCreateTcsName] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [selectedTCSId, setSelectedTCSId] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();

  const [disableDialogOpen, setDisableDialogOpen] = useState(false);
  const [enableDialogOpen, setEnableDialogOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(true);
  // Fonction pour vérifier si l'utilisateur a une permission spécifique
  const hasPermission = (component, requiredPermission) => {
    return permissions.some(
      (perm) => perm.component === component && perm.permission === requiredPermission
    );
  };

  // Vérifie si l'utilisateur a la permission "Voir" pour le composant "EPP"
  const hasViewPermission = hasPermission("EPP", "Voir");

  // Vérifie si l'utilisateur a la permission "create" pour le composant "EPP"
  const hasCreatePermission = hasPermission("EPP", "Créer");

  useEffect(() => {
    setFilteredTcs(tcs);
  }, [tcs]);

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
    setFilteredTcs(tcs.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())));
  };

  const handleCreateTcs = async () => {
    const id = await auditsApi.createAudit(user.admin_id, createTcsName, "TCS");
    navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/tcs/${id.id}`);
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleDuplicateClick = (id) => {
    setSelectedTCSId(id);
    setDuplicateDialogOpen(true);
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
   const handleDisableClick = (id) => {
    setSelectedTCSId(id);
    setDisableDialogOpen(true);
  };
  const handleDisableCancel = () => {
    setSelectedTCSId(null);
    setDisableDialogOpen(false);
  };
   const handleEnableClick = (id) => {
    setSelectedTCSId(id);
    setEnableDialogOpen(true);
  };
  const handleEnableCancel = () => {
    setSelectedTCSId(null);
    setEnableDialogOpen(false);
  };
  const handleConfirmDisable  = async () => {
    try{
      const data = await auditsApi.DisableEPP_ById(user.admin_id, selectedTCSId);
      getGraphics()
      setFilteredTcs(tcs)
      setDisableDialogOpen(false)
      
      toast.success('TCS désactivé');
    } catch (error) {
      setDisableDialogOpen(false)
      toast.error("Désactivation échouée");
      console.error("Erreur lors de la désactivation de l'audit", error);
    }
  }
  const handleConfirmEnableClick  = async () => {
    try{
      const data = await auditsApi.DisableEPP_ById(user.admin_id, selectedTCSId);
      getGraphics()
      setFilteredTcs(tcs)
      setEnableDialogOpen(false)
      
      toast.success('TCS Activé');
    } catch (error) {
      setEnableDialogOpen(false)
      toast.error("Activation échouée");
      console.error("Erreur lors de la désactivation de l'audit", error);
    }
  }
  const filteredResults = filteredTcs.filter((result) => result.activated === selectedCategory);
  const handleCancel = () => {
    setSelectedTCSId(null);
    setDuplicateDialogOpen(false);
  };

  const handleConfirmDuplicate = async () => {
    try {
      const data = await auditsApi.DuplicateTCSById(user.admin_id, selectedTCSId);
      getGraphics();
      setFilteredTcs(tcs);
      setDuplicateDialogOpen(false);
      toast.success('TCS dupliqué');
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/tcs/${data.new_tcs_id}`);
    } catch (error) {
      console.error("Erreur lors de la duplication du TCS", error);
    }
  };

  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = { roles_id };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);

  return (
    <>
      {/* Afficher le bouton "Créer un TCS" si l'utilisateur a la permission "create" */}
      {hasCreatePermission && (
        <Box
          sx={{
            m: 1,
            width: '95%',
            display: 'flex',
            justifyContent: "flex-end",
          }}
        >
          {createBool === false ? (
            <Button
              onClick={() => {
                setCreateBool(true);
              }}
              variant="contained"
            >
              Créer un TCS
            </Button>
          ) : (
            <Box>
              <TextField
                onChange={(event) => {
                  setCreateTcsName(event.target.value);
                }}
                sx={{
                  backgroundColor: 'white',
                }}
                label="Entrez le nom du TCS"
              />
              <Button
                sx={{
                  m: 1
                }}
                variant="contained"
                onClick={() => {
                  setCreateBool(false);
                }}
              >
                Annuler
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleCreateTcs();
                }}
              >
                Créer
              </Button>
            </Box>
          )}
          <Select
      value={selectedCategory}
      label="Filtrer par catégorie"
      onChange={handleCategoryChange}
    >
      
      <MenuItem value={true}>Activé</MenuItem>
      <MenuItem value={false}>Désactivé</MenuItem>
      
    </Select>
        </Box>
      )}

      {/* Afficher la liste des TCS si l'utilisateur a la permission "Voir" */}
      {hasViewPermission ? (
        <Card {...props}>
          <Scrollbar>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2.5
              }}
            >
              <SearchIcon fontSize="small" />
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 3
                }}
              >
                <Input
                  color="secondary"
                  disableUnderline
                  fullWidth
                  value={searchName}
                  onChange={handleChangeSearch}
                  placeholder="Chercher un TCS"
                />
              </Box>
            </Box>
            <Divider />
            <Table sx={{ minWidth: 700 }}>
              <TableBody>
                {tcs && tcs.length > 0 ? filteredResults.map((tcsSingle, index) => (
                  <TableRow
                    hover
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          '& > img': {
                            flexShrink: 0,
                            height: 56,
                            width: 56
                          }
                        }}
                      >
                        {tcsSingle.image_link ? (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              backgroundImage: `url(${tcsSingle.image_link})`,
                              backgroundPosition: 'center',
                              backgroundSize: 'cover',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              overflow: 'hidden',
                              width: 100
                            }}
                          />
                        ) : (
                          <Box
                            sx={{
                              alignItems: 'center',
                              backgroundColor: 'background.default',
                              borderRadius: 1,
                              display: 'flex',
                              height: 100,
                              justifyContent: 'center',
                              width: 100
                            }}
                          >
                            <ImageIcon fontSize="small" />
                          </Box>
                        )}
                        <Box sx={{ ml: 3 }}>
                          <IconButton
                            component={RouterLink}
                            to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/tcs/${tcsSingle.id}`}
                          >
                            <Typography variant="h6" color="primary">
                              {tcsSingle.name}
                            </Typography>
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ width: '15%' }}>
                      {hoveredRowIndex === index && (
                        <>
                          <Tooltip title="Dupliquer le TCS">
                            <IconButton
                              onClick={() => handleDuplicateClick(tcsSingle.id)}
                              color="primary"
                            >
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={tcsSingle.activated ? "Désactiver le TCS":"Activer le TCS"}>
                            <IconButton
                              onClick={() =>tcsSingle.activated ? handleDisableClick(tcsSingle.id):handleEnableClick(tcsSingle.id)}
                              // color={!tcsSingle.activated ?  "success":"error"}
                              color="primary"
                            >
                             {tcsSingle.activated ?  <DeleteIcon />: <CheckCircleIcon />}
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                )) : <Typography sx={{ p: 3 }}> Pas de TCS associés </Typography>}
              </TableBody>
              <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
              <DialogTitle>Confirmation de duplication</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir dupliquer ce TCS ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDuplicate} color="primary">
                  Dupliquer
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={disableDialogOpen} onClose={handleDisableCancel}>
              <DialogTitle>Confirmation de désactivation</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir désactiver ce TCS ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDisableCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDisable} color="primary">
                  Désactiver
                </Button>
              </DialogActions>
            </Dialog>
             <Dialog open={enableDialogOpen} onClose={handleEnableCancel}>
              <DialogTitle>Confirmation l'activation</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir activer ce TCS ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEnableCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmEnableClick} color="primary">
                  Activer
                </Button>
              </DialogActions>
            </Dialog>
            </Table>
          </Scrollbar>
        </Card>
      ) : (
        // Afficher un message si l'utilisateur n'a pas la permission "Voir"
        <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <Card sx={{ maxWidth: 400, textAlign: "center", p: 3, boxShadow: 3 }}>
            <CardContent>
              <LockIcon sx={{ fontSize: 60, color: "error.main" }} />
              <Typography variant="h6" sx={{ mt: 2, color: "error.main" }}>
                Accès refusé
              </Typography>
              <Typography variant="body1" sx={{ color: "text.secondary", mt: 1 }}>
                Vous n'avez pas la permission d'afficher cette page.
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary", mt: 1 }}>
                Si vous pensez qu'il s'agit d'une erreur, contactez l'administrateur.
              </Typography>
              <Button 
                variant="contained" 
                color="primary" 
                sx={{ mt: 3 }} 
                onClick={() => window.location.href = "/"}
              >
                Retour à l'accueil
              </Button>
            </CardContent>
          </Card>
        </Box>
      </Grid>
      )}

      {/* Boîte de dialogue pour la duplication */}
      <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirmation de duplication</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir dupliquer ce TCS ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDuplicate} color="primary">
            Dupliquer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
  TextField,
} from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Toaster } from 'react-hot-toast';
import { useNavigate, useParams, useLocation, Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from 'react';
import { userApi } from '../../../api/user-api';

export const AdminUpdateForm = (props) => {
  const navigate = useNavigate(); // Définir la navigation
  const location = useLocation();
  const { admin } = location.state || {}; // Récupérer l'objet admin passé via state

  const [roles, setRoles] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState(admin?.avatar || ''); // État local pour l'URL de l'avatar

  const [initialValues, setInitialValues] = useState({
    firstname: admin?.firstname || '',
    lastname: admin?.lastname || '',
    email: admin?.email || '',
    password: '',
    avatar: admin?.avatar || '',
    role_id: admin?.role_id || '',
  });

  // Définition du validationSchema avec Yup
  const validationSchema = Yup.object({
    firstname: Yup.string().required("Le prénom est requis"),
    lastname: Yup.string().required("Le nom est requis"),
    email: Yup.string().email("Email invalide").required("L'email est requis"),
    avatar: Yup.string().url("URL invalide"),
    role_id: Yup.string().required("Le rôle est requis"),
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/admin/update`;
        const accessToken = localStorage.getItem("accessToken");

        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            id: admin.id, // Envoyer l'ID dans le corps de la requête
            ...values,    // Envoyer les autres données
          }),
        });

        if (!response.ok) {
          const errorData = await response.json(); // Récupérer les données d'erreur
          if (errorData.msg === "Cet email est déjà utilisé par un autre administrateur") {
            toast.error(errorData.msg); // Afficher le message d'erreur dans un toast
          } else {
            throw new Error(`Erreur HTTP! Status: ${response.status}`);
          }
          return; // Arrêter l'exécution si une erreur est détectée
        }

        const data = await response.json();

        if (!data.id) {
          toast.success('Admin mis à jour avec succès !');
          helpers.setSubmitting(false);
        } else {
          toast.error(data.msg || "Erreur lors de la mise à jour.");
          helpers.setErrors({ submit: data.msg });
        }
      } catch (err) {
        console.error(err);
        toast.error('Une erreur est survenue !');
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  // Mettre à jour l'URL de l'avatar lorsque l'URL change
  useEffect(() => {
    setAvatarUrl(formik.values.avatar);
  }, [formik.values.avatar]);

  // Mapper role_name à role_id une fois que les rôles sont chargés
  useEffect(() => {
    if (admin?.role_name && roles.length > 0) {
      const role = roles.find((r) => r.role_name === admin.role_name);
      if (role) {
        setInitialValues((prevValues) => ({
          ...prevValues,
          role_id: role.id, // Mettre à jour role_id en fonction de role_name
        }));
      }
    }
  }, [admin, roles]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const rolesUrl = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/role/get/`;
        const accessToken = localStorage.getItem("accessToken");

        const rolesResponse = await fetch(rolesUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!rolesResponse.ok) throw new Error(`Erreur HTTP! Status: ${rolesResponse.status}`);
        const rolesData = await rolesResponse.json();
        setRoles(rolesData.roles);
      } catch (error) {
        console.error("Erreur lors de la récupération des rôles:", error);
        toast.error("Erreur lors de la récupération des rôles.");
      }
    };

    fetchRoles();
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <RouterLink
        to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/admins`}
        style={{ textDecoration: "none" }}
        sx={{
          mt: 60
        }}
      >
        <Link
          color="textPrimary"
          component="a"
          sx={{
            alignItems: "center",
            display: "flex",
            marginTop: "30px",
            mt: 13,
            ml: "20px"
          }}
        >
          <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="subtitle2" component="span">
            {" "}
            Liste des Rôles
          </Typography>
        </Link>
      </RouterLink>

      <Card sx={{ mt: 4, mx: "auto", mr: 3 }}>
        <CardHeader title="Édition du profil administrateur" />
        <Divider />
        <CardContent sx={{ pr: 3 }}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                fullWidth
                helperText={formik.touched.firstname && formik.errors.firstname}
                label="Prénom"
                name="firstname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.firstname}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                fullWidth
                helperText={formik.touched.lastname && formik.errors.lastname}
                label="Nom"
                name="lastname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.lastname}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.email}
              />
            </Grid>
            <Grid item md={12} xs={12} container alignItems="center" spacing={1}>
  {/* Zone de l'avatar à gauche */}
  <Grid item xs={-10} md={2} container direction="column" alignItems="flex-start">
    {avatarUrl ? (
      <img
        src={avatarUrl}
        alt="Avatar"
        style={{ marginTop: '8px', maxWidth: '90px', maxHeight: '90px', marginRight: '90px' }}
      />
    ) : (
      <img
        src="https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png" // Avatar par défaut
        alt="Avatar par défaut"
        style={{ marginTop: '8px', maxWidth: '90px', maxHeight: '90px', marginRight: '90px' }}
      />
    )}
  </Grid>

  {/* Champ de saisie de l'URL de l'avatar à droite */}
  <Grid item xs={9} md={10}>
    <TextField
      error={Boolean(formik.touched.avatar && formik.errors.avatar)}
      fullWidth
      helperText={formik.touched.avatar && formik.errors.avatar}
      label="URL de l'avatar"
      name="avatar"
      onBlur={formik.handleBlur}
      onChange={formik.handleChange}
      value={formik.values.avatar}
    />
  </Grid>
</Grid>

            <Grid item md={12} xs={12}>
              <InputLabel htmlFor="role">Rôle</InputLabel>
              <Select
                error={Boolean(formik.touched.role_id && formik.errors.role_id)}
                fullWidth
                name="role_id"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.role_id}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.role_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ pr: 3 }}>
          <Button disabled={formik.isSubmitting} type="submit" variant="contained">
            Mettre à jour
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
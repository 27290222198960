import { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMounted } from "../../../hooks/use-mounted";
import { useAuth } from "../../../hooks/use-auth";
import { surveyApi } from "../../../api/survey-api";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import { FileCopy as DuplicateIcon } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const SurveyQuestions = (props) => {
  let { survey, setsurvey } = props;
  // console.log(survey);
  const isMounted = useMounted();
  const [createSurveyQuestionName, setcreateSurveyQuestionName] = useState([]);
  const [createSurveyQuestionAnswer, setcreateSurveyQuestionAnswer] = useState(
    []
  );
 
  const [isCreateSurveyQuestionModalOpen, setIsCreateSurveyQuestionModalOpen] =
    useState(false);
  const [
    isCreateSurveyQuestionAnswerModalOpen,
    setIsCreateSurveyQuestionAnswerModalOpen,
  ] = useState(false);
  const [questionType, setQuestionType] = useState("multiple");
  const [questions, setQuestions] = useState([]);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [editingQuestionText, setEditingQuestionText] = useState("");
  const [editingQuestionType, setEditingQuestionType] = useState("");
  const [editingAnswerText, setEditingAnswerText] = useState("");
  const [editingAnswer, setEditingAnswer] = useState("");
  const { user } = useAuth();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [selectedResponseId, setSelectedResponseId] = useState(null);
  const [deleteAnswerDialogOpen, setDeleteAnswerDialogOpen] = useState(false);
  const [openedQuestionId, setOpenedQuestionId] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const fetchQuestions = useCallback(async () => {
    try {
      if (survey.id) {
        const data = await surveyApi.getSurveyQuestions(
          user.admin_id,
          survey.id
        );

        const sortedQuestions = data.sort(
          (a, b) => a.question_order - b.question_order
        );

        if (isMounted()) {
          setQuestions(sortedQuestions);
        }
      }
    } catch (err) {
      console.error("Erreur lors de la récupération des questions", err);
    }
  }, [isMounted, survey]);

  useEffect(() => {
    fetchQuestions();
  }, [fetchQuestions]);

  const handleCreateSurveyQuestion = async (event) => {
    try {
      const id = await surveyApi.createSurveyQuestion(
        user.admin_id,
        createSurveyQuestionName,
        questionType,
        survey.id
      );
      fetchQuestions();
    } catch (error) {
      console.error("Erreur lors de la création de la question", error);
    }
  };

  const handleCreateSurveyQuestionAnswer = async () => {
    try {
      const data = await surveyApi.createSurveyQuestionAnswer(
        user.admin_id,
        selectedQuestionId,
        createSurveyQuestionAnswer
      );
      fetchQuestions();
      setIsCreateSurveyQuestionAnswerModalOpen(false);
    } catch (error) {
      console.error("Erreur lors de la création de la réponse ", error);
    }
  };

  const handleDragEnd = async (result, questionId, answers) => {
    if (!result.destination) return;
    if (result.type === "questions") {
      // Traitement du glisser-déposer pour les questions
      const updatedQuestions = [...questions];
      const [movedQuestion] = updatedQuestions.splice(result.source.index, 1);
      updatedQuestions.splice(result.destination.index, 0, movedQuestion);

      const sortedQuestions = updatedQuestions.map((question, index) => ({
        ...question,
        question_order: index + 1, // Mettez à jour question_order en fonction de l'index
      }));

      await surveyApi.modifySurveyQuestionsOrders(
        user.admin_id,
        survey.id,
        sortedQuestions
      );
      setQuestions(sortedQuestions);
    } else if (result.type === "answers") {
      // Traitement du glisser-déposer pour les réponses
      const updatedAnswers = [...answers];
      const [movedAnswer] = updatedAnswers.splice(result.source.index, 1);
      updatedAnswers.splice(result.destination.index, 0, movedAnswer);

      const sortedAnswers = updatedAnswers.map((answer, index) => ({
        ...answer,
        answer_order: index + 1, // Mettez à jour question_order en fonction de l'index
      }));

      await surveyApi.modifySurveyQuestionsAnswersOrders(
        user.admin_id,
        questionId,
        sortedAnswers
      );

      const updatedQuestions = [...questions];
      const questionIndex = updatedQuestions.findIndex(
        (q) => q.id === questionId
      );
      if (questionIndex !== -1) {
        updatedQuestions[questionIndex] = {
          ...updatedQuestions[questionIndex],
          answers: sortedAnswers,
        };
        setQuestions(updatedQuestions);
      }
    }
  };
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = { roles_id };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);


  const hasCreatePermission = permissions.some(
    (permission) => permission.component === "Satisfaction" && permission.permission === "Créer"
  );

  const handleEditQuestion = (questionId, questionText, questionType) => {
    setEditingQuestionText(questionText);
    setEditingQuestionType(questionType);
    setEditingQuestion(questionId);
  };

  const handleEditQuestionAnswer = (answerId, answerText) => {
    setEditingAnswerText(answerText);
    setEditingAnswer(answerId);
  };

  const handleSaveEdit = async (index) => {
    try {
      await surveyApi.modifySurveyQuestion(
        user.admin_id,
        editingQuestion,
        editingQuestionText,
        editingQuestionType
      );
      // Mettez à jour l'état des questions avec la question modifiée
      setQuestions((prevQuestions) =>
        prevQuestions.map((question, i) =>
          i === index
            ? {
                ...question,
                question: editingQuestionText,
                question_type: editingQuestionType,
              }
            : question
        )
      );
      // Réinitialisez l'état d'édition
      setEditingQuestion(null);
      setEditingQuestionText("");
      setEditingQuestionType("");
      fetchQuestions();
    } catch (error) {
      console.error("Error editing survey question", error);
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    setSelectedQuestionId(questionId);
    setDeleteDialogOpen(true);
  };

  const handleDuplicateQuestion = (id) => {
    setSelectedQuestionId(id);
    setDuplicateDialogOpen(true);
  };

  const confirmDeleteQuestion = async () => {
    try {
      await surveyApi.deleteQuestion(user.admin_id, selectedQuestionId);
      setQuestions((prevQuestions) =>
        prevQuestions.filter((question) => question.id !== selectedQuestionId)
      );
      fetchQuestions();
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting survey question", error);
    }
  };

  const handleCancel = () => {
    setDuplicateDialogOpen(false);
    setDeleteDialogOpen(false);
    setDeleteAnswerDialogOpen(false);
  };

  const handleDeleteAnswer = async (responseId) => {
    setSelectedResponseId(responseId);
    setDeleteAnswerDialogOpen(true);
  };

  const confirmDeleteAnswer = async () => {
    try {
      await surveyApi.deleteQuestionAnswer(user.admin_id, selectedResponseId);
      fetchQuestions();
      setDeleteAnswerDialogOpen(false);
    } catch (error) {
      console.error("Error deleting survey question", error);
    }
  };

  const handleSaveEditAnswer = async (answerId) => {
    try {
      await surveyApi.modifySurveyQuestionAnswer(
        user.admin_id,
        answerId,
        editingAnswerText
      );
      // Mettez à jour l'état des réponses avec la réponse modifiée
      fetchQuestions();
      // Réinitialisez l'état d'édition
      setEditingAnswer(null);
      setEditingAnswerText("");
    } catch (error) {
      console.error("Error editing survey question answer", error);
    }
  };

  const handleConfirmDuplicate = async () => {
    try {
      const data = await surveyApi.DuplicateSurveyQuestionById(
        user.admin_id,
        selectedQuestionId
      );
      fetchQuestions();
      setDuplicateDialogOpen(false);
    } catch (error) {
      console.error("Erreur lors de la création du questionnaire", error);
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  return (
    <>
      <Box
        sx={{
          m: 2,
          width: "95%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {hasCreatePermission &&(
        <Button
          onClick={() => {
            setIsCreateSurveyQuestionModalOpen(true);
          }}
          variant="contained"
        >
          Créer une question
        </Button>
        )}

        <Box>
          <Modal
            open={isCreateSurveyQuestionModalOpen}
            onClose={() => {
              // Réinitialiser les états lorsque le modal se ferme
              setIsCreateSurveyQuestionModalOpen(false);
              setcreateSurveyQuestionName("");
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%",
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    value={createSurveyQuestionName}
                    onChange={(event) =>
                      setcreateSurveyQuestionName(event.target.value)
                    }
                    label="Entrez la question"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Select
                    fullWidth
                    value={questionType}
                    onChange={(event) => setQuestionType(event.target.value)}
                    label="Type de question"
                  >
                    <MenuItem value="multiple">Choix multiple</MenuItem>
                    <MenuItem value="opened">Ouverte</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      handleCreateSurveyQuestion();
                      setIsCreateSurveyQuestionModalOpen(false);
                    }}
                  >
                    Créer
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      setIsCreateSurveyQuestionModalOpen(false);
                      setcreateSurveyQuestionName("");
                    }}
                  >
                    Annuler
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Box>
      </Box>
      <DragDropContext onDragEnd={(result) => handleDragEnd(result)}>
        <Droppable droppableId="questions" type="questions">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {questions.map((question, index) => (
                <Draggable
                  key={question.id}
                  draggableId={question.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <Table
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        mb: 2,
                        backgroundColor: "white",
                      }}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <TableRow>
                        <TableCell sx={{ width: "10%" }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() =>
                              setOpenedQuestionId(
                                question.id === openedQuestionId
                                  ? null
                                  : question.id
                              )
                            }
                          >
                            {question.id === openedQuestionId ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ width: "70%" }}>
                          {editingQuestion === question.id ? (
                            <>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    value={editingQuestionText}
                                    onChange={(e) =>
                                      setEditingQuestionText(e.target.value)
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Select
                                    value={editingQuestionType}
                                    onChange={(e) =>
                                      setEditingQuestionType(e.target.value)
                                    }
                                    label="Type de question"
                                  >
                                    <MenuItem value="multiple">
                                      Choix multiple
                                    </MenuItem>
                                    <MenuItem value="opened">Ouverte</MenuItem>
                                  </Select>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography variant="h6">
                                Question {index + 1} : &nbsp;
                              </Typography>
                              <ListItemText primary={question.question} />
                            </div>
                          )}
                        </TableCell>
                        <TableCell sx={{ width: "20%" }}>
                          {editingQuestion === question.id ? (
                            <>
                              <Grid
                                container
                                justifyContent="flex-end"
                                spacing={2}
                              >
                                <Grid item>
                                  <Button
                                    onClick={() => handleSaveEdit(index)}
                                    variant="contained"
                                  >
                                    Enregistrer
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    onClick={() => setEditingQuestion(null)}
                                    variant="contained"
                                  >
                                    Annuler
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              {hoveredRowIndex === index && (
                                <>
                                  <Tooltip title="Dupliquer une question">
                                    <IconButton
                                      onClick={() =>
                                        handleDuplicateQuestion(question.id)
                                      }
                                      edge="end"
                                      aria-label="edit"
                                      color="primary"
                                    >
                                      <DuplicateIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Modifier le contenu d'une question">
                                    <IconButton
                                      onClick={() =>
                                        handleEditQuestion(
                                          question.id,
                                          question.question,
                                          question.question_type
                                        )
                                      }
                                      edge="end"
                                      aria-label="edit"
                                      color="success"
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Supprimer une question">
                                    <IconButton
                                      onClick={() =>
                                        handleDeleteQuestion(question.id)
                                      }
                                      edge="end"
                                      aria-label="delete"
                                      color="error"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Collapse
                            in={question.id === openedQuestionId}
                            timeout="auto"
                            unmountOnExit
                          >
                            {question.question_type === "multiple" && (
                              <>
                                <Box>
                                  <Modal
                                    open={isCreateSurveyQuestionAnswerModalOpen}
                                    onClose={() => {
                                      // Réinitialiser les états lorsque le modal se ferme
                                      setIsCreateSurveyQuestionAnswerModalOpen(
                                        false
                                      );
                                      setcreateSurveyQuestionAnswer("");
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: "50%",
                                        bgcolor: "background.paper",
                                        boxShadow: 24,
                                        p: 4,
                                      }}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <TextField
                                            fullWidth
                                            value={createSurveyQuestionAnswer}
                                            onChange={(event) =>
                                              setcreateSurveyQuestionAnswer(
                                                event.target.value
                                              )
                                            }
                                            label="Entrer la réponse"
                                          />
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                              handleCreateSurveyQuestionAnswer();
                                            }}
                                          >
                                            Créer
                                          </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => {
                                              setIsCreateSurveyQuestionAnswerModalOpen(
                                                false
                                              );
                                              setcreateSurveyQuestionAnswer("");
                                            }}
                                          >
                                            Annuler
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  </Modal>
                                </Box>
                                <Box>
                                  <DragDropContext
                                    onDragEnd={(result) =>
                                      handleDragEnd(
                                        result,
                                        question.id,
                                        question.answers
                                      )
                                    }
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-between"
                                      alignItems="center"
                                      margin={1}
                                    >
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ fontWeight: "bold", mb: 1 }}
                                      >
                                        Liste des réponses :
                                      </Typography>
                                      <Tooltip title="Ajouter une nouvelle réponse">
                                        <IconButton
                                          onClick={() => {
                                            setSelectedQuestionId(question.id);
                                            setIsCreateSurveyQuestionAnswerModalOpen(
                                              true
                                            );
                                          }}
                                          variant="contained"
                                          sx={{ mr: 2 }}
                                        >
                                          <AddCircleIcon />
                                        </IconButton>
                                      </Tooltip>
                                    </Grid>
                                    <Droppable
                                      droppableId={`answers-${question.id}`}
                                      type="answers"
                                    >
                                      {(provided) => (
                                        <Table
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          component={Paper}
                                          sx={{ width: "80%", margin: "auto" }}
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Ordre</TableCell>
                                              <TableCell>Réponse</TableCell>
                                              <TableCell>Actions</TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {question.answers.length > 0 ? (
                                              question.answers
                                                .sort(
                                                  (a, b) =>
                                                    a.answer_order -
                                                    b.answer_order
                                                )
                                                .map((answer, index) => (
                                                  <Draggable
                                                    key={answer.id}
                                                    draggableId={answer.id.toString()}
                                                    index={index}
                                                    type="answers"
                                                  >
                                                    {(provided) => (
                                                      <TableRow
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                      >
                                                        <TableCell>
                                                          {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                          {editingAnswer ===
                                                          answer.id ? (
                                                            // Champ de texte d'édition
                                                            <TextField
                                                              value={
                                                                editingAnswerText
                                                              }
                                                              onChange={(e) =>
                                                                setEditingAnswerText(
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          ) : (
                                                            // Affichage du texte normal
                                                            answer.answer
                                                          )}
                                                        </TableCell>
                                                        <TableCell>
                                                          {editingAnswer ===
                                                          answer.id ? (
                                                            // Boutons de sauvegarde et d'annulation pour le mode édition
                                                            <>
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleSaveEditAnswer(
                                                                    answer.id
                                                                  )
                                                                }
                                                              >
                                                                <CheckIcon />
                                                              </IconButton>
                                                              <IconButton
                                                                onClick={() =>
                                                                  setEditingAnswer(
                                                                    null
                                                                  )
                                                                }
                                                              >
                                                                <CancelIcon />
                                                              </IconButton>
                                                            </>
                                                          ) : (
                                                            <Tooltip title="Modifier une réponse">
                                                              <IconButton
                                                                onClick={() =>
                                                                  handleEditQuestionAnswer(
                                                                    answer.id,
                                                                    answer.answer
                                                                  )
                                                                }
                                                                edge="end"
                                                                aria-label="edit"
                                                                color="success"
                                                              >
                                                                <EditIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                          )}
                                                          <Tooltip title="Supprimer une réponse">
                                                            <IconButton
                                                              onClick={() =>
                                                                handleDeleteAnswer(
                                                                  answer.id
                                                                )
                                                              }
                                                              edge="end"
                                                              aria-label="delete"
                                                              color="error"
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </Tooltip>
                                                        </TableCell>
                                                      </TableRow>
                                                    )}
                                                  </Draggable>
                                                ))
                                            ) : (
                                              <TableRow>
                                                <TableCell colSpan={3}>
                                                  <Typography>
                                                    Aucune réponse ajoutée pour
                                                    cette question
                                                  </Typography>
                                                </TableCell>
                                              </TableRow>
                                            )}
                                            {provided.placeholder}
                                          </TableBody>
                                        </Table>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </Box>
                              </>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </Table>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      {/* Dialog pour la duplication */}
      <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirmation de duplication</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir dupliquer cette question ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDuplicate} color="primary">
            Dupliquer
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog pour la suppression */}
      <Dialog open={deleteDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cette question ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDeleteQuestion} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog pour la suppression */}
      <Dialog open={deleteAnswerDialogOpen} onClose={handleCancel}>
        <DialogTitle>Confirmation de suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cette réponse ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Annuler
          </Button>
          <Button onClick={confirmDeleteAnswer} color="primary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

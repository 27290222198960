import { useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Card, CardHeader, Divider, Grid, Link, List, ListItem, ListItemText, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import { useMounted } from '../../../hooks/use-mounted';
import { useAuth } from '../../../hooks/use-auth';
import { userApi } from '../../../api/user-api';
import { formationsApi } from '../../../api/survey-api';
import { auditsApi } from '../../../api/audit-api';
import { surveyApi } from '../../../api/survey-api';

export const SurveyBasicDetails = (props) => {
  let { survey, setsurvey,getsurveyData } = props;
//   console.log(survey); 
  const isMounted = useMounted();
  const { user } = useAuth();
  const [modifyText, setModifyText] = useState("");
  const [audits, setAudits] = useState([]);
  const [modify, setModify] = useState({
    "name": null,
    "epp_ids": null,
  });

  // Fonction pour vérifier si l'utilisateur a la permission "update" pour le composant "Satisfaction"
  const hasPermission = (component, requiredPermission) => {
    return user.components_and_permissions.some(
      (perm) => perm.component === component && perm.permission === requiredPermission
    );
  };

  const canModify = hasPermission("Satisfaction", "Mettre à jour");

  async function handleModify(field, data = null) {
    if (!canModify) return; // Bloquer la modification si l'utilisateur n'a pas la permission

    setModify(modify => ({
      ...modify,
      [field]: false
    }));
    let changeSurvey = { ...survey };
    if (data) {
      changeSurvey[field] = data;
    } else {
      changeSurvey[field] = modifyText;
    }
    // setsurvey(changeSurvey);
    await surveyApi.modifySurvey(user.admin_id, survey.id ,changeSurvey.name, changeSurvey.epp_ids);
     getsurveyData();
  }

  const getAudits = useCallback(async () => {
    try {
      const data = await auditsApi.getAllEpp(user.admin_id);
      if (isMounted()) {
        setAudits(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getAudits();
  }, []);

  return (
    <Card>
      <CardHeader title="Informations" />
      <Divider />
      <List disablePadding>
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180 }}
                variant="subtitle2"
              >
                Nom du questionnaire
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                {modify.name && canModify ? (
                  <TextField
                    size='small'
                    focused
                    autoFocus
                    defaultValue={survey.name}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleModify("name");
                      }
                    }}
                    onBlur={() => {
                      handleModify("name");
                    }}
                    onChange={(e) => setModifyText(e.target.value)}
                  />
                ) : (
                  <Typography
                    onDoubleClick={() => {
                      if (canModify) {
                        setModify(modify => ({
                          ...modify,
                          name: true
                        }));
                        setModifyText(survey.name);
                      }
                    }}
                    color="textSecondary"
                    variant="body2"
                    sx={{ cursor: canModify ? 'pointer' : 'default' }}
                  >
                    {survey.name}
                  </Typography>
                )}
              </Box>
            )}
          />
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            px: 3,
            py: 1.5
          }}
        >
          <ListItemText
            sx={{
              display: 'flex',
              flexDirection: 'row',
              my: 0
            }}
            disableTypography
            primary={
              <Typography
                sx={{ minWidth: 180 }}
                variant="subtitle2"
              >
                EPP associés
              </Typography>
            }
            secondary={(
              <Box
                sx={{
                  flex: 1,
                  mt: 0,
                  display: "flex",
                  maxWidth: 1000
                }}
              >
                <Autocomplete
x={{ minWidth: 300 }}
  multiple
  id="audits-autocomplete"
  options={audits}
  getOptionLabel={(audit) => audit.name}
  value={
    
    survey.epp_ids
      ? audits.filter((audit) =>
      { let arr = JSON.parse(survey.epp_ids); 
        return arr.includes(audit.id); 
        }
        )
      : []
  }
  
  
  onChange={(event, newValue) => {
    // Update `survey.epp_ids` with the IDs of the selected audits
    const newEppIds = newValue.map((audit) => audit.id);
    
    // Update the survey object with the new IDs
    handleModify('epp_ids', newEppIds);

    // Debugging logs
    // console.log("Updated survey.epp_ids:", newEppIds);
    getsurveyData();
  }}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="standard"
      label="Sélectionnez les epp"
    />
                  )}
                  disabled={!canModify} // Désactiver si l'utilisateur n'a pas la permission
                />
              </Box>
            )}
          />
        </ListItem>
      </List>
    </Card>
  );
};
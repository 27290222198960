import {
    Box,
    Button,
    Card,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    Input,
    Divider,
    TextField
  } from '@mui/material';
  import ImageIcon from '@mui/icons-material/Image';
  import { Scrollbar } from '../../scrollbar';
  import SearchIcon from '@mui/icons-material/Search';
  import { useState } from 'react';
  import { useEffect } from 'react';
  import { Link as RouterLink } from 'react-router-dom';
  import { useAuth } from '../../../hooks/use-auth';
  import { useNavigate } from "react-router-dom";
import { patientsFolderApi } from '../../../api/patients_folder-api';
  
  export const PatientsFolderList = (props) => {
    const { patientsFolder } = props;
    const { user } = useAuth();
    const [filteredPatientsFolders, setFilteredPatientsFolders] = useState([])
    const [searchName, setSearchName] = useState([])
    const [createBool, setCreateBool] = useState(false)
    const [createPatientsFileName, setCreatePatientsFileName] = useState([])
    const [permissions, setPermissions] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
      setFilteredPatientsFolders(patientsFolder)
    }, [patientsFolder])
    const getPermissionsByRole = async (roles_id) => {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
      const values = { roles_id };
      const accessToken = localStorage.getItem("accessToken");
  
      try {
        const resp = await fetch(url, {
          method: "POST",
          body: JSON.stringify(values),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        if (!resp.ok) {
          throw new Error(`HTTP error! Status: ${resp.status}`);
        }
  
        const json = await resp.json();
        setPermissions(json.permissions);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
  
    useEffect(() => {
      if (user && user.roles_id) {
        getPermissionsByRole(user.roles_id);
      }
    }, [user]);
  
  
    const hasCreatePermission = permissions.some(
      (permission) => permission.component === "Dossier patient" && permission.permission === "Créer"
    );
  
    const handleChangeSearch = event => {
      setSearchName(event.target.value)
      setFilteredPatientsFolders(patientsFolder.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
    }
  
    const handleCreatePatientsFile = async event => {
      const id = await patientsFolderApi.createPatientsFile(user.admin_id, createPatientsFileName);
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/patients_folder/${id.id}`)
    }
  
    return (
      <>
        <Box
          sx={{
            m: 1,
            width: '95%',
            display: 'flex',
            justifyContent: "flex-end",
          }}
        >
          {hasCreatePermission && (
            <>
            <Button
              onClick={() => {
                setCreateBool(true)
              }}
              variant='contained'
            >
              Créer un dossier patient
            </Button>: 
          
            <Box>
              <TextField
                onChange={(event) => {
                  setCreatePatientsFileName(event.target.value)
                }}
                sx={{
                  backgroundColor:'white',
                  // borderRadius: 50
                }}
                label="Entrez le nom du dossier patients"
              />
              <Button
                sx={{
                  m:1
                }}
                variant='contained'
                onClick={() => {
                  setCreateBool(false)
                }}
              >
                Annuler
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  handleCreatePatientsFile()
                }}
              >
                Créer
              </Button>
            </Box>
          
          </>
    )}
        </Box>
        <Card {...props}>
          <Scrollbar>
            <Box
              sx={{
                  alignItems: 'center',
                  display: 'flex',
                  p: 2.5
                }}
            >
              <SearchIcon fontSize="small" />
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 3
                }}
              >
                <Input
                  color='secondary'
                  disableUnderline
                  fullWidth
                  value={searchName}
                  onChange={handleChangeSearch}
                  placeholder="Chercher un dossier patient"
                />
              </Box>
            </Box>
            <Divider />
            <Table sx={{ minWidth: 700 }}>
              <TableBody>
                {patientsFolder && patientsFolder.length > 0 ? filteredPatientsFolders.map((patientsFolder, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          '& > img': {
                            flexShrink: 0,
                            height: 56,
                            width: 56
                          }
                        }}
                      >
                        {/* {audit.image_link
                          ? (
                            <Box
                              sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                backgroundImage: `url(${audit.image_link})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                borderRadius: 1,
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                overflow: 'hidden',
                                width: 100
                              }}
                            />
                          )
                          : (
                            <Box
                              sx={{
                                alignItems: 'center',
                                backgroundColor: 'background.default',
                                borderRadius: 1,
                                display: 'flex',
                                height: 100,
                                justifyContent: 'center',
                                width: 100
                              }}
                            >
                              <ImageIcon fontSize="small" />
                            </Box>
                          )} */}
                        <Box sx={{ ml: 3 }}>
                          <IconButton
                            component = {RouterLink}
                            to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/patients_folder/${patientsFolder.id}`}
                          >
                            <Typography variant="h6"
                              color='primary'
                            >
                              {patientsFolder.name}
                            </Typography>
                          </IconButton>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )) : <Typography sx={{p:3}}> Pas de dossiers patients </Typography>}
              </TableBody>
            </Table>
          </Scrollbar>
        </Card>
      </>
    )
  };
  
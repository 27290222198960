import React, { useState, useEffect, Suspense, lazy } from "react";
import { Box, Container, IconButton, Collapse, Paper, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight, Delete } from "@mui/icons-material";
import { widgetsApi } from "../../api/widget-api";
import GridLayout from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { debounce } from "lodash"; // Importation de lodash pour le debounce
import CloseIcon from '@mui/icons-material/Close';
// Function for fetching the widgets available
const getWidgets = async () => {
  const widgets = await widgetsApi.getWidgets();
  return widgets.map((widget) => ({
    ...widget,
    Component: lazy(() =>
      import(`./widgets/${widget.component_name}`).catch(() =>
        import(`../../components/dashboard/statistiques/${widget.component_name}`)
      )
    ),
  }));
};
 
// Function for getting widget positions
const defaultWidgets = [
  {
    widget_id: "5",
    component_name: "mean-audit.js",
    position_x: 0.0,
    position_y: 7.1000000000000005,
    width: 14.0,
    height: 7.1,
  },
  {
    widget_id: "3",
    component_name: "satisfaction-rate.js",
    position_x: 10.0,
    position_y: 5.4,
    width: 4.0,
    height: 1.7,
  },
  {
    widget_id: "1",
    component_name: "finished-epp.js",
    position_x: 5.0,
    position_y: 5.4,
    width: 4.0,
    height: 1.7,
  },
  {
    widget_id: "2",
    component_name: "in-progress-epp.js",
    position_x: 0.0,
    position_y: 5.4,
    width: 4.0,
    height: 1.7,
  },
  {
    widget_id: "4",
    component_name: "last-activities.js",
    position_x: 0.0,
    position_y: 0.0,
    width: 6.6,
    height: 5.4,
  },
  {
    widget_id: "8",
    component_name: "best-finished-epp.js",
    position_x: 7.3,
    position_y: 0.0,
    width: 6.7,
    height: 5.4,
  },
  {
    widget_id: "11",
    component_name: "last-messages.js",
    position_x: 7.5,
    position_y: 5.2,
    width: 7.5,
    height: 5.1,
  },
  {
    widget_id: "10",
    component_name: "coming-dates.js",
    position_x: 0,
    position_y: 0,
    width: 15.0,
    height: 5.2,
  },
  {
    widget_id: "9",
    component_name: "feedbacks-number.js",
    position_x: 0,
    position_y: 5.2,
    width: 7.5,
    height: 5.1,
  },
];

 
const getWidgetLocations = async (admin_id) => {
  try {
    const widgetLocationsResponse = await widgetsApi.getWidgetLocations(admin_id);
    const widgetLocations = widgetLocationsResponse.data;
 
    // Retourner les widgets par défaut si aucune donnée n'est trouvée
    if (!widgetLocations || widgetLocations.length === 0) {
      return defaultWidgets.map((widget) => ({
        i: widget.widget_id.toString(),
        x: widget.position_x,
        y: widget.position_y,
        w: widget.width,
        h: widget.height,
        component_name: widget.component_name,
      }));
    }
 
    return widgetLocations.map((location) => ({
      i: location.widget_id.toString(),
      x: location.position_x,
      y: location.position_y,
      w: location.width || 4,
      h: location.height || 4,
    }));
  } catch (error) {
    console.error("Error fetching widget locations:", error);
    // En cas d'erreur, utiliser les widgets par défaut
    return defaultWidgets.map((widget) => ({
      i: widget.widget_id.toString(),
      x: widget.position_x,
      y: widget.position_y,
      w: widget.width,
      h: widget.height,
      component_name: widget.component_name,
    }));
  }
};
 
 
const DashboardAdmin = ({ admin_id, isEditMode ,id_service}) => {
  const [availableWidgets, setAvailableWidgets] = useState([]);
  const [widgetsLayout, setWidgetsLayout] = useState([]);
  const [selectedWidgets, setSelectedWidgets] = useState([]);  // Ensure it's initialized as an empty array
  const [menuOpen, setMenuOpen] = useState(true);
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null); // To track the hovered menu item
 
  // Fetch widgets and widget locations when component mounts
  useEffect(() => {
    const fetchWidgets = async () => {
      let widgets = await getWidgets();
  
      if (id_service !== 0) {
        widgets = widgets.filter(widget =>
          ["last-messages.js", "coming-dates.js", "feedbacks-number.js","last-activities-ex.js"].includes(widget.component_name)
        );
        // console.log("widget",widgets);
      }
  
      setAvailableWidgets(widgets);
      // console.log(`Largeur de l'écran : ${window.innerWidth}px`);
  
      const widgetLocations = await getWidgetLocations(admin_id);
      const selectedWidgetIds = widgetLocations.map((location) => location.i);
      setSelectedWidgets(widgets.filter((widget) => selectedWidgetIds.includes(widget.id.toString())));
      setWidgetsLayout(widgetLocations);
    };
  
    fetchWidgets();
  }, [admin_id, id_service]);
  
 
  // Debounced layout change handler to avoid excessive API calls
  const handleLayoutChange = debounce((newLayout) => {
    setWidgetsLayout(newLayout);
    handleSaveWidgets(newLayout);  // Auto save when layout changes
  }, 300);  // Delay of 300ms before calling the function
 

 
  const onSelectWidget = (widget) => {
    // Ensure selectedWidgets is an array before checking
    if (Array.isArray(selectedWidgets) && selectedWidgets.some((w) => w.id === widget.id)) {
      return;
    }
 
    const maxY = Math.max(...widgetsLayout.map((layout) => layout.y + layout.h), 0);
 
    const newWidgetLayout = {
      i: widget.id.toString(),
      x: 0,
      y: maxY,
      w: 4,
      h: widget.height || 4,
    };
 
    const updatedLayout = [...widgetsLayout, newWidgetLayout];
    setWidgetsLayout(updatedLayout);
 
    setSelectedWidgets((prev) => [...prev, widget]);
    handleSaveWidgets(updatedLayout);  // Auto save when widget is selected
  };
 
  
  const handleDeleteWidget = (widgetId) => {
    const updatedLayout = widgetsLayout.filter((layout) => layout.i !== widgetId.toString());
    setWidgetsLayout(updatedLayout);
 
    const updatedSelectedWidgets = selectedWidgets.filter((widget) => widget.id !== widgetId);
    setSelectedWidgets(updatedSelectedWidgets);
 
    //console.log(`Widget ${widgetId} deleted.`);
    handleSaveWidgets(updatedLayout);  // Auto save when widget is deleted
  };
 
  const handleDeleteAllWidgets = async () => {
    try {
      await widgetsApi.deleteAllWidgetLocalisations();
      setWidgetsLayout([]); 
      setSelectedWidgets([]); // Clear selected widgets
      //console.log("All widget locations deleted successfully.");
    } catch (error) {
     // console.error("Error deleting widget locations:", error);
    }
  };
 
  const handleSaveWidgets = async (updatedLayout) => {
    const dataToSend = updatedLayout.map((layout) => {
      const widget = selectedWidgets.find((w) => w.id.toString() === layout.i);
      return {
        widget_id: layout.i,
        component_name: widget ? widget.component_name : "unknown",
        position_x: layout.x,
        position_y: layout.y,
        width: layout.w,
        height: layout.h,
      };
    });
 
   // console.log("admin_id:", admin_id);
 
    try {
      const response = await widgetsApi.saveWidgetLocations(admin_id, dataToSend);
   //   console.log("response:", response);
    } catch (error) {
   //   console.error("Error saving widgets layout:", error);
    }
  };
 
  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "row-reverse", // Set flexDirection to "row-reverse" to move the menu to the right
        padding: 0,
        margin: 0,
        height: "100vh",
        width: "100%",
        backgroundColor: "#fafafa",
      }}
    >
     
      {isEditMode && (
        <Box sx={{ width: menuOpen ? 240 : 60, height: "100vh", overflowY: "auto" }}>
          <IconButton
            onClick={() => setMenuOpen(!menuOpen)}
            sx={{ position: "absolute", top: 1, left: menuOpen ? "300px" : "60px" }}
          >
            {menuOpen ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
         
          <Collapse in={menuOpen}>
            {availableWidgets.map((widget) => {
              const isDisabled = Array.isArray(selectedWidgets) && selectedWidgets.some((w) => w.id === widget.id);
              return (
                <Paper
                  key={widget.id}
                  sx={{
                    padding: 2,
                    margin: 1,
                    textAlign: "center",
                    cursor: isDisabled ? "not-allowed" : "pointer",
                    backgroundColor: isDisabled ? "#e0e0e0" : "#fafafa",
                    borderRadius: 2,
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
                    position: "relative", // Make sure the delete icon is positioned relative to the Paper component
                  }}
                  onClick={() => !isDisabled && onSelectWidget(widget)}
                  onMouseEnter={() => setHoveredMenuItem(widget.id)} // Show delete icon when hovering over the menu item
                  onMouseLeave={() => setHoveredMenuItem(null)} // Hide delete icon when mouse leaves
                >
                  <Typography variant="body2" sx={{ fontWeight: 600, color: "#333" }}>
                    {widget.name}
                  </Typography>
                  {isEditMode && hoveredMenuItem === widget.id && (
                  <IconButton
                  onClick={() => handleDeleteWidget(widget.id)}
                  sx={{
                    position: 'absolute',
                    top: 16,
                    right: 8,
                    zIndex: 10,
                    color: '#6d6dff',
                    backgroundColor: '#8181CA',
                    borderRadius: '50%',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    opacity: hoveredMenuItem === widget.id ? 1 : 0,
                    transition: 'opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#8181CA',
                      color: 'rgba(129, 129, 202, 0.2)',
                    },
                  }}
                >
                  <CloseIcon sx={{ fontSize: '16px', color: '#fff' }} />
                </IconButton>
                  )}
                </Paper>
              );
            })}
          </Collapse>
        </Box>
      )}
 
      <Box sx={{ flex: 1, padding: 3, position: "relative" }}>
        <GridLayout
          className="layout"
          layout={widgetsLayout}
          onLayoutChange={handleLayoutChange}
          cols={15}
          rowHeight={90}
          
          width={1550}
          compactType="vertical" // Mode de compactage pour éviter les sauts (null pour désactiver)
          preventCollision={false} // Empêche la collision entre widgets
          isDraggable={isEditMode} // Disable drag when isEditMode is false
          isResizable={false} // Disable resize when isEditMode is false
        >
          {widgetsLayout.map((layout) => {
            const widget = availableWidgets.find((widget) => widget.id.toString() === layout.i);
            if (!widget) return null;
 
            const widgetRowHeight =widget.id === 5 ? 7.1:widget.id === 12 ? 5.3:widget.id === 10 ? 5.4: widget.id === 4 ? 5.1 : widget.id === 11 ? 5.3 :widget.id === 9 ? 5.5 : widget.id === 8 || widget.id === 6 || widget.id === 7 ? 5.1 : 1.7;
            const widgetWidth = widget.id === 5 ? 19 :widget.id === 12 ? 7 :widget.id === 10 ? 19 :widget.id === 4 ? 7.3 :widget.id === 11 ? 7.5 :widget.id === 9 ? 7.5 : widget.id === 8 || widget.id === 6 || widget.id === 7 ? 7.3 : 4.7;
 
            const widgetLayout = {
              ...layout,
              h: widgetRowHeight,
              w: widgetWidth,
            };
 
            return (
              <div key={layout.i} data-grid={widgetLayout}>
                <Suspense fallback={<div>Loading...</div>}>
                  <widget.Component />
                </Suspense>
              </div>
            );
          })}
        </GridLayout>
      </Box>
    </Container>
  );
};
 
export default DashboardAdmin;
 
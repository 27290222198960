import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { coursesApi } from "../../../api/course-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useCallback, useState, useEffect } from "react";
import { useAuth } from "../../../hooks/use-auth";
import SendIcon from "@mui/icons-material/Send";
import { feedBacksApi } from "../../../api/feedback-api";
import toast from "react-hot-toast";
import ClearIcon from "@mui/icons-material/Clear";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const CustomerFeedBackTCS = (props) => {
  const { open, handleClose, course_id, auditName, customerDetails } = props;
  const isMounted = useMounted();
  const [resultTcs, setResultTcs] = useState(null);
  const [collapseStatesFirstTurn, setCollapseStatesFirstTurn] = useState([]);
  const [collapseStatesSecondTurn, setCollapseStatesSecondTurn] = useState([]);
  const [feedbackTextFirstTurn, setFeedbacktFirstTurn] = useState("");
  const [feedbackTextSecondTurn, setFeedbackTextSecondTurn] = useState("");
  const [feedbackTextAmelio, setFeedbackTextAmelio] = useState("");
  const [feedbackFirstTurnSent, setFeedbackFirstTurnSent] = useState(false);
  const [feedbackSecondTurnSent, setFeedbackSecondTurnSent] = useState(false);
  const [feedbackImprovementSent, setFeedbackImprovementSent] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingSecondTurn, setIsEditingSecondTurn] = useState(false);
  const [isEditingAmelio, setIsEditingAmelio] = useState(false);
  const [isTurn1Filled, setIsTurn1Filled] = useState(false);
  const [isTurn2Filled, setIsTurn2Filled] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const { user } = useAuth();
  const getFeedback = async() => {
    try {
      const data = await feedBacksApi.getFeedback(course_id, user.admin_id);
      const feedTour1 = data[0].feedback_turn_1;
      const feedTour2 = data[0].feedback_turn_2;
      const feedAmelio = data[0].feedback_improvement;
      const isTurn1Sent = data[0].is_feedback_first_turn_sent;
      const isTurn2Sent = data[0].is_feedback_second_turn_sent;
      const isImprovementSent = data[0].is_feedback_improvement_sent;
      if (isMounted()) {
        setFeedbacktFirstTurn(feedTour1);
        setFeedbackTextSecondTurn(feedTour2);
        setFeedbackTextAmelio(feedAmelio);
        setFeedbackFirstTurnSent(isTurn1Sent);
        setFeedbackSecondTurnSent(isTurn2Sent);
        setFeedbackImprovementSent(isImprovementSent);
      }
    } catch (err) {
      console.error(err);
    }
  
  }
const determinateFeedback = async() =>{
   try {
      const data = await feedBacksApi.determinateFeedback(
        course_id,
        user.admin_id
      );
      const feedback_turn1_isfilled = data.is_turn1_filled;
      const feedback_turn2_isfilled = data.is_turn2_filled;
      if (isMounted()) {
        setIsTurn1Filled(feedback_turn1_isfilled);
        setIsTurn2Filled(feedback_turn2_isfilled);
      }
    } catch (err) {
      console.error(err);
    }
}

  useEffect( () => {
  getFeedback();
  determinateFeedback();
  }, [isMounted]);



  const handleSend = () => {
    setIsEditing(!isEditing);
  };

  const handleEdit = async () => {
    setIsEditing(false);
    try {
      const data = await feedBacksApi.createFeedback(
        course_id,
        feedbackTextFirstTurn,
        feedbackTextSecondTurn,
        feedbackTextAmelio,
        user.admin_id,
        auditName,
        customerDetails.firstname,
        customerDetails.lastname,
        1,
        customerDetails.email
      );
      toast.success("Feedback enregistré avec succés");
      setOpenToast(false);
      window.location.reload();
    } catch (err) {
      toast.error("Erreur lors de l'enregistrement du feedback");
      console.error(err);
    }
  };

  const handleSendSecondTurn = () => {
    setIsEditingSecondTurn(!isEditingSecondTurn);
  };

  const handleEditSecondTurn = async () => {
    setIsEditingSecondTurn(false);
    try {
      const data = await feedBacksApi.createFeedback(
        course_id,
        feedbackTextFirstTurn,
        feedbackTextSecondTurn,
        feedbackTextAmelio,
        user.admin_id,
        auditName,
        customerDetails.firstname,
        customerDetails.lastname,
        2,
        customerDetails.email
      );
      toast.success("Feedback enregistré avec succés");
      setOpenToast(false);
      window.location.reload();
    } catch (err) {
      toast.error("Erreur lors de l'enregistrement du feedback");
      console.error(err);
    }
  };

  const handleSendAmelio = () => {
    setIsEditingAmelio(!isEditingAmelio);
  };

  const handleEditAmelio = async () => {
    setIsEditingAmelio(false);
    try {
      const data = await feedBacksApi.createFeedback(
        course_id,
        feedbackTextFirstTurn,
        feedbackTextSecondTurn,
        feedbackTextAmelio,
        user.admin_id,
        auditName,
        customerDetails.firstname,
        customerDetails.lastname,
        3,
        customerDetails.email
      );
      toast.success("Feedback enregistré avec succés");
      setOpenToast(false);
      window.location.reload();
    } catch (err) {
      toast.error("Erreur lors de l'enregistrement du feedback");
      console.error(err);
    }
  };

  const getAnswer = useCallback(async () => {
    try {
      const data = await coursesApi.getTcsResult(user.admin_id, course_id);
      if (isMounted()) {
        setResultTcs(data);
        setCollapseStatesFirstTurn(
          new Array(data.tour_1.mini_vignette.length).fill(false)
        );
        setCollapseStatesSecondTurn(
          new Array(data.tour_2.mini_vignette.length).fill(false)
        );
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);
  const handleCollapseToggleFirstTurn = (index) => {
    setCollapseStatesFirstTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  const handleCollapseToggleSecondTurn = (index) => {
    setCollapseStatesSecondTurn((prevState) => {
      const updatedStates = [...prevState];
      updatedStates[index] = !updatedStates[index];
      return updatedStates;
    });
  };

  useEffect(() => {
    getAnswer();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isValidDate(date)) {
      return format(date, 'dd/MM/yyyy', { locale: fr });
    } else {
      return "Date invalide";
    }
  };
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };
  
  return (
    <>
      <Dialog onClose={handleClose} 
      open={open} 
      fullWidth 
      maxWidth="lg">
        <DialogTitle variant="h2" 
        align="center">
          Liste des réponses
        </DialogTitle>
        {resultTcs ? (
          resultTcs.tour_1 ? (
            <DialogContent>
              <DialogContentText>
                <Grid>
                  <Typography variant="h3" 
                  color="secondary">
                    Tour 1 :
                  </Typography>
                </Grid>
                <Grid container 
                display="flex">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      marginTop={"0.3em"}
                    >
                      <Grid item>
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                          Score de l'apprenant{" "}
                          {resultTcs.tour_1.correct_answers} /{" "}
                          {resultTcs.tour_1.total_questions}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontSize: 18, fontStyle: "italic", mt: 0.3 }}
                        >
                          ({resultTcs.tour_1.percentage}%)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {isTurn1Filled && (
                      <>
                        {!isEditing
                          ? resultTcs.tour_1 && (
                              <Grid marginLeft={5} 
                              marginTop={2} 
                              item 
                              xs={4}>
                                <Button
                                  variant="contained"
                                  sx={{ width: "300px" }}
                                  onClick={handleSend}
                                >
                                  {" "}
                                  FeedBack Tour 1{" "}
                                </Button>
                              </Grid>
                            )
                          : resultTcs.tour_1 && (
                              <Card
                                sx={{
                                  width: "600px",
                                }}
                              >
                                <CardContent>
                                  <TextField
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                    placeholder="Votre FeedBack par rapport au tour 1"
                                    sx={{
                                      "& textarea": {
                                        color: "black",
                                        backgroundColor: feedbackFirstTurnSent
                                          ? "#f0f0f0"
                                          : "white",
                                      },
                                      marginBottom: 2,
                                      overflowY: "auto",
                                      resize: "none",
                                      pointerEvents: "auto",
                                    }}
                                    value={feedbackTextFirstTurn}
                                    onChange={(e) =>
                                      setFeedbacktFirstTurn(e.target.value)
                                    }
                                    fullWidth
                                    inputProps={{
                                      readOnly: feedbackFirstTurnSent,
                                    }}
                                  />
                                  <Grid container 
                                  display="flex">
                                    <Grid item>
                                      {!feedbackFirstTurnSent && (
                                        <Button
                                          variant="contained"
                                          onClick={() => setOpenToast(true)}
                                          startIcon={<SendIcon />}
                                        >
                                          Envoyer à l'apprenant
                                        </Button>
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        onClick={handleSend}
                                        startIcon={<ClearIcon />}
                                        sx={{
                                          marginLeft: feedbackFirstTurnSent
                                            ? "0px"
                                            : "20px",
                                        }}
                                      >
                                        Ignorer
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <Snackbar
                                    open={openToast}
                                    autoHideDuration={6000} // Durée d'affichage du toast (en millisecondes)
                                    onClose={() => setOpenToast(false)}
                                    severity="info"
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Alert
                                      severity="success" // Vous pouvez utiliser 'success' ou 'error' pour un style différent
                                      sx={{ width: "100%" }}
                                      action={
                                        <>
                                          <Button
                                            color="inherit"
                                            size="small"
                                            onClick={() => setOpenToast(false)}
                                          >
                                            Annuler
                                          </Button>
                                          <Button
                                            color="inherit"
                                            size="small"
                                            onClick={handleEdit}
                                          >
                                            Confirmer
                                          </Button>
                                        </>
                                      }
                                    >
                                      Voulez-vous vraiment envoyer ce feedback ?
                                    </Alert>
                                  </Snackbar>
                                </CardContent>
                              </Card>
                            )}
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid sx={{ mt: "1em" }}>
                  {collapseStatesFirstTurn.length > 0 &&
                    resultTcs.tour_1.mini_vignette.map(
                      (mini_vignette, index) => (
                        <Grid
                          key={index}
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          sx={{
                            p: 1,
                            backgroundColor:
                              index % 2 === 0 ? "lightgrey" : "white",
                          }}
                        >
                          <Grid item>
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: 22 }}
                              onClick={() =>
                                handleCollapseToggleFirstTurn(index)
                              }
                            >
                              Mini vignette {index + 1}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: 13,
                                fontWeight: "bold",
                                textAlign: "center",
                              }}
                            >
                              {mini_vignette.score} / {mini_vignette.nb_answer}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Collapse
                              in={collapseStatesFirstTurn[index]}
                              timeout="auto"
                              unmountOnExit
                              sx={{ mt: 2 }}
                            >
                              {mini_vignette.result.map(
                                (vignette, indexVignette) => (
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    key={indexVignette}
                                  >
                                    <Typography
                                      sx={{ mb: 0.7, mr: 2, fontSize: 20 }}
                                    >
                                      {indexVignette + 1}.
                                    </Typography>
                                    {[-2, -1, 0, 1, 2].map(
                                      (value, indexResult) => (
                                        <Avatar
                                          key={indexResult}
                                          sx={{
                                            mx: 1,
                                            mb: 2,
                                            width: "3em",
                                            height: "3em",
                                            backgroundColor:
                                              value === vignette.good_answer
                                                ? "green"
                                                : value === vignette.answer
                                                ? "red"
                                                : "transparent",
                                          }}
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: 20,
                                              fontWeight: "bold",
                                              color: "black",
                                            }}
                                          >
                                            {value}
                                          </Typography>
                                        </Avatar>
                                      )
                                    )}
                                  </Grid>
                                )
                              )}
                            </Collapse>
                          </Grid>
                        </Grid>
                      )
                    )}
                </Grid>
                {/* <Grid marginBottom={1} 
                marginTop={3}>
                  <Typography variant="h5">
                    Interprétation des résultats
                  </Typography>
                </Grid> */}
                {/* <Grid marginLeft={4}> */}
                  {/* {resultTcs.tour_1.notes.improvement_text ||
                  resultTcs.tour_1.notes.positive ||
                  resultTcs.tour_1.notes.why ? (
                    <Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Points forts :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_1.notes.positive}
                        </Typography>
                      </Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Points à améliorer :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_1.notes.improvement_text}
                        </Typography>
                      </Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Pourquoi :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_1.notes.why}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    "Aucune interprétation des résultats"
                  )} */}
                {/* </Grid> */}
                <Grid container 
                display="flex">
                  <Grid item>
                    <Grid marginTop={4} 
                    marginBottom={1}>
                      <Typography variant="h5">
                        Objectifs d'améliorations
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item 
                  marginLeft={4}>
                    {!isEditingAmelio ? (
                      <Grid marginLeft={3} 
                      marginTop={3} 
                      item 
                      xs={4}>
                        <Button
                          variant="contained"
                          sx={{ width: "300px" }}
                          onClick={handleSendAmelio}
                        >
                          {" "}
                          FeedBack sur les objectifs d'améliorations{" "}
                        </Button>
                      </Grid>
                    ) : (
                      <Card
                        sx={{
                          width: "600px",
                        }}
                      >
                        <CardContent>
                          <TextField
                            multiline
                            minRows={6}
                            maxRows={6}
                            placeholder="Votre FeedBack par rapport aux objectifs d'améliorations"
                            sx={{
                              "& textarea": {
                                color: "black",
                                backgroundColor: feedbackImprovementSent
                                  ? "#f0f0f0"
                                  : "white",
                              },
                              marginBottom: 2,
                              overflowY: "auto",
                              resize: "none",
                              pointerEvents: "auto",
                            }}
                            value={feedbackTextAmelio}
                            onChange={(e) =>
                              setFeedbackTextAmelio(e.target.value)
                            }
                            fullWidth
                            inputProps={{
                              readOnly: feedbackImprovementSent,
                            }}
                          />
                          <Grid container 
                          display="flex">
                            <Grid item>
                              {!feedbackImprovementSent && (
                                <Button
                                  variant="contained"
                                  onClick={() => setOpenToast(true)}
                                  startIcon={<SendIcon />}
                                >
                                  Envoyer à l'apprenant
                                </Button>
                              )}
                            </Grid>
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={handleSendAmelio}
                                startIcon={<ClearIcon />}
                                sx={{
                                  marginLeft: feedbackImprovementSent
                                    ? "0px"
                                    : "20px",
                                }}
                              >
                                Ignorer
                              </Button>
                            </Grid>
                          </Grid>
                          <Snackbar
                            open={openToast}
                            autoHideDuration={6000} // Durée d'affichage du toast (en millisecondes)
                            onClose={() => setOpenToast(false)}
                            severity="info"
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <Alert
                              severity="success" // Vous pouvez utiliser 'success' ou 'error' pour un style différent
                              sx={{ width: "100%" }}
                              action={
                                <>
                                  <Button
                                    color="inherit"
                                    size="small"
                                    onClick={() => setOpenToast(false)}
                                  >
                                    Annuler
                                  </Button>
                                  <Button
                                    color="inherit"
                                    size="small"
                                    onClick={handleEditAmelio}
                                  >
                                    Confirmer
                                  </Button>
                                </>
                              }
                            >
                              Voulez-vous vraiment envoyer ce feedback ?
                            </Alert>
                          </Snackbar>
                        </CardContent>
                      </Card>
                    )}
                  </Grid>
                </Grid>
                <Grid marginLeft={4} 
                marginBottom={4}>
                  {resultTcs.tour_1.improvements.length > 0
                    ? (resultTcs.tour_1.improvements.map(
                        (improvement, index) => (
                          <Grid key={index}>
                            <Grid>
                              <Typography
                                variant="h6"
                                marginTop={1}
                                marginBottom={0.5}
                                color="#1155EE"
                              >
                                Objectif {index + 1} :
                              </Typography>
                            </Grid>
                            <Grid marginLeft={4}>
                              <Grid container 
                              justify="space-between">
                                <Typography
                                  inline
                                  fontWeight={"bold"}
                                  align="left"
                                >
                                  Commentaire :&nbsp;
                                </Typography>
                                <Typography
                                  inline
                                  variant="body1"
                                  align="right"
                                >
                                  {improvement.comment}
                                </Typography>
                              </Grid>
                              <Grid container 
                              justify="space-between">
                                <Typography
                                  inline
                                  fontWeight={"bold"}
                                  align="left"
                                >
                                  Objectif :&nbsp;
                                </Typography>
                                <Typography
                                  inline
                                  variant="body1"
                                  align="right"
                                >
                                  {improvement.objective}
                                </Typography>
                              </Grid>
                              <Grid container 
                              justify="space-between">
                                <Typography
                                  inline
                                  fontWeight={"bold"}
                                  align="left"
                                >
                                  Point à améliorer :&nbsp;
                                </Typography>
                                <Typography
                                  inline
                                  variant="body1"
                                  align="right"
                                >
                                  {improvement.point}
                                </Typography>
                              </Grid>
                              <Grid container 
                              justify="space-between">
                                <Typography
                                  inline
                                  fontWeight={"bold"}
                                  align="left"
                                >
                                  Stratégie :&nbsp;
                                </Typography>
                                <Typography
                                  inline
                                  variant="body1"
                                  align="right"
                                >
                                  {improvement.strategy}
                                </Typography>
                              </Grid>
                              <Grid container 
                              justify="space-between">
                                <Typography
                                  inline
                                  fontWeight={"bold"}
                                  align="left"
                                >
                                  Date limite :&nbsp;
                                </Typography>
                                <Typography
                                  inline
                                  variant="body1"
                                  align="right"
                                >
                                  {formatDate(improvement.limit_date)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      )
                    ):(
                      <Typography>Aucune amélioration</Typography>
                    )}
                </Grid>
                <Grid sx={{ mt: 6 }}>
                  <Typography variant="h3" 
                  color="secondary">
                    Tour 2 :
                  </Typography>
                </Grid>
                <Grid container 
                display="flex">
                  <Grid item>
                    <Grid
                      container
                      alignItems="center"
                      spacing={2}
                      marginTop={"0.3em"}
                    >
                      <Grid item>
                        <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                          Score de l'apprenant{" "}
                          {resultTcs.tour_2.correct_answers} /{" "}
                          {resultTcs.tour_2.total_questions}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ fontSize: 18, fontStyle: "italic", mt: 0.3 }}
                        >
                          ({resultTcs.tour_2.percentage}%)
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {isTurn2Filled && (
                      <>
                        {!isEditingSecondTurn
                          ? resultTcs.tour_2 && (
                              <Grid marginLeft={5} 
                              marginTop={2} 
                              item 
                              xs={4}>
                                <Button
                                  variant="contained"
                                  sx={{ width: "300px" }}
                                  onClick={handleSendSecondTurn}
                                >
                                  {" "}
                                  FeedBack Tour 2{" "}
                                </Button>
                              </Grid>
                            )
                          : resultTcs.tour_2 && (
                              <Card
                                sx={{
                                  width: "600px",
                                }}
                              >
                                <CardContent>
                                  <TextField
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                    placeholder="Votre FeedBack par rapport au tour 2"
                                    sx={{
                                      "& textarea": {
                                        color: "black",
                                        backgroundColor: feedbackSecondTurnSent
                                          ? "#f0f0f0"
                                          : "white",
                                      },
                                      marginBottom: 2,
                                      overflowY: "auto",
                                      resize: "none",
                                      pointerEvents: "auto",
                                    }}
                                    value={feedbackTextSecondTurn}
                                    onChange={(e) =>
                                      setFeedbackTextSecondTurn(e.target.value)
                                    }
                                    fullWidth
                                    inputProps={{
                                      readOnly: feedbackSecondTurnSent,
                                    }}
                                  />
                                  <Grid container 
                                  display="flex">
                                    <Grid item>
                                      {!feedbackSecondTurnSent && (
                                        <Button
                                          variant="contained"
                                          onClick={() => setOpenToast(true)}
                                          startIcon={<SendIcon />}
                                        >
                                          Envoyer à l'apprenant
                                        </Button>
                                      )}
                                    </Grid>
                                    <Grid item>
                                      <Button
                                        variant="contained"
                                        onClick={handleSendSecondTurn}
                                        startIcon={<ClearIcon />}
                                        sx={{
                                          marginLeft: feedbackSecondTurnSent
                                            ? "0px"
                                            : "20px",
                                        }}
                                      >
                                        Ignorer
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  <Snackbar
                                    open={openToast}
                                    autoHideDuration={6000} // Durée d'affichage du toast (en millisecondes)
                                    onClose={() => setOpenToast(false)}
                                    severity="info"
                                    anchorOrigin={{
                                      vertical: "top",
                                      horizontal: "center",
                                    }}
                                  >
                                    <Alert
                                      severity="success" // Vous pouvez utiliser 'success' ou 'error' pour un style différent
                                      sx={{ width: "100%" }}
                                      action={
                                        <>
                                          <Button
                                            color="inherit"
                                            size="small"
                                            onClick={() => setOpenToast(false)}
                                          >
                                            Annuler
                                          </Button>
                                          <Button
                                            color="inherit"
                                            size="small"
                                            onClick={handleEditSecondTurn}
                                          >
                                            Confirmer
                                          </Button>
                                        </>
                                      }
                                    >
                                      Voulez-vous vraiment envoyer ce feedback ?
                                    </Alert>
                                  </Snackbar>
                                </CardContent>
                              </Card>
                            )}
                      </>
                    )}
                  </Grid>
                </Grid>

                <Grid sx={{ mt: "1em" }}>
                  {collapseStatesSecondTurn.length > 0 &&
                    resultTcs.tour_2.mini_vignette.map(
                      (mini_vignette, index) => (
                        <Grid
                          key={index}
                          container
                          justifyContent="center"
                          alignItems="center"
                          flexDirection="column"
                          sx={{
                            p: 1,
                            backgroundColor:
                              index % 2 === 0 ? "lightgrey" : "white",
                          }}
                        >
                          <Grid item>
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: 22 }}
                              onClick={() =>
                                handleCollapseToggleSecondTurn(index)
                              }
                            >
                              Mini vignette {index + 1}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Collapse
                              in={collapseStatesSecondTurn[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Typography
                                sx={{
                                  fontSize: 13,
                                  fontWeight: "bold",
                                  textAlign: "center",
                                  mb: 2,
                                }}
                              >
                                {mini_vignette.score} /{" "}
                                {mini_vignette.nb_answer}
                              </Typography>
                              {mini_vignette.result.map(
                                (vignette, indexVignette) => (
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    key={indexVignette}
                                  >
                                    <Typography sx={{ mb: 0.7 }}>
                                      {indexVignette + 1}.
                                    </Typography>
                                    {[-2, -1, 0, 1, 2].map(
                                      (value, indexResult) => (
                                        <Typography
                                          key={indexResult}
                                          sx={{
                                            mx: 6,
                                            mb: 1,
                                            textAlign: "center",
                                            fontSize: 20,
                                            fontWeight: "bold",
                                            color:
                                              value === vignette.good_answer
                                                ? "green"
                                                : value === vignette.answer
                                                ? "red"
                                                : "grey",
                                          }}
                                        >
                                          {value}
                                        </Typography>
                                      )
                                    )}
                                  </Grid>
                                )
                              )}
                            </Collapse>
                          </Grid>
                        </Grid>
                      )
                    )}
                </Grid>
                {/* <Grid marginBottom={1} 
                marginTop={3}>
                  <Typography variant="h5">
                    Interprétation des résultats
                  </Typography>
                </Grid> */}
                {/* <Grid marginLeft={4}> */}
                  {/* {resultTcs.tour_2.notes.improvement_text ||
                  resultTcs.tour_2.notes.positive ||
                  resultTcs.tour_2.notes.why ? (
                    <Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Points forts :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_2.notes.positive}
                        </Typography>
                      </Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Points à améliorer :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_2.notes.improvement_text}
                        </Typography>
                      </Grid>
                      <Grid container 
                      justify="space-between">
                        <Typography inline 
                        fontWeight={"bold"} 
                        align="left">
                          Pourquoi :&nbsp;
                        </Typography>
                        <Typography inline 
                        variant="body1" 
                        align="right">
                          {resultTcs.tour_2.notes.why}
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    "Aucune interprétation des résultats"
                  )} */}
                {/* </Grid> */}
              </DialogContentText>
            </DialogContent>
          ) : (
            <Grid>
              <Typography variant="h6" 
              marginLeft={4}>
                {" "}
                La personne n'a pas encore terminé le Tour 1
              </Typography>
            </Grid>
          )
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding={5}
          >
            <CircularProgress color="inherit" 
            size={100} />
          </Box>
        )}
      </Dialog>
    </>
  );
};

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,IconButton,DialogActions,DialogContent,DialogTitle,Dialog
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { useEffect, useState } from "react";
import { feedBacksApi } from "../../../api/feedback-api";
import toast from 'react-hot-toast';

export const ImprovementsObjectives = (props) => {
  const { data, admin_id, setData,getAnswer } = props;

  const [editableIndexComment, setEditableIndexComment] = useState(null);
  const [editableIndexObjective, setEditableIndexObjective] = useState(null);
  const [editableIndexPoint, setEditableIndexPoint] = useState(null);
  const [editableIndexStrategy, setEditableIndexStrategy] = useState(null);

  const [editedComment, setEditedComment] = useState({});
  const [editedObjective, setEditedObjective] = useState({});
  const [editedPoint, setEditedPoint] = useState({});
  const [editedStrategy, setEditedStrategy] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [improvementToDelete, setImprovementToDelete] = useState(null);


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isValidDate(date)) {
      return format(date, "dd/MM/yyyy", { locale: fr });
    } else {
      return "Date invalide";
    }
  };

  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  } 
 
  
  const handleDoubleClickComment = (index, value) => {
    setEditableIndexComment(index);
    setEditedComment({ ...editedComment, [index]: value });
  };

  const handleDoubleClickObjective = (index, value) => {
    setEditableIndexObjective(index);
    setEditedObjective({ ...editedObjective, [index]: value });
  };

  const handleDoubleClickPoint = (index, value) => {
    setEditableIndexPoint(index);
    setEditedPoint({ ...editedPoint, [index]: value });
  };

  const handleDoubleClickStrategy = (index, value) => {
    setEditableIndexStrategy(index);
    setEditedStrategy({ ...editedStrategy, [index]: value });
  };

  // Fonctions pour gérer les modifications de texte dans chaque champ
  const handleTextChangeComment = (index, e) => {
    setEditedComment({ ...editedComment, [index]: e.target.value });
  };

  const handleTextChangeObjective = (index, e) => {
    setEditedObjective({ ...editedObjective, [index]: e.target.value });
  };

  const handleTextChangePoint = (index, e) => {
    setEditedPoint({ ...editedPoint, [index]: e.target.value });
  };

  const handleTextChangeStrategy = (index, e) => {
    setEditedStrategy({ ...editedStrategy, [index]: e.target.value });
  };

  const handleBlurComment = (index) => {
    handleSaveComment(index, "comment");
  };

  const handleBlurObjective = (index) => {
    handleSaveObjective(index, "objective");
  };

  const handleBlurPoint = (index) => {
    handleSavePoint(index, "point");
  };

  const handleBlurStrategy = (index) => {
    handleSaveStrategy(index, "strategy");
  };

  const handleSaveComment = async (index) => {
    try {
      const improvement = data[index];
      const improvementId = improvement.id;
      const editedValue = editedComment[index];
      // Effectuer la requête POST pour mettre à jour le champ de commentaire
      const response = await feedBacksApi.UpdateImprovements(
        "backoffice",
        admin_id,
        improvementId,
        "comment",
        editedValue
      );
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].comment = editedValue;
        return newData;
      });
      setEditableIndexComment(null);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du commentaire :", error);
    }
  };

  const handleSaveObjective = async (index) => {
    try {
      const improvement = data[index];
      const improvementId = improvement.id;
      const editedValue = editedObjective[index];
      // Effectuer la requête POST pour mettre à jour le champ de commentaire
      const response = await feedBacksApi.UpdateImprovements(
        "backoffice",
        admin_id,
        improvementId,
        "objective",
        editedValue
      );
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].objective = editedValue;
        return newData;
      });
      setEditableIndexObjective(null);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du commentaire :", error);
    }
  };

  const handleSavePoint = async (index) => {
    try {
      const improvement = data[index];
      const improvementId = improvement.id;
      const editedValue = editedPoint[index];
      // Effectuer la requête POST pour mettre à jour le champ de commentaire
      const response = await feedBacksApi.UpdateImprovements(
        "backoffice",
        admin_id,
        improvementId,
        "point",
        editedValue
      );
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].point = editedValue;
        return newData;
      });
      setEditableIndexPoint(null);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du commentaire :", error);
    }
  };

  const handleSaveStrategy = async (index) => {
    try {
      const improvement = data[index];
      const improvementId = improvement.id;
      const editedValue = editedStrategy[index];
      // Effectuer la requête POST pour mettre à jour le champ de commentaire
      const response = await feedBacksApi.UpdateImprovements(
        "backoffice",
        admin_id,
        improvementId,
        "strategy",
        editedValue
      );
      setData((prevData) => {
        const newData = [...prevData];
        newData[index].strategy = editedValue;
        return newData;
      });
      setEditableIndexStrategy(null);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du commentaire :", error);
    }
  };

  // const handleTabsChange = (value) => {
  //   setSelectedObjectiveTab(value);
  // };

  const handleChange = (event, newValue) => {
    setSelectedCriteriaTab(newValue);
  };

  const handleDeleteImprovement = (id) => {
    setOpenDeleteDialog(true);
    setImprovementToDelete(id);
  };
const handleCancelDeleteImprovement = () => {
  setOpenDeleteDialog(false);
  setImprovementToDelete(null);
  };

  const deleteImprovement = async (id) => {
    try{
      const response = await feedBacksApi.deleteImprovement( admin_id,id );
    if ( response) {
      getAnswer();
      toast.success("L'objectif a été supprimé");
  } else {
    toast.error("Il y a eu un souci lors de la suppression de l'objectif !");
  }
    setOpenDeleteDialog(false);
    setImprovementToDelete(null);
    }catch(e){
      console.error(e);
      toast.error("Il y a eu un souci lors de la suppression de l'objectif !");
      setOpenDeleteDialog(false);
      setImprovementToDelete(null);
    }
    
  };
  return (
    <>
      {/* <Grid>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 2, mb: 1, ml: 2 }}
        >
          Feedbacks
        </Typography>
      </Grid>
      <Card sx={{ m: 1, borderRadius: 5 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection :'column'
          }}
        >
          <Grid xs={12} sm={8}>
            {" "}
            {tabs.map((tab) => (
              <Button
                key={tab.value}
                variant={
                  selectedObjectiveTab === tab ? "contained" : "outlined"
                }
                onClick={() => handleTabsChange(tab)}
                sx={{
                  borderRadius: 999, // Valeur suffisamment grande pour rendre les boutons arrondis
                  marginRight: 1,
                  textTransform: "none",
                  width: "15%",
                  color: selectedObjectiveTab === tab ? "white" : "black", // Couleur du texte
                  backgroundColor:
                    selectedObjectiveTab === tab
                      ? process.env.REACT_APP_NEW_PRIMARY_COLOR
                      : "white", // Couleur de fond du bouton appuyé
                  boxShadow:
                    selectedObjectiveTab !== tab ? "0 0 0 1px white" : "none", // Ombre blanche sur le contour pour les boutons non sélectionnés
                }}
              >
                {tab}
              </Button>
            ))}
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
            <Tabs
              value={selectedCriteriaTab}
              onChange={handleChange}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {tabsCriterias.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab}
                  sx={{
                    minWidth: 0,
                    padding: "6px 16px",
                    textTransform: "none",
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </CardContent>
      </Card> */}

      <Grid marginTop={4} marginBottom={1}>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
        >
          Objectifs d'améliorations
        </Typography>
      </Grid>
      <TableContainer component={Paper} sx={{ marginTop: 4,m: 1, borderRadius: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Objectif d'amélioration
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Objectif
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Point à améliorer
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Stratégie
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Commentaire
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Date limite
              </TableCell>
              <TableCell
                backgroundColor={(theme) =>
                  theme.palette.mode === "dark" ? "black" : "#f0f0f0"
                }
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? (
              data.map((improvement, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Objectif {index + 1}
                  </TableCell>
                  <TableCell
                    onDoubleClick={() =>
                      handleDoubleClickObjective(index, improvement.objective)
                    }
                  >
                    {editableIndexObjective === index ? (
                      <TextField
                        name={index.toString()}
                        value={editedObjective[index] || improvement.objective}
                        onChange={(e) => handleTextChangeObjective(index, e)}
                        onBlur={() => handleBlurObjective(index)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleSaveObjective(index);
                          }
                        }}
                        autoFocus
                        sx={{ width: "400px", overflowY: "auto" }}
                        multiline
                        minRows={4}
                        maxRows={5}
                      />
                    ) : (
                      improvement.objective || "Non Renseigné"
                    )}
                  </TableCell>
                  <TableCell
                    onDoubleClick={() =>
                      handleDoubleClickPoint(index, improvement.point)
                    }
                  >
                    {editableIndexPoint === index ? (
                      <TextField
                        name={index.toString()}
                        value={editedPoint[index] || improvement.point}
                        onChange={(e) => handleTextChangePoint(index, e)}
                        onBlur={() => handleBlurPoint(index)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleSavePoint(index);
                          }
                        }}
                        autoFocus
                        sx={{ width: "400px", overflowY: "auto" }}
                        multiline
                        minRows={4}
                        maxRows={5}
                      />
                    ) : (
                      improvement.point || "Non Renseigné"
                    )}
                  </TableCell>
                  <TableCell
                    onDoubleClick={() =>
                      handleDoubleClickStrategy(index, improvement.strategy)
                    }
                  >
                    {editableIndexStrategy === index ? (
                      <TextField
                        name={index.toString()}
                        value={editedStrategy[index] || improvement.strategy}
                        onChange={(e) => handleTextChangeStrategy(index, e)}
                        onBlur={() => handleBlurStrategy(index)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleSaveStrategy(index);
                          }
                        }}
                        autoFocus
                        sx={{ width: "400px", overflowY: "auto" }}
                        multiline
                        minRows={1}
                        maxRows={5}
                      />
                    ) : (
                      improvement.strategy || "Non Renseigné"
                    )}
                  </TableCell>
                  <TableCell
                    onDoubleClick={() =>
                      handleDoubleClickComment(index, improvement.comment)
                    }
                  >
                    {editableIndexComment === index ? (
                      <TextField
                        name={index.toString()}
                        value={editedComment[index] || improvement.comment}
                        onChange={(e) => handleTextChangeComment(index, e)}
                        onBlur={() => handleBlurComment(index)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            handleSaveComment(index);
                          }
                        }}
                        autoFocus
                        sx={{ width: "400px", overflowY: "auto" }}
                        minRows={4}
                        maxRows={5}
                        multiline
                      />
                    ) : (
                      improvement.comment || "Non Renseigné"
                    )}
                  </TableCell>
                  <TableCell>
                    {formatDate(improvement.limit_date) || "Non Renseigné"}
                  </TableCell>
                  <TableCell>
                  <IconButton
            sx={{ borderRadius: "50%" }}
            onClick={() => handleDeleteImprovement(improvement.id) }
          ><Tooltip title="Supprimer l'objectif">
            <DeleteIcon fontSize="medium" /></Tooltip>
          </IconButton>
        
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5}>Aucune amélioration</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Dialog open={openDeleteDialog} onClose={() =>handleCancelDeleteImprovement}>
              <DialogTitle>Suppression d'objectif d'amélioration</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir supprimer cet objectif ?
              </DialogContent>
              <DialogActions>
                <Button onClick={() =>handleCancelDeleteImprovement} color="primary">
                  Annuler
                </Button>
                <Button onClick={() =>deleteImprovement(improvementToDelete)} color="primary">
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
      </TableContainer>  
      
            
    </>
  );
};

import {
  Box,
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Input,
  Divider,
  TextField,
  CircularProgress,
  Tooltip,Dialog,
  DialogTitle,MenuItem,
  DialogContent,Select,
  DialogActions,
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { Scrollbar } from '../../scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { auditsApi } from '../../../api/audit-api';
import { useAuth } from '../../../hooks/use-auth';
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { Delete as DeleteIcon, FileCopy as DuplicateIcon } from '@mui/icons-material';

export const AuditsList = (props) => {
  const { audits, isFetchLoading, getGraphics } = props;
  const { user } = useAuth();
  const [permissions, setPermissions] = useState([]);
  const [filteredAudits, setFilteredAudits] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [createBool, setCreateBool] = useState(false);
  const [createAuditName, setCreateAuditName] = useState([]);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [disableDialogOpen, setDisableDialogOpen] = useState(false);
  const [enableDialogOpen, setEnableDialogOpen] = useState(false);
  const [selectedAuditId, setSelectedAuditId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(true);
  const navigate = useNavigate();

  // Fonction pour vérifier si l'utilisateur a une permission spécifique
  const hasPermission = (component, requiredPermission) => {
    return permissions.some(
      (perm) => perm.component === component && perm.permission === requiredPermission
    );
  };

  // Vérifie si l'utilisateur a la permission "Voir" pour le composant "EPP"
  const hasViewAuditPermission = hasPermission("EPP", "Voir");

  // Vérifie si l'utilisateur a la permission "create" pour le composant "EPP"
  const hasCreateAuditPermission = hasPermission("EPP", "Créer");

  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      "roles_id": roles_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');

    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Appeler l'API pour récupérer les permissions lorsque le composant est monté
  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);

  useEffect(() => {
    // console.log(user);
    setFilteredAudits(audits)
  }, [audits])

  const handleChangeSearch = event => {
    setSearchName(event.target.value)
    setFilteredAudits(audits.filter((el) => el.name.toLowerCase().includes(event.target.value.toLowerCase())))
  }

  const handleCreateAudit = async event => {
    const id = await auditsApi.createAudit(user.admin_id, createAuditName, "audit");
    navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/audits/${id.id}`)
  }

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleDuplicateClick = (id) => {
    setSelectedAuditId(id);
    setDuplicateDialogOpen(true);
  };

  const handleCancel = () => {
    setSelectedAuditId(null);
    setDuplicateDialogOpen(false);
  };

  const handleConfirmDuplicate = async () => {
    try {
      const data = await auditsApi.DuplicateAuditById(user.admin_id, selectedAuditId);
      setFilteredAudits(audits)
      setDuplicateDialogOpen(false)
      getGraphics()
      toast.success('Audit dupliqué');
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/audits/${data.new_audit_id}`)
    } catch (error) {
      console.error("Erreur lors de la duplication de l'audit", error);
    }
  }

  return (
    <>
      {/* Afficher le bouton "Créer un audit" si l'utilisateur a la permission "create" */}
      {hasCreateAuditPermission && (
        <Box
          sx={{
            m: 1,
            width: '95%',
            display: 'flex',
            justifyContent: "flex-end",
          }}
        >
          {createBool === false ? (
            <Button
              onClick={() => {
                setCreateBool(true)
              }}
              variant='contained'
            >
              Créer un audit
            </Button>
          ) : (
            <Box>
              <TextField
                onChange={(event) => {
                  setCreateAuditName(event.target.value)
                }}
                sx={{
                  backgroundColor: 'white',
                }}
                label="Entrez le nom de l'audit"
              />
              <Button
                sx={{
                  m: 1
                }}
                variant='contained'
                onClick={() => {
                  setCreateBool(false)
                }}
              >
                Annuler
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  handleCreateAudit()
                }}
              >
                Créer
              </Button>
            </Box>
          )}
        </Box>
      )}

      {/* Afficher la liste des audits si l'utilisateur a la permission "Voir" */}
      {hasViewAuditPermission ? (
        <Card {...props}>
          <Scrollbar>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2.5
              }}
            >
              <SearchIcon fontSize="small" />
              <Box
                sx={{
                  flexGrow: 1,
                  ml: 3
                }}
              >
                <Input
                  color='secondary'
                  disableUnderline
                  fullWidth
                  value={searchName}
                  onChange={handleChangeSearch}
                  placeholder="Chercher un audit"
                />
              </Box>
            </Box>
            <Divider />
            <Table sx={{ minWidth: 700 }}>
              <TableBody>
                {isFetchLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '100px' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  audits && audits.length > 0 ? filteredAudits.map((audit, index) => (
                    <TableRow
                      hover
                      key={index}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={handleMouseLeave}
                    >
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            '& > img': {
                              flexShrink: 0,
                              height: 56,
                              width: 56
                            }
                          }}
                        >
                          {audit.image_link
                            ? (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  backgroundColor: 'background.default',
                                  backgroundImage: `url(${audit.image_link})`,
                                  backgroundPosition: 'center',
                                  backgroundSize: 'cover',
                                  borderRadius: 1,
                                  display: 'flex',
                                  height: 100,
                                  justifyContent: 'center',
                                  overflow: 'hidden',
                                  width: 100
                                }}
                              />
                            )
                            : (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  backgroundColor: 'background.default',
                                  borderRadius: 1,
                                  display: 'flex',
                                  height: 100,
                                  justifyContent: 'center',
                                  width: 100
                                }}
                              >
                                <ImageIcon fontSize="small" />
                              </Box>
                            )}
                          <Box sx={{ ml: 3 }}>
                            <IconButton
                              component={RouterLink}
                              to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/audits/${audit.id}`}
                            >
                              <Typography variant="h6"
                                color='primary'
                              >
                                {audit.name}
                              </Typography>
                            </IconButton>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: '15%' }}>
                        {hoveredRowIndex === index && (
                          <>
                            <Tooltip title="Dupliquer l'audit">
                              <IconButton
                                onClick={() => handleDuplicateClick(audit.id)}
                                color="primary"
                              >
                                <DuplicateIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  )) : <Typography sx={{ p: 3 }}> Pas d'audits associés </Typography>)}
              </TableBody>
              <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
                <DialogTitle>Confirmation de duplication</DialogTitle>
                <DialogContent>
                  Êtes-vous sûr de vouloir dupliquer cet audit ?
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCancel} color="primary">
                    Annuler
                  </Button>
                  <Button onClick={handleConfirmDuplicate} color="primary">
                    Dupliquer
                  </Button>
                </DialogActions>
              </Dialog>
            </Table>
          </Scrollbar>
        </Card>
      ) : (
        <Box sx={{ textAlign: "center", mt: 6 }}>
         <img
      src="https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
      alt="Accès refusé"
      style={{ maxWidth: "300px", marginBottom: "16px" }}
    />
          <Typography variant="h6" sx={{ color: "error.main" }}>
            Vous n'avez pas la permission de Voir les Audits.
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary", mt: 1 }}>
            Veuillez contacter un administrateur si vous pensez qu'il s'agit d'une erreur.
          </Typography>
        </Box>
      )}
    </>
  )
};
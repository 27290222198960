import { useEffect, useState } from 'react';
import { Box, Card, Typography, Grid, CircularProgress } from '@mui/material';
import { GuestGuard } from '../../components/authentication/guest-guard';
import { useAuth } from '../../hooks/use-auth';
import { gtm } from '../../lib/gtm';
import pauline from './../../images/new_pauline_1.png';
import Logo_Kalanke_Blanc from './../../images/Logo-Kalanke-Blanc.png';
import Logo_Kalanke from './../../images/Logo-Kalanke.png';
import { useParams, useNavigate } from 'react-router-dom';
import { JWTLogin } from '../../components/authentication/jwt-login';
import { JWTForgotPassword } from '../../components/authentication/jwt-forgot-password';
import { authApi } from "../../api/auth-api";
const Login = () => {
  const { platform } = useAuth();
  const [isPasswordForgotten, setIsPasswordForgotten] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state to manage tenant check
  const [tenantError, setTenantError] = useState(null); // Track tenant validation error
  const navigate = useNavigate();
  const { tenant } = useParams();

  const checkTenant = async () => {
    try {
      const res = await authApi.getOrgName(tenant);
      if (res.name) {
        // console.log(`res name : ${res.name}`);
        setTenantError(null);
        localStorage.setItem('tenant', tenant);
        
        gtm.push({ event: 'page_view' });
        
      } else if (res.msg =='tenant not found') {
        setTenantError("Nom d'organisme incorrecte");
        navigate(`/not-found`);
      }
    } catch (err) {
      console.error(err);
      setTenantError('Connexion impossible : problème au niveau du serveur');
      navigate(`/not-found`);
    } finally {
      // Stop loading after check
      // setLoading(false); 
    }
  };
  const checkTenantandNavigate = async () =>{
//     if (globalThis.localStorage.getItem("accessToken")==null){
//  navigate(`/${globalThis.localStorage.getItem('tenant')}`)  
//     }
//     else{
  await checkTenant();
  if (globalThis.localStorage.getItem('tenant')!== null && globalThis.localStorage.getItem('accessToken')!==null){
    navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard`);
  }
  setLoading(false); 
// }
// Run tenant validation on mount
  };
  useEffect(() => {
    checkTenantandNavigate();
  }, []);

  const handleForgottenPassword = () => {
    setIsPasswordForgotten(true);
  };

  if (loading) {
    return (
      <Grid 
      container 
      justifyContent="center" 
      alignItems="center" 
      style={{ height: '100vh', position: 'relative' }}
    >
      {/* Circular Progress underneath the image */}
      <CircularProgress 
        size={170} // Adjust the size as needed
        style={{ position: 'absolute' }}
      />
    
      {/* Image on top */}
      <img
        alt="logo"
        src={Logo_Kalanke}
        style={{
          width: '120px', // Adjust the size to fit over the CircularProgress
          position: 'absolute',
        }}
      />
    </Grid>
    

    );
  }

  return (
    <Grid container style={{ height: '100vh', width: '100vw' }}>
      {/* Left Side (Logo and Background) */}
      <Grid item style={{ height: '100%', width: '47%', backgroundColor: '#5d5db7', position: 'relative' }}>
        <img
          alt="logo"
          src={Logo_Kalanke_Blanc}
          style={{ width: '200px', position: 'absolute', bottom: '60px', left: '60px', zIndex: 2 }}
        />
        <div style={{ width: '100%', height: '100%', display: 'flex', zIndex: 0 }}>
          <img alt="Image" src={pauline} style={{ width: '100%', opacity: 1 }} />
        </div>
      </Grid>

      {/* Right Side (Form and Login) */}
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ height: '100%', width: '53%', backgroundColor: '#ffffff' }}
      >
        <div>
          <Typography
            style={{ textAlign: 'center', color: '#5d5db7', fontSize: '24px', fontFamily: '"Montserrat", sans-serif' }}
          >
            Kalanke
          </Typography>
          <Typography
            variant="h3"
            style={{
              textAlign: 'center',
              color: '#5d5db7',
              fontSize: '26px',
              fontWeight: 900,
              fontFamily: '"Montserrat", sans-serif',
            }}
          >
            Back-Office
          </Typography>
        </div>
        <Card elevation={10} style={{ width: '50%', padding: '40px', borderRadius: 25, backgroundColor: 'white' }}>
          {platform === 'JWT' && !isPasswordForgotten && (
            <Typography
              variant="h3"
              style={{
                fontWeight: '900',
                fontFamily: '"Montserrat", sans-serif',
                marginBottom: '20px',
                color: 'black',
              }}
            >
              Connexion
            </Typography>
          )}

          {platform === 'JWT' && !isPasswordForgotten ? (
            <JWTLogin
              handleForgottenPassword={handleForgottenPassword}
              setLoginError={setLoginError}
              loginError={loginError}
            />
          ) : (
            <JWTForgotPassword
              setIsPasswordForgotten={setIsPasswordForgotten}
              setLoginError={setLoginError}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

Login.getLayout = (page) => <GuestGuard>{page}</GuestGuard>;

export default Login;

import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Collapse, TextField, IconButton, Button ,  Link,Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { useAuth } from "../../../../hooks/use-auth";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Fonction pour transformer les permissions en structure hiérarchique
const transformPermissions = (rolesPermissions) => {
  if (!rolesPermissions || !Array.isArray(rolesPermissions)) {
    console.error("Invalid rolesPermissions data:", rolesPermissions);
    return [];
  }

  const components = [];

  rolesPermissions.forEach(({ components: roleComponents }) => {
    roleComponents.forEach(({ component, permissions }) => {
      const cleanedComponent = component.trim();
      const componentNode = {
        label: cleanedComponent,
        children: [],
      };

      permissions.forEach((action) => {
        const permissionNode = {
          id: action.id, 
          label: action.name,
          assigned: action.assigned,
          parent: componentNode, // Ajouter une référence au parent
        };
        componentNode.children.push(permissionNode);
      });

      components.push(componentNode);
    });
  });

  return components;
};

// Fonction pour récupérer les permissions depuis l'API
const getAllPermissionsByRoleId = async (roleId, userRoleId) => {
  const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permissionRole/get/${roleId}/${userRoleId}/`;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const resp = await fetch(url, {
      method: "GET", // Garder la méthode GET
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        // "X-User-Role-Id": userRoleId, // Envoyer user_role_id dans les headers
      },
    });

    if (!resp.ok) {
      throw new Error(`HTTP error! Status: ${resp.status}`);
    }

    const json = await resp.json();
    return json.role_permissions;
  } catch (error) {
    console.error("Error fetching permissions by role ID:", error);
    return null;
  }
};
const updateRoleName = async (roleId, newRoleName) => {
  const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/role/update/${roleId}/`;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const resp = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ role_name: newRoleName }), // Envoyer le nouveau nom du rôle
    });

    if (!resp.ok) {
      throw new Error(`HTTP error! Status: ${resp.status}`);
    }

    const json = await resp.json();
    console.log("Nom du rôle mis à jour avec succès:", json);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du nom du rôle:", error);
  }
};

// Fonction pour envoyer les permissions au backend
const savePermissions = async (selectedPermissions) => {
  const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission/save/`;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(selectedPermissions),
    });

    if (!resp.ok) {
      throw new Error(`HTTP error! Status: ${resp.status}`);
    }

    const json = await resp.json();
    console.log("Permissions sauvegardées avec succès:", json);
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des permissions:", error);
  }
};

// Composant PermissionsTreee
const PermissionsTreee = ({ permissions = [], setPermissions }) => {
  const [expanded, setExpanded] = useState({});
  const [checked, setChecked] = useState({});
  const [search, setSearch] = useState("");
 
  const navigate = useNavigate(); // Définir la navigation

  useEffect(() => {
    const initialChecked = {};
    permissions.forEach((component) => {
      const componentKey = component.label;
  
      // Vérifier l'état des permissions enfants
      const allAssigned = component.children.every((perm) => perm.assigned);
      const someAssigned = component.children.some((perm) => perm.assigned);
  
      // Définir l'état du composant
      if (allAssigned) {
        initialChecked[componentKey] = true; 
      } else if (someAssigned) {
        initialChecked[componentKey] = "indeterminate"; 
      } else {
        initialChecked[componentKey] = false; 
      }
  
      // Initialiser les permissions enfants
      component.children.forEach((perm) => {
        const permKey = `${componentKey}-${perm.id}`; 
        initialChecked[permKey] = perm.assigned; // Met à jour correctement l'état selon `assigned`
      });
    });
  
    setChecked(initialChecked);
  }, [permissions]);

  const handleToggle = (label) => {
    setExpanded((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const handleCheck = (node, isChecked) => {
    let newChecked = { ...checked };
  
    const uniqueKey = node.id ? `${node.parent.label}-${node.id}` : node.label;
  
    newChecked[uniqueKey] = isChecked;
  
    if (node.children) {
      node.children.forEach((child) => {
        const childKey = `${node.label}-${child.id}`;
        newChecked[childKey] = isChecked;
      });
    }
  
    if (node.parent) {
      const parentKey = node.parent.label;
      const childrenKeys = node.parent.children.map((child) => `${node.parent.label}-${child.id}`);
  
      const allChildrenChecked = childrenKeys.every((key) => newChecked[key] === true);
      const someChildrenChecked = childrenKeys.some((key) => newChecked[key] === true);
  
      if (allChildrenChecked) {
        newChecked[parentKey] = true;
      } else if (someChildrenChecked) {
        newChecked[parentKey] = "indeterminate";
      } else {
        newChecked[parentKey] = false;
      }
    }
  
    setChecked(newChecked);
  };

  const collectSelectedPermissions = () => {
    const selectedPermissions = [];
  
    permissions.forEach((component) => {
      const componentPermissions = {
        component: component.label,
        permissions: [],
      };
  
      component.children.forEach((perm) => {
        const permKey = `${component.label}-${perm.id}`;
        if (checked[permKey]) {
          componentPermissions.permissions.push({
            id: perm.id,
            name: perm.label,
            assigned: true,
          });
        }
      });
  
      selectedPermissions.push(componentPermissions);
    });
  
    // Ajouter l'ID du rôle
    const roleId = sessionStorage.getItem("roleId");
    return [{ role_id: roleId, components: selectedPermissions }];
  };

  const handleSave = async () => {
    const selectedPermissions = collectSelectedPermissions();
    const loadingToast = toast.loading("Sauvegarde en cours...", {
      duration: 0,
    });
    try {
      await savePermissions(selectedPermissions);
      toast.success("Rôle et permissions sauvegardés avec succès !", {
        id: loadingToast,
        duration: 5000,
      });
       setTimeout(() => {
            const tenant = localStorage.getItem('tenant');
            navigate(`/${tenant}/dashboard/roles`); // Redirection après succès
          }, 1500)
    } catch (error) {
      console.error("Erreur lors de la sauvegarde :", error);
      toast.error("Une erreur est survenue lors de la sauvegarde.", {
        id: loadingToast,
        duration: 5000,
      });    }
  };

  const renderTree = (nodes, level = 0) =>
    nodes
      .filter((node) => typeof node.label === "string" && node.label.toLowerCase().includes(search.toLowerCase()))
      .map((node) => {
        const uniqueKey = node.id ? `${node.parent?.label}-${node.id}` : node.label;

        return (
          <div key={uniqueKey} style={{ marginLeft: `${level * 50}px`, marginBottom: "-6px" }}>
            <div style={{ display: "flex", alignItems: "center", marginBottom: "-6px" }}>
              {node.children && node.children.length > 0 && (
                <IconButton
                  onClick={() => handleToggle(node.label)}
                  style={{
                    transform: expanded[node.label] ? "rotate(0deg)" : "rotate(-90deg)",
                    transition: "transform 0.3s ease",
                    padding: "4px",
                  }}
                >
                  <ExpandMore />
                </IconButton>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked[uniqueKey] === true}
                    indeterminate={checked[uniqueKey] === "indeterminate"}
                    onChange={(e) => handleCheck(node, e.target.checked)}
                    size="small"
                  />
                }
                label={node.label}
                style={{ margin: "0px", padding: "0px" }}
              />
            </div>
            {node.children && (
              <Collapse in={expanded[node.label]}>
                {renderTree(node.children, level + 1)}
              </Collapse>
            )}
          </div>
        );
      });

  return (
    <div>
      {renderTree(permissions)}
      <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: "40px" }}>
        Sauvegarder
      </Button>
    </div>
  );
};

export default function PermissionsComponent() {
  const [permissions, setPermissions] = useState([]);
  const [roleName, setRoleName] = useState(sessionStorage.getItem("roleName") || "");
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth(); // Extraire l'utilisateur connecté
  const userRoleId = user?.roles_id;

  console.log("User:", user); // Log de l'utilisateur
  console.log("User Role ID:", userRoleId); 

  useEffect(() => {
    const roleId = sessionStorage.getItem("roleId"); // Récupérer l'ID du rôle sélectionné
  
    if (roleId && userRoleId) {
      const fetchPermissions = async () => {
        const data = await getAllPermissionsByRoleId(roleId, userRoleId); // Appeler l'API
        if (data) {
          const transformedData = transformPermissions([data]); // Transformer les données
          setPermissions(transformedData);
          console.log("Permissions récupérées :", data);
        }
      };
      fetchPermissions();
    }
  }, [user, userRoleId]); // Déclencher l'effet lorsque l'utilisateur change

  const handleRoleNameChange = async (newRoleName) => {
    if (newRoleName.trim() === "") {
      toast.error("Le nom du rôle ne peut pas être vide.");
      return;
    }
  
    const roleId = sessionStorage.getItem("roleId");
    if (!roleId) {
      toast.error("Aucun rôle sélectionné.");
      return;
    }
  
    try {
      // Mettre à jour le nom du rôle dans le backend
      await updateRoleName(roleId, newRoleName);
  
      // Mettre à jour l'état local et sessionStorage
      setRoleName(newRoleName);
      sessionStorage.setItem("roleName", newRoleName);
  
      // Rafraîchir les permissions après la mise à jour du nom
      const data = await getAllPermissionsByRoleId(roleId);
      if (data) {
        const transformedData = transformPermissions([data]);
        setPermissions(transformedData);
  
        // Reconstruire l'objet `checked` avec le nouveau nom du rôle
        const newChecked = {};
        transformedData.forEach((role) => {
          role.children.forEach((component) => {
            component.children.forEach((perm) => {
              const permKey = `${role.id}-${perm.id}`; // Utiliser l'ID du rôle et l'ID de la permission
              newChecked[permKey] = perm.assigned; // Utiliser la valeur `assigned` de l'API
            });
          });
        });
  
        setChecked(newChecked); // Mettre à jour l'état `checked`
      }
  
      toast.success("Nom du rôle mis à jour avec succès !");
    } catch (error) {
      console.error("Erreur lors de la mise à jour du nom du rôle:", error);
      toast.error("Une erreur est survenue lors de la mise à jour du nom du rôle.");
    }
  };

  return (
    <div style={{ padding: "40px" }}>
       <RouterLink
          to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/roles`}
          style={{ textDecoration: "none" }}
        >
          <Link
            color="textPrimary"
            component="a"
            sx={{
              alignItems: "center",
              display: "flex",
              marginTop : "30px"
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="subtitle2" component="span">
              {" "}
              Liste des Rôles
            </Typography>
          </Link>
        </RouterLink>
      <h3>Personnalisation du Rôle et de ses Permissions</h3>
      <div><Toaster /></div>
      {isEditing ? (
        <TextField
          variant="outlined"
          fullWidth
          value={roleName}
          onChange={(e) => setRoleName(e.target.value)}
          onBlur={() => {
            handleRoleNameChange(roleName); // Sauvegarder le nouveau nom
            setIsEditing(false); // Quitter l'édition
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleRoleNameChange(roleName); // Sauvegarder le nouveau nom
              setIsEditing(false); // Quitter l'édition
            }
          }}
          autoFocus
          style={{ marginBottom: "20px", width: "25%" }}
          />
      ) : (
        <label
          onDoubleClick={() => setIsEditing(true)}
          style={{
            display: "block",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            cursor: "pointer",
            backgroundColor: "#f9f9f9",
            marginBottom: "20px",
            width: "25%" 
          }}
        >
          {roleName}
        </label>
      )}

      <PermissionsTreee permissions={permissions} setPermissions={setPermissions} />
    </div>
  );
}
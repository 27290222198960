import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../hooks/use-auth";
import { gtm } from "../../lib/gtm";
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useMounted } from "../../hooks/use-mounted";
import { topicsApi } from "../../api/topic-api";
import { auditsApi } from "../../api/audit-api";
import DashboardFormateur from "./dashbord-formateur";
import DashboardAdmin from "./dashboard-admin";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const Dashboard = () => {
  const isMounted = useMounted();
  const [progression, setProgression] = useState([]);
  const [meanTime, setMeanTime] = useState([]);
  const [isLoadingTime, setIsLoadingTime] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [permissions, setPermissions] = useState([]); // État pour stocker les permissions

  // État pour contrôler la taille de la page
  const [zoomLevel, setZoomLevel] = useState(0.7);
  const [width, setWidth] = useState("177%");

  const { user } = useAuth();
// Fonction pour vérifier si l'utilisateur a la permission "update" pour le composant "Dashboard"
  // Fonction pour récupérer les permissions
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      roles_id: roles_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
        if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);const json = await resp.json();
        setPermissions(json.permissions);
      }} catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    // const getMeanTime = useCallback(async () => {
    //   try {
    //     const data = await auditsApi.meanTime(user.admin_id);
    //     if (isMounted()) {
    //       setMeanTime(data);
    //       // console.log("largeur",window.innerWidth);
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   } finally {
    //     setIsLoadingTime(false);
    //   }
    // });
      

  

  // Récupérer les permissions lorsque le composant est monté
  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);

  const getMeanTime = useCallback(async () => {
    try {
      const data = await auditsApi.meanTime(user.admin_id);
      if (isMounted()) {
        setMeanTime(data);
        console.log("largeur", window.innerWidth);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoadingTime(false);
    }
  }, [isMounted]);

  const getProgression = useCallback(async () => {
    try {
      const data = await topicsApi.getProgression(user.admin_id);
      if (isMounted()) {
        setProgression(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  // Gérer les changements de largeur de l'écran
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1300 && screenWidth <= 1370) {
        setWidth("176%");
        setZoomLevel(0.63);
      } else if (screenWidth >= 1390 && screenWidth <= 1440) {
        setWidth("167%");
        setZoomLevel(0.66);
      } else if (screenWidth >= 1500 && screenWidth < 1600) {
        setWidth("154%");
        setZoomLevel(0.69);
      } else if (screenWidth >= 1600 && screenWidth <= 1650) {
        setWidth("145%");
        setZoomLevel(0.74);
      } else if (screenWidth >= 1650 && screenWidth <= 1700) {
        setWidth("138%");
        setZoomLevel(0.78);
      } else if (screenWidth >= 1200 && screenWidth <= 1299) {
        setWidth("191%");
        setZoomLevel(0.585);
      } else if (screenWidth > 1700) {
        setWidth("120%");
        setZoomLevel(0.9);
      }
    };

    // Définir les valeurs initiales
    handleResize();

    // Ajouter un écouteur d'événements pour les redimensionnements
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getProgression();
    getMeanTime();
    gtm.push({ event: "page_view" });
  }, []);

  const handleToggleEditMode = () => {
    setIsEditMode((prev) => !prev);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
        height: "100vh",
        width: width,
        backgroundColor: (theme) =>
          theme.palette.mode === "dark"
            ? "#2C2F3F"
            : isEditMode
            ? "white"
            : "#F9FAFB",
        transition: "all 0.3s ease",
        transform: `scale(${zoomLevel})`,
        transformOrigin: "top left",
        ml: -11, // Déplace légèrement à gauche
      }}
    >
      <Container maxWidth="xl" sx={{ padding: { xs: 2, sm: 3 } }}>
        <Grid container spacing={2} alignItems="center" sx={{ ml: 6 }}>
          <Grid item>
            <Typography
              sx={{
                color: (theme) =>
                  theme.palette.mode === "dark" ? "white" : "black",
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: { xs: "1.5rem", sm: "1.75rem" },
                transition: "color 0.3s ease",
              }}
              variant="h4"
            >
              Tableau de Bord
            </Typography>
          </Grid>

          {/* Afficher le bouton de mode édition uniquement si l'utilisateur a la permission */}
          
            <Grid item>
              <IconButton
                onClick={handleToggleEditMode}
                sx={{
                  color: isEditMode ? "primary.main" : "text.secondary",
                  "&:hover": {
                    color: "primary.dark",
                  },
                }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Grid>
          
        </Grid>

        {isEditMode && (
          <Grid container>
            <Grid item sx={{ ml: 9, height: "35px" }}>
              <Typography sx={{ fontSize: "1.2rem" }}>
                Mode édition
              </Typography>
            </Grid>
          </Grid>
        )}

        <Box sx={{ mb: 5 }}>
          <Divider sx={{ borderBottomWidth: 2, opacity: 0.5 }} />
        </Box>

        <DashboardAdmin
          progression={progression}
          admin_id={user.admin_id}
          isEditMode={isEditMode}
          id_service={user.id_service}
        />
      </Container>
    </Box>
  );
};

export default Dashboard;
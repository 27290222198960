import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function NotAvailable(props) {
    const { origin } = props
    const [resource, setResource] = useState("")
    const [redirectionLink, setRedirectionLink] = useState("")
    const [redirectionLabel, setRedirectionLabel] = useState("")
    const navigate = useNavigate()

    const handleClick = () => {
      navigate(redirectionLink)  
    }

    useEffect(() => {
      if (origin === "audits") {
        setResource("L'audit que vous recherchez n'existe pas ou a été déplacé.")  
        setRedirectionLink(`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits`)
        setRedirectionLabel("Revenir à la page d'audits")
      } else if (origin === "apprenants") {
        setResource("L'apprenant que vous recherchez n'existe pas ou a été déplacé.")
        setRedirectionLink(`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants`)
        setRedirectionLabel("Revenir à la page d'apprenants")
      } else if (origin === "feedbacks") {
        setResource("L'apprenant que vous recherchez n'existe pas ou a été déplacé.")
        setRedirectionLink(`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants`)
        setRedirectionLabel("Revenir à la page d'apprenants")
      } else if (origin === "sessions") {
        setResource("La session que vous recherchez n'existe pas ou a été déplacée.")
        setRedirectionLink(`/${globalThis.localStorage.getItem('tenant')}/dashboard/session`)
        setRedirectionLabel("Revenir à la page de sessions")
      } else {
        setResource("La ressource que vous recherchez n'existe pas ou a été déplacée.")
        setRedirectionLink("/dashboard")
        setRedirectionLabel("Revenir à la page d'accueil")
      }
    }, [])

    return (
        <Box component="main" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Box sx={{ overflow: 'hidden', display: 'inline-block', height: 250, mt: -20 }}>
            <Box
              component="img"
              sx={{
                  mt: -18,
                  width : '778px',
                  height: '500px',
              }}
              alt="Kalanke"
              src={process.env.REACT_APP_LOGO_SPLASH_SCREEN}
            />
          </Box>
            <Typography variant="h1" sx={{ mt: 5 }}>Ressource non disponible</Typography>
            <Typography variant="h4" sx={{ fontWeight: 400 }}>{resource}</Typography>
            <Button size="large" sx={{ fontSize: 18, mt: 5 }} onClick={handleClick}>{redirectionLabel}</Button>
        </Box>
    )
}

import ReactDOM from 'react-dom'
import { StrictMode } from 'react'
import App from './pages/_app'
import { BrowserRouter } from 'react-router-dom'
import { SettingsProvider } from './contexts/settings-context'
import './index.css';
 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <StrictMode>
    <SettingsProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </SettingsProvider>
  // </StrictMode>
 
)
import AuthGuard from "../components/authentication/auth-guard.js";
import AdminProtectedRoute from "../components/authentication/admin-protected-route.js";
import { DashboardLayout } from "../components/dashboard/dashboard-layout.js";

import Login from "./login_page/index.js";
import Tenant_page from "./tenant_page/index.js";
import Dashboard from "../pages/dashboard/index.js";
import Customers from "../pages/dashboard/customers/index.js";
import Audits from "../pages/dashboard/audits/index.js";
import Agenda from "../pages/dashboard/agenda/index.js";
import Tcs from "../pages/dashboard/tcs/index.js";
import Errors from "../pages/dashboard/errors.js";
import CreateUser from "../pages/dashboard/create/index.js";
import CustomerDetails from "../pages/dashboard/customers/[customerId].js";
import AuditsGraphicsDetails from "../pages/dashboard/audits/[auditId].js";
import CourseSession from "../pages/dashboard/session/[session].js";
import CreateSession from "../pages/dashboard/session/create_session.js";
import Forum from "../pages/dashboard/forum/index.js";
import SessionHome from "../pages/dashboard/session/home.js";
import Formations from "../pages/dashboard/formations/index.js";
import FormationsDetails from "../pages/dashboard/formations/[formationId].js";
import TcsInformationsById from "../pages/dashboard/tcs/[tcs].js";
import Facturation from "../pages/dashboard/facturation/index.js";
import Activities from "../pages/dashboard/activities/index.js";
import Parameters from "../pages/dashboard/parameters/index.js";
import FeedBacks from "../pages/dashboard/FeedBacks/index.js";
import FeedBackByCustomerId from "../pages/dashboard/FeedBacks/feedBackByCustomerId.js";
import CreateAdmin from "../pages/dashboard/create/index-admin.js";
import PatientsFolder from "../pages/dashboard/patients_folder/index.js";
import PatientsFolderDetails from "../pages/dashboard/patients_folder/[patients_folder].js";
import Satisfaction from "../pages/dashboard/qualite/Seatisfaction.js";
import SurveyDetails from "../pages/dashboard/qualite/[surveyId].js";
import Discussion from "../pages/dashboard/discussion/index.js";
import StatistiquesAudit from "../pages/dashboard/statistiques_audit/index.js";
import NotFound from "../pages/NotFound.js";
import Email_sent from "../pages/email_sent.js";
import ChangePasswordFirstLogin from "../pages/confrm_email.js";
import NotAvailable from "../pages/NotAvailable.js";
import Score from "../components/dashboard/scores/Score.js";
import FormateurHome from "../pages/dashboard/formateurs/index.js";
import FormateurProfile from "../pages/dashboard/formateurs/[formateur].js";
import RoleAgendadRedirect from "./roles_redirection/agenda-role-redirection.js";
import RoleCustomersdRedirect from "./roles_redirection/customers-role-redirection.js";
import RoleCustomerDetailsdRedirect from "./roles_redirection/customer-details-role-redirection.js";
import Compte from "../pages/dashboard/Reglages/compte/compte.js";
import Extention from "../pages/dashboard/Reglages/Extensions/extensions.js";
import Personnalisation from "../pages/dashboard/Reglages/Personnalisation/personnalisation.js";
import CreateTenantPage from "../pages/dashboard/tenants/create_tenant.js";
import DetailedResultsPage from "../components/dashboard/DetailedResultsPage.js";
import PermissionsTree from "../pages/dashboard/Reglages/Permission/PermissionsTree.js";
import RolesTable from "../pages/dashboard/Reglages/Role/Role_table.js";
import PermissionsTre from "../pages/dashboard/Reglages/Permission_Role/PermissionsTree.js";
import PermissionsTreee from "../pages/dashboard/Reglages/Permissions_Edit/PermissionsTreee.js";
import List_admin from "../components/dashboard/create/list-admin.js";
import { AdminUpdateForm } from "../components/dashboard/create/adminUpdateForm.js";

const routes = [
  {
    path:'/:tenant/Email_confirmed/:token',
element:  <ChangePasswordFirstLogin/>
  },{
    path:'/:tenant/change_password/:token',
element:  <ChangePasswordFirstLogin/>
  },
   
  {path:'/:tenant/confirm_email',
    element:  <Email_sent/>  },
  {
path: "/create_tenant",
    element: <CreateTenantPage />,
  },
  {
    path: "/",
    element: <Tenant_page />,
  },
  {
    path: "/:tenant",
    element: <Login />,
  },
  {
    path: "/detailed-results",
    element: <DetailedResultsPage/>,
  },
  {
    path:`/:tenant/dashboard` ,
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "agenda",
        element: <RoleAgendadRedirect />,
      },
      {
        path: "scores",
        element: <AdminProtectedRoute><Score /></AdminProtectedRoute>,
      },
      {
        path: "audit-session/:auditId/:startSession/:endSession",
        element: <StatistiquesAudit />,
      },
      {
        path: "apprenants",
        children: [
          {
            path: "",
            element: <RoleCustomersdRedirect />,
          },
          {
            path: ":customerId",
            element: <RoleCustomerDetailsdRedirect />,
          },
          {
            path: "not-available",
            element: <NotAvailable />,
          },
        ],
      },
      // {
      //   path: "feedbacks",
      //   children: [
      //     {
      //       path: "",
      //       element: <FeedBacks />,
      //     },
      //     {
      //       path: ":customerId",
      //       element: <FeedBackByCustomerId />,
      //     },
      //     {
      //       path: "not-available",
      //       element: <NotAvailable origin="feedbacks" />,
      //     },
      //   ],
      // },
      {
        path: "epp/audits",

        children: [
          {
            path: "",
            element: <Audits />,
          },
          {
            path: ":auditId",
            element: <AuditsGraphicsDetails />,
          },
          {
            path: "not-available",
            element: <NotAvailable origin="audits" />,
          },
        ],
      },
      {
        path: "epp/tcs",
        children: [
          {
            path: "",
            element: <Tcs />,
          },
          {
            path: ":tcsId",
            element: <TcsInformationsById />,
          },
        ],
      },
      {
        path: "formations",
        children: [
          {
            path: "",
            element: (
              <AdminProtectedRoute>
                {" "}
                <Formations />{" "}
              </AdminProtectedRoute>
            ),
          },
          {
            path: ":formationId",
            element: (
              <AdminProtectedRoute>
                <FormationsDetails />{" "}
              </AdminProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "satisfaction",
        children: [
          {
            path: "",
            element: (
              <AdminProtectedRoute>
                {" "}
                <Satisfaction />{" "}
              </AdminProtectedRoute>
            ),
          },
          {
            path: ":surveyId",
            element: (
              <AdminProtectedRoute>
                {" "}
                <SurveyDetails />{" "}
              </AdminProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "session",
        children: [
          {
            path: "",
            element: <SessionHome />,
          },
          {
            path: ":sessionId",
            element: <CourseSession />,
          },
          {
            path: "create",
            element: <CreateSession />,
          },
          {
            path: "not-available",
            element: <NotAvailable origin="sessions" />,
          },
        ],
      },
      {
        path: "experts",
        children: [
          {
            path: "",
            element: (
              <AdminProtectedRoute>
                <FormateurHome />
              </AdminProtectedRoute>
            ),
          },
          {
            path: ":formerId",
            element: (
              <AdminProtectedRoute>
                <FormateurProfile />
              </AdminProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "errors",
        element: (
          <AdminProtectedRoute>
            <Errors />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "create/apprenants",
        element: (
          <AdminProtectedRoute>
            {" "}
            <CreateUser />{" "}
          </AdminProtectedRoute>
        ),
      },
      {
        path: "forum",
        element: <Forum />,
      },
     /* {
        path: "compte",
        element: <Compte />,
   },  */ 
    /*  {
        path: "Extensions",
        element: <Extention />,
      },*/
     /*  {
        path: "Permission",
        element: <PermissionsTree />,
      },*/
      {
        path: "permission_role",
        element: <PermissionsTre />,
      },
      {
        path: "permission_edit",
        element: <PermissionsTreee />,
      },
      {
        path: "roles",
        element: <RolesTable />,
      },
    /*  {
        path: "Personnalisation",
        element: <Personnalisation />,
      },*/
      {
        path: "discussion",
        element: <Discussion />,
      },
      {
        path: "facturation",
        element: (
          <AdminProtectedRoute>
            {" "}
            <Facturation />{" "}
          </AdminProtectedRoute>
        ),
      },
      {
        path: "activities",
        element: (
          <AdminProtectedRoute>
            {" "}
            <Activities />{" "}
          </AdminProtectedRoute>
        ),
      },
      {
        path: "parametres",
        element: (
          <AdminProtectedRoute>
            <Parameters />{" "}
          </AdminProtectedRoute>
        ),
      },
      {
        path: "create/admin",
        element: (
          <AdminProtectedRoute>
            <CreateAdmin />
          </AdminProtectedRoute>
        ),
      },
      {
        path: "admins",
        element: (
         
          <List_admin></List_admin>
        ),
      },
      {
        path: "admins/update",
        element: (
         
          <AdminUpdateForm></AdminUpdateForm>
        ),
      },
      {
        path: "patients_folder",
        children: [
          {
            path: "",
            element: (
              <AdminProtectedRoute>
                <PatientsFolder />
              </AdminProtectedRoute>
            ),
          },
          {
            path: ":patientsFolderId",
            element: (
              <AdminProtectedRoute>
                <PatientsFolderDetails />
              </AdminProtectedRoute>
            ),
          },
        ],
      },
      {
        path: "not-available",
        element: <NotAvailable />,
      },
    ],
  },
  {
 path: "/not-found",
    element: <NotFound />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export default routes;

import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { sessionsApi } from "../../../../api/session-api";
import { useAuth } from "../../../../hooks/use-auth";
import zIndex from "@mui/material/styles/zIndex";

const ListeAmeliorations = ({
  matches,
  auditId,
  selectedTour,
  start_session,
  end_session,
}) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(true);

  let [pointsAmeliorations, setPointsAmeliorations] = useState([]);
  let [currentPAIndex, setCurrentPAIndex] = useState(0);
  let [currentPointAmelioration, setCurrentPointAmelioration] = useState(null);
  let [currentImprovement, setCurrentImprovement] = useState(null);
  let [currentIMPndex, setCurrentIMPndex] = useState(0);
  let [showMenu, setShowMenu] = useState(false);
  let [searchFieldContent, setSearchFieldContent] = useState("");

  let onInputFieldFocus = () => {
    setShowMenu(true);
  };

  let onInputFieldBlur = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 500);
  };

  let onInputFieldChange = (e) => {
    let val = e?.target?.value?.trim();
    if (val != "") {
      setSearchFieldContent(val);
    } else {
      setSearchFieldContent("");
    }
  };

  let changeSelectedApprenant = (id) => {
    let apprenant = pointsAmeliorations.filter(
      (item) => item?.["user_id"] === id
    );
    let apprenantIndex = pointsAmeliorations.findIndex(
      (item) => item?.["user_id"] === id
    );
    // console.log(apprenant);
    // console.log(apprenantIndex);
    if (apprenant.length > 0) {
      setCurrentPAIndex(apprenantIndex);
      setCurrentPointAmelioration(pointsAmeliorations[apprenantIndex]);
      if (pointsAmeliorations[apprenantIndex]?.["improvements"]?.length > 0) {
        setCurrentIMPndex(0);
        setCurrentImprovement(
          pointsAmeliorations[apprenantIndex]?.["improvements"]?.[0]
        );
      } else {
        setCurrentImprovement(null);
        setCurrentIMPndex(0);
      }
    }
    setSearchFieldContent("");
  };
  let getPointsAmeliorationssBySession= async() => {
    let response = await sessionsApi.getPointsAmeliorationssBySession(
      user.admin_id,
      auditId,
      turn,
      start_session,
      end_session
    ).catch(() => {
      setLoading(false);
    });
    if (response?.results?.length > 0) {
      // console.log(response?.results);
      setPointsAmeliorations(response?.results);
      setCurrentPointAmelioration(response?.results?.[0]);
      if (response.results?.[0]?.["improvements"]?.length > 0) {
        setCurrentImprovement(response.results?.[0]?.["improvements"][0]);
      }
    }
  };
  useEffect( () => {
    setPointsAmeliorations([]);
    setCurrentPAIndex(0);
    setCurrentPointAmelioration(null);
    setCurrentImprovement(null);
    setCurrentIMPndex(0);
    setLoading(true);
    let turn = selectedTour === 0 ? 1 : 2;
    // setTimeout(async () => {
      getPointsAmeliorationssBySession();
    setLoading(false);
    // }, 2000);
  }, [selectedTour]);

  const changePointAmeliorationForward = () => {
    setCurrentPAIndex(0);
    setCurrentPointAmelioration(null);
    setCurrentImprovement(null);
    setCurrentIMPndex(0);
    let nextPAIndex = currentPAIndex + 1;
    if (nextPAIndex >= pointsAmeliorations.length) {
      setCurrentPAIndex(0);
      if (pointsAmeliorations?.length > 0) {
        setCurrentPointAmelioration(pointsAmeliorations[0]);
        if (pointsAmeliorations[0]?.["improvements"]?.length > 0) {
          setCurrentIMPndex(0);
          setCurrentImprovement(pointsAmeliorations[0]?.["improvements"]?.[0]);
        }
      }
    } else {
      setCurrentPAIndex(nextPAIndex);
      if (pointsAmeliorations?.length > 0) {
        setCurrentPointAmelioration(pointsAmeliorations[nextPAIndex]);
        if (pointsAmeliorations[nextPAIndex]?.["improvements"]?.length > 0) {
          setCurrentImprovement(nextPAIndex);
          setCurrentImprovement(
            pointsAmeliorations[nextPAIndex]?.["improvements"]?.[0]
          );
        }
      }
    }
  };
  const changePointAmeliorationBackward = () => {
    setCurrentPAIndex(0);
    setCurrentPointAmelioration(null);
    setCurrentImprovement(null);
    setCurrentIMPndex(0);
    let nextPAIndex = currentPAIndex - 1;
    if (nextPAIndex < 0) {
      setCurrentPAIndex(pointsAmeliorations?.length - 1);
      if (pointsAmeliorations?.length > 0) {
        setCurrentPointAmelioration(
          pointsAmeliorations[pointsAmeliorations?.length - 1]
        );
        if (
          pointsAmeliorations[pointsAmeliorations?.length - 1]?.["improvements"]
            ?.length > 0
        ) {
          setCurrentIMPndex(0);
          setCurrentImprovement(
            pointsAmeliorations[pointsAmeliorations?.length - 1]?.[
              "improvements"
            ]?.[0]
          );
        }
      }
    } else {
      setCurrentPAIndex(nextPAIndex);
      if (pointsAmeliorations?.length > 0) {
        setCurrentPointAmelioration(pointsAmeliorations[nextPAIndex]);
        if (pointsAmeliorations[nextPAIndex]?.["improvements"]?.length > 0) {
          setCurrentImprovement(nextPAIndex);
          setCurrentImprovement(
            pointsAmeliorations[nextPAIndex]?.["improvements"]?.[0]
          );
        }
      }
    }
  };

  return (
    <div>
      <h3 style={{ color: "#484848" }}>
        {"Liste des améliorations".toUpperCase()}
      </h3>
      <Paper
        elevation={18}
        style={{ width: "100%", padding: "2rem", borderRadius: "40px" }}
      >
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: matches ? "0" : "3rem",
            }}
          >
            <p
              style={{
                width: "150px",
                height: "63px",
                fontSize: "1.3rem",
                fontWeight: "bold",
                marginRight: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {currentPointAmelioration != null &&
                currentPointAmelioration["user_name"]}
            </p>
            {loading && <CircularProgress sx={{ color: "#9DA4C8" }} />}
            {!loading && (
              <>
                <ChevronLeftIcon
                  fontSize="large"
                  style={{ color: "#9da4c8", cursor: "pointer" }}
                  onClick={changePointAmeliorationBackward}
                />
                <ChevronRightIcon
                  fontSize="large"
                  style={{ color: "#9da4c8", cursor: "pointer" }}
                  onClick={changePointAmeliorationForward}
                />
              </>
            )}
            {!loading &&
              (pointsAmeliorations?.length > 0 ? (
                <p style={{ margin: "0px 0px 0px 0.5rem" }}>
                  {currentPAIndex + 1}/{pointsAmeliorations?.length}
                </p>
              ) : (
                <p style={{ margin: "0px 0px 0px 0.5rem" }}>0/0</p>
              ))}
          </Box>
          <Paper
            style={{
              width: matches ? "25%" : "50%",
              position: "absolute",
              top: matches ? "10px" : "-20px",
              right: matches ? "10px" : "calc(100% - (50% + 25%))",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              borderTopRightRadius: "70px",
              borderBottomRightRadius: "70px",
            }}
            elevation={20}
          >
            <Box
              style={{
                backgroundColor: "#9da4c8",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                position: "absolute",
                left: "-15px",
                zIndex: 2,
              }}
            >
              <SearchOutlinedIcon style={{ color: "#fff" }} />
            </Box>
            <div onBlur={onInputFieldBlur}>
              <input
                placeholder="Rechercher un apprenant..."
                value={searchFieldContent}
                style={{
                  height: "40px",
                  width: "100%",
                  border: "none",
                  outline: "unset",
                  paddingLeft: "3rem",
                  borderTopRightRadius: "70px",
                  borderBottomRightRadius: "70px",
                  fontSize: matches ? "18px" : "14px",
                  position: "relative",
                }}
                onChange={onInputFieldChange}
                onFocus={onInputFieldFocus}
              />
              {showMenu && (
                <div
                  style={{
                    backgroundColor: "white",
                    width: matches ? "370px" : "370px",
                    maxHeight: "200px",
                    overflowY: "auto",
                    position: "absolute",
                    top: "45px",
                    right: "0",
                    boxShadow: "0px 0px 5px #6F78A7",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {pointsAmeliorations?.filter(
                      (item) =>
                        item?.["user_name"]
                          ?.toUpperCase()
                          .includes(searchFieldContent?.toUpperCase()) &&
                        searchFieldContent.trim() != ""
                    ).length === 0 && <p>Aucun apprenant correspondant ...</p>}
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {pointsAmeliorations.length > 0 &&
                      pointsAmeliorations?.filter(
                        (item) =>
                          item?.["user_name"]
                            ?.toUpperCase()
                            .includes(searchFieldContent?.toUpperCase()) &&
                          searchFieldContent.trim() != ""
                      ).length > 0 &&
                      pointsAmeliorations
                        ?.filter(
                          (item) =>
                            item?.["user_name"]
                              ?.toUpperCase()
                              .includes(searchFieldContent?.toUpperCase()) &&
                            searchFieldContent.trim() != ""
                        )
                        .map((app) => {
                          return (
                            <p
                              key={app?.["user_id"]}
                              style={{
                                cursor: "pointer",
                                margin: 0,
                                padding: "0.5rem 0 0.5rem 0.5rem",
                              }}
                              onClick={() =>
                                changeSelectedApprenant(app?.["user_id"])
                              }
                            >
                              {app?.["user_name"]}
                            </p>
                          );
                        })}
                  </div>
                </div>
              )}
            </div>
          </Paper>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "start",
            marginTop: "2rem",
            height: "40px",
          }}
        >
          {currentPointAmelioration?.["improvements"]?.length > 0 &&
            currentPointAmelioration?.["improvements"].map((obj, index) => {
              return (
                <Paper
                  key={obj?.["id"]}
                  style={{
                    margin: "0 8px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "150px",
                    borderRadius: "50px",
                    boxShadow:
                      index == currentIMPndex ? "" : "0px 0px 4px #6F78A7",
                    backgroundColor: index == currentIMPndex ? "#6F78A7" : "",
                    color: index == currentIMPndex ? "#fff" : "",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setCurrentIMPndex(index);
                    setCurrentImprovement(
                      currentPointAmelioration?.["improvements"]?.[index]
                    );
                  }}
                >
                  <p>Objectif {index + 1}</p>
                </Paper>
              );
            })}
          {currentPointAmelioration?.["improvements"]?.length == 0 && (
            <p>Pas d'objectifs ...</p>
          )}
          {loading && <CircularProgress sx={{ color: "#9DA4C8" }} />}
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            marginTop: "2rem",
          }}
        >
          <Box
            style={{
              width: matches ? "22%" : "90%",
              marginBottom: matches ? "0" : "1rem",
              height: "250px",
              boxShadow: "0px 0px 5px #6F78A7",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "35px",
                backgroundColor: "#9da4c8",
                height: "100%",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            ></Box>
            <Box
              style={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
                padding: "0 1rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#9da4c8",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {"Point à améliorer".toUpperCase()}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {currentImprovement != null && currentImprovement["point"]}
              </p>
              {currentImprovement == null && (
                <p>Pas de point d'amélioration ...</p>
              )}
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#9DA4C8" }} />
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              width: matches ? "22%" : "90%",
              marginBottom: matches ? "0" : "1rem",
              height: "250px",
              boxShadow: "0px 0px 5px #6F78A7",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "35px",
                backgroundColor: "#9da4c8",
                height: "100%",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            ></Box>
            <Box
              style={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
                padding: "0 1rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#9da4c8",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {"Objectif".toUpperCase()}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {currentImprovement != null && currentImprovement["objective"]}
              </p>
              {currentImprovement == null && <p>Pas d'objectif ...</p>}
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#9DA4C8" }} />
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              width: matches ? "22%" : "90%",
              marginBottom: matches ? "0" : "1rem",
              height: "250px",
              boxShadow: "0px 0px 5px #6F78A7",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "35px",
                backgroundColor: "#9da4c8",
                height: "100%",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            ></Box>
            <Box
              style={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
                padding: "0 1rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#9da4c8",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {"Stratégie".toUpperCase()}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {currentImprovement != null && currentImprovement["strategy"]}
              </p>
              {currentImprovement == null && <p>Pas de stratégie ...</p>}
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#9DA4C8" }} />
                </div>
              )}
            </Box>
          </Box>
          <Box
            style={{
              width: matches ? "22%" : "90%",
              marginBottom: matches ? "0" : "1rem",
              height: "250px",
              boxShadow: "0px 0px 5px #6F78A7",
              borderRadius: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "35px",
                backgroundColor: "#9da4c8",
                height: "100%",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            ></Box>
            <Box
              style={{
                height: "100%",
                width: "100%",
                overflowY: "auto",
                padding: "0 1rem",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#9da4c8",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                {"Commentaire".toUpperCase()}
              </p>
              <p style={{ fontSize: "14px", fontWeight: "bold" }}>
                {currentImprovement != null && currentImprovement["comment"]}
              </p>
              {currentImprovement == null && <p>Pas de commentaire ...</p>}
              {loading && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress sx={{ color: "#9DA4C8" }} />
                </div>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default ListeAmeliorations;

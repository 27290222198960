import { useCallback, useEffect, useState } from 'react';
import { 
  Box, Button, Card, Container, Grid, IconButton, Table, TableBody, 
  TableCell, TableRow, Typography, Input, Divider, TextField 
} from '@mui/material';
import { Scrollbar } from '../../../components/scrollbar';
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from '../../../hooks/use-auth';
import { useMounted } from '../../../hooks/use-mounted';
import { formationsApi } from '../../../api/formation-api';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { gtm } from '../../../lib/gtm';

const Formations = () => {
  const isMounted = useMounted();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [formations, setFormations] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [createBool, setCreateBool] = useState(false);
  const [createFormationName, setCreateFormationName] = useState("");

  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = { roles_id };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);

  const hasViewPermission = permissions.some(
    (permission) => permission.component === "Formation" && permission.permission === "Voir"
  );

  const hasCreatePermission = permissions.some(
    (permission) => permission.component === "Formation" && permission.permission === "Créer"
  );

  const getFormations = useCallback(async () => {
    if (!hasViewPermission) return;
    try {
      const data = await formationsApi.getAllFormations(user.admin_id);
      if (isMounted()) {
        setFormations(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted, user.admin_id, hasViewPermission]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    getFormations();
  }, [getFormations]);

  const handleCreateFormation = async () => {
    const id = await formationsApi.createFormation(user.admin_id, createFormationName || "Nouvelle formation");
    navigate(`/${localStorage.getItem('tenant')}/dashboard/formations/${id.id}`);
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 11 }}>
    <Container maxWidth="xl">
      <Box sx={{ mb: 5 }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
            Actions
            </Typography>
          </Grid>

          {hasCreatePermission && (
            <Grid item sx={{ mt: 8, mr: 3 }}>
              {!createBool ? (
                <Button
                  onClick={() => setCreateBool(true)}
                  variant="contained"
                >
                  Créer une action
                </Button>
              ) : (
                <Box>
                  <TextField
                    onChange={(event) => setCreateFormationName(event.target.value)}
                    sx={{
                      backgroundColor: 'white',
                      flexGrow: 1,
                      mr: 2,
                    }}
                    label="Entrez le nom de l'action'"
                  />
                  <Button
                    sx={{
                      m: 1,
                    }}
                    variant="contained"
                    onClick={() => setCreateBool(false)}
                  >
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCreateFormation}
                  >
                    Créer
                  </Button>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Grid container spacing={4}>
        <Grid item xs={12}>
  {hasViewPermission ? (
    <Card>
      <Scrollbar>
        <Box sx={{ alignItems: 'center', display: 'flex', p: 2.5 }}>
          <SearchIcon fontSize="small" />
          <Box sx={{ flexGrow: 1, ml: 3 }}>
            <Input
              color="secondary"
              disableUnderline
              fullWidth
              value={searchName}
              onChange={(event) => setSearchName(event.target.value)}
              placeholder="Chercher une formation"
            />
          </Box>
        </Box>
        <Divider />
        <Table sx={{ minWidth: 700 }}>
          <TableBody>
            {formations
              .filter(f => f.name.toLowerCase().includes(searchName.toLowerCase()))
              .map((formation, index) => (
                <TableRow hover key={index}>
                  <TableCell>
                    <Box sx={{ ml: 3 }}>
                      <IconButton
                        component={RouterLink}
                        to={`/${localStorage.getItem('tenant')}/dashboard/formations/${formation.id}`}
                      >
                        <Typography variant="h6" color="primary">
                          {formation.name}
                        </Typography>
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Scrollbar>
    </Card>
  ) : (
    <Box sx={{ textAlign: "center", mt: 6 }}>
      <img
  src="https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
  alt="Accès refusé"
  style={{ maxWidth: "300px", marginBottom: "16px" }}
/>
      <Typography variant="h6" sx={{ color: "error.main" }}>
        Vous n'avez pas la permission de Voir les formations.
      </Typography>
      <Typography variant="body1" sx={{ color: "text.secondary", mt: 1 }}>
        Veuillez contacter un administrateur si vous pensez qu'il s'agit d'une erreur.
      </Typography>
    </Box>
  )}
</Grid>

        </Grid>
      </Box>
    </Container>
  </Box>
);
};

export default Formations;

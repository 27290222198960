import {
  Accordion,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { DossierPatient } from "./dossier-patient";
import { useCallback, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Chart,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
} from "chart.js";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { feedBacksApi } from "../../../api/feedback-api";
import { useMounted } from "../../../hooks/use-mounted";
import { useAuth } from "../../../hooks/use-auth";

Chart.register(
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement
);

export const ResultsFirstTurn = (props) => {
  const {
    turn,
    resultData,
    setDialogOpen,
    dialogOpen,
    auditName,
    dataFirstTurn,
    courseId,
    gapFirstTurn,
    setResultData,
    setGapFirstTurn,
  } = props;
  const [selectedPatient, setSelectedPatient] = useState(0);
  const isMounted = useMounted();
  const { user } = useAuth();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [hoveredPatient, setHoveredPatient] = useState(null); // État pour gérer le survol

  const getCriteriasGaps = useCallback(
    async (patient_id, turn) => {
      try {
        const data = await feedBacksApi.getCriteriasGaps(patient_id, turn);
        if (isMounted()) {
          setGapFirstTurn(data.message);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMounted]
  );

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const deletePatient = async (admin_id, patient_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/course/deletePatient/`;
    const values = {
      "admin_id": admin_id,
      "patient_id": patient_id
    };
    const accessToken = globalThis.localStorage.getItem('accessToken');

    try {
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        }
      });

      const json = await resp.json();
      return json;
    } catch (error) {
      console.error("Erreur lors de la suppression du patient :", error);
    }
  };

  const handleOpenConfirmDialog = (patient_id) => {
    setPatientToDelete(patient_id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setPatientToDelete(null);
  };

  const [key, setKey] = useState(0); // Compteur pour forcer le re-rendu

  const handleConfirmDelete = async () => {
    if (patientToDelete) {
      const response = await deletePatient(user.id, patientToDelete);
      if (response && response.success) {
        // Mettre à jour resultData et gapFirstTurn
        setResultData(prev => prev.filter(patient => patient.patient_id !== patientToDelete));
        setGapFirstTurn(prev => prev.filter(patient => patient.patient_id !== patientToDelete));

        // Force un re-rendu de ResultsFirstTurn
        setKey(prevKey => prevKey + 1);  // Cela va forcer le re-rendu
      }
    }
    handleCloseConfirmDialog();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenCollapse = (index) => {
    setSelectedPatient(selectedPatient === index ? selectedPatient : index);
  };

  const handleMouseEnter = (index) => {
    setHoveredPatient(index);
  };

  const handleMouseLeave = () => {
    setHoveredPatient(null);
  };

  useEffect(() => {
    if (resultData.length > 0) getCriteriasGaps(resultData[selectedPatient].patient_id, 1);
  }, [selectedPatient]);

  useEffect(() => {
    // console.log(gapFirstTurn);
  }, [gapFirstTurn]);

  // console.log(resultData);

  return (
    <>
      <Card sx={{ borderRadius: 1 }}>
        <CardContent>
          <Typography
            variant="h5"
            color={(theme) =>
              theme.palette.mode === "dark"
                ? "white"
                : process.env.REACT_APP_PRIMARY_COLOR
            }
            sx={{ mt: 1, mb: 1, ml: 2 }}
          >
            Analyse des patients
          </Typography>
          <Divider sx={{ my: 2 }} />

          {resultData.length > 0 ? (
            <>
              <Grid
                container
                display="flex"
                spacing={2}
                marginTop={1}
                sx={{ justifyContent: "flex-start" }}
              >
                <Grid
                  item
                  xs={12} md={3} lg={3}
                  sx={{
                    height: "auto",
                  }}
                >
                  <Typography
                    variant="h6"
                    color={(theme) =>
                      theme.palette.mode === "dark"
                        ? "white"
                        : process.env.REACT_APP_PRIMARY_COLOR
                    }
                  >
                    Liste des patients
                  </Typography>
                  <Box sx={{ margin: 1 }}>
                    {turn === 1 &&
                      (resultData.length > 0 ? (
                        <Grid
                          container
                          display="flex"
                          spacing={2}
                          marginTop={2}
                          sx={{ justifyContent: "flex-start" }}
                        >
                          {resultData.map((criteria, index) => (
                            <Grid
                              item
                              xs={6} md={6} lg={6}
                              key={index}
                            >
                              <Box
                                sx={{
                                  backgroundColor:
                                    selectedPatient === index
                                      ? "#f0f0f0"
                                      : "inherit",
                                }}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <Button onClick={() => handleOpenCollapse(index)}>
                                  Patient {index + 1}
                                </Button>
                                {user.access_level === 0 && hoveredPatient === index && (
                                 <IconButton
                                 onClick={() => handleOpenConfirmDialog(criteria.patient_id)}
                                 sx={{ ml: "13px", color: "gray.800" }} // Utilisation de la palette de couleurs Material UI
                               >
                                 <DeleteIcon />
                               </IconButton>
                               
                                )}
                                <Divider />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <Typography>
                          Pas de données pour le premier tour
                        </Typography>
                      ))}
                    <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                      <DialogTitle>Confirmation</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Voulez-vous vraiment supprimer ce patient ? Cette action est irréversible.
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseConfirmDialog} color="primary">
                          Annuler
                        </Button>
                        <Button onClick={handleConfirmDelete} color="error">
                          Supprimer
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                </Grid>

                <Grid item xs={12} md={9} lg={9}>
                  {resultData.map((result, index) => (
                    <Collapse
                      in={selectedPatient === index}
                      timeout="auto"
                      unmountOnExit
                      key={index}
                    >
                      <Grid container>
                        <Grid item xs={4} sx={{ paddingLeft: 4 }}>
                          <Typography variant="h6">
                            Informations du patient :
                          </Typography>
                          <List>
                            <Box>
                              <Typography fontWeight="bold" color={"grey"}>
                                Age :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.age ? result.age : "Non Renseigné"}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                marginTop={2}
                                fontWeight="bold"
                                color={"grey"}
                              >
                                Sexe :
                              </Typography>
                            </Box>
                            <Box>
                              <Typography marginTop={1}>
                                {result.sexe ? result.sexe : "Non Renseigné"}
                              </Typography>
                            </Box>
                            {/* <ListItem display="flex"> */}
                              <Button
                                variant={"outlined"}
                                onClick={handleOpenDialog}
                                sx={{
                                  borderRadius: 999,
                                  marginRight: 1,
                                  marginTop: 2,
                                  textTransform: "none",
                                  width: "80%",
                                  color: "black",
                                  boxShadow: "0 0 0 1px grey",
                                }}
                              >
                                Consulter le dossier patient
                              </Button>
                            {/* </ListItem> */}
                            {dialogOpen && (
                              <DossierPatient
                                open={dialogOpen}
                                onClose={handleCloseDialog}
                                auditName={auditName}
                                number={result.patient_number}
                                patient_id={result.patient_id}
                              />
                            )}
                          </List>
                        </Grid>
                        <Grid xs={8}>
                          <Typography variant="h6">Réponses :</Typography>
                          <Box
                            sx={{
                              maxWidth: "100%",
                              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                              mb: 3,
                              ml: 1,
                              mt: 1,
                              mr: 1,
                              overflowX: "auto",
                              flexGrow: 1,
                              height: 320,
                              scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
                            }}
                          >
                            {result.answer.map((criteria, i) => (
                              <Accordion
                                key={i}
                                sx={{
                                  pointerEvents:
                                    criteria.answer === "Oui" && "none",
                                  opacity: criteria.answer === "Oui" && 1,
                                  backgroundColor: "#F2F1F1",
                                }}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    criteria.answer !== "Oui" && (
                                      <ExpandMoreIcon />
                                    )
                                  }
                                  aria-controls={`panel-${i}-content`}
                                  id={`panel-${i}-header`}
                                >
                                  <Grid container>
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Grid item xs={1}>
                                        <Typography
                                          sx={{
                                            color:
                                              criteria.answer === "Oui"
                                                ? process.env
                                                    .REACT_APP_YES_COLOR
                                                : criteria.answer === "Non"
                                                ? process.env
                                                    .REACT_APP_NO_COLOR
                                                : criteria.answer === "NA"
                                                ? process.env
                                                    .REACT_APP_PNC_COLOR
                                                : "inherit",
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                            marginRight: 2,
                                            flex: "0 0 auto",
                                          }}
                                        >
                                          {criteria.answer.toUpperCase()}
                                        </Typography>
                                      </Grid>
                                      <Divider
                                        orientation="vertical"
                                        flexItem
                                        sx={{
                                          backgroundColor:
                                            criteria.answer === "Oui"
                                              ? process.env
                                                  .REACT_APP_YES_COLOR
                                              : criteria.answer === "Non"
                                              ? process.env
                                                  .REACT_APP_NO_COLOR
                                              : criteria.answer === "NA"
                                              ? process.env
                                                  .REACT_APP_PNC_COLOR
                                              : "inherit",
                                          width: 5,
                                          height: 80,
                                          marginRight: 2,
                                        }}
                                      />
                                      <Grid
                                        item
                                        xs={10}
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          flex: 1,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color:
                                              criteria.answer === "Oui"
                                                ? process.env
                                                    .REACT_APP_YES_COLOR
                                                : criteria.answer === "Non"
                                                ? process.env
                                                    .REACT_APP_NO_COLOR
                                                : criteria.answer === "NA"
                                                ? process.env
                                                    .REACT_APP_PNC_COLOR
                                                : "inherit",
                                            ml: 1,
                                            fontSize: 18,
                                            fontWeight: "bold",
                                            width: "70%",
                                          }}
                                        >
                                          Critère {i + 1} :
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "black",
                                            fontSize: 18,
                                            ml: 1,
                                          }}
                                        >
                                          {dataFirstTurn &&
                                            dataFirstTurn.criterias &&
                                            dataFirstTurn.criterias.length >
                                              0 &&
                                            dataFirstTurn.criterias[i]
                                              .personal.criteria}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>
                                {criteria.answer !== "Oui" &&
                                  gapFirstTurn &&
                                  gapFirstTurn.length > 0 && (
                                    <>
                                      <Typography
                                        sx={{ ml: "12%", mt: "2%" }}
                                        variant="h6"
                                      >
                                        Causes de l'écart pour le critère{" "}
                                        {i + 1} :
                                      </Typography>
                                      <ul>
                                        {gapFirstTurn[i].criteria_gap
                                          .length > 0 ? (
                                          <ul>
                                            {gapFirstTurn[
                                              i
                                            ].criteria_gap.map((gap, c) => (
                                              <li
                                                key={c}
                                                style={{
                                                  marginLeft: "10%",
                                                  marginTop: "2%",
                                                }}
                                              >
                                            { gap.lack_factor!="Autre" ?  `Facteur ${gap.lack_factor} : Manque de ${gap.lack}` : `Facteur ${gap.lack_factor}`}
                                              </li>
                                            ))}
                                          </ul>
                                        ) : (
                                          <Typography
                                            sx={{ ml: "12%", mt: "2%" }}
                                            variant="body1"
                                          >
                                            Aucune donnée disponible
                                          </Typography>
                                        )}
                                      </ul>
                                    </>
                                  )}
                              </Accordion>
                            ))}
                          </Box>
                        </Grid>
                      </Grid>
                    </Collapse>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center", height: "300px" }}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Aucun résultat trouvé
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  Il n'y a pas de données disponibles pour le moment.
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};
import { 
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  useMediaQuery,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom'
import { PropertyList } from '../../property-list';
import { PropertyListItem } from '../../property-list-item';
import ProgressBar from '../session/progress-bar'
import { useEffect, useRef, useState } from 'react';
import { coursesApi } from '../../../api/course-api';
import { useAuth } from '../../../hooks/use-auth';
import { ExportMenu } from '../../export-menu';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CustomerResponseAudit } from './customer-response-audit.js';
import dayjs from 'dayjs';
import Swal from 'sweetalert2'
import { frFR } from '@mui/x-date-pickers';
import { CustomerFeedbackAudit } from '../feedbacks/customer-feedback-audit';

export const CustomerCoursesGrid = (props) => {
  const { customer, setCustomer, index, customer_id,getCustomer } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const [openAuditId, setOpenAuditId] = useState(null);
  const [sessionDate, setSessionDate] = useState({
    "start_session": customer.audits[index].start_session,
    "end_session": customer.audits[index].end_session
  })

  const [modify, setModify] = useState({
    "start_session": false,
    "end_session": false,
    "action_type": false,
    "format": false,
    "program_number": false
  });
  const [tenant, setTenant] = useState(globalThis.localStorage.getItem('tenant'));
  async function handleModify(field, new_value) {
    setModify(modify => ({
      ...modify,
      [field]: false
    }));
    if (field == "start_session" || field == "end_session"){
      let changeDate = {...sessionDate};
      changeDate[field] = new_value;
      setSessionDate(changeDate);
      await coursesApi.changeDate(user.admin_id, customer.audits[index].course_id, changeDate.start_session, changeDate.end_session)
    } else {
      let items = [...customer.audits];
      // console.log(new_value);
      items[index][field] = new_value
      setCustomer({
        ...customer,
        audits: items
      })
      await coursesApi.modifyCourse(user.admin_id, items[index])
    }
  }
  const [permissions, setPermissions] = useState([]); // État pour stocker les permissions
  const align = mdUp ? 'horizontal' : 'vertical';

  async function handleDesactivate() {
    let items = [...customer.audits];
    items[index].activated = false;
    await coursesApi.desactivateCourse(user.admin_id, customer.audits[index].course_id)
    setCustomer({
      ...customer,
      audits: items
    });
  }

  async function handleActivate() {
    let items = [...customer.audits];
    items[index].activated = true;
    await coursesApi.activateCourse(user.admin_id, customer.audits[index].course_id)
    setCustomer({
      ...customer,
      audits: items
    });
  }
  
  async function handleDeleteFacturation() {
    Swal.fire({
      title: 'Voulez-vous vraiment supprimer les données de facturation ?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await coursesApi.deleteFacturation(user.admin_id, customer.audits[index].course_id)
        Swal.fire({
          title: "Facturation supprimée",
          icon: 'success',
        })
      }
    })
  }

  async function handleUnlockSecondTurn() {
    Swal.fire({
      title: 'Voulez-vous vraiment débloquer le second tour ?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let answer = await coursesApi.unlockSecondTurn(user.admin_id, customer.audits[index].course_id)
        if (answer.msg == 'unlocked') {
          Swal.fire({
            title: "Tour débloqué",
            icon: 'success',
          })
        } else if (answer.msg == 'impossible unlock') {
          Swal.fire({
            title: "Impossible de débloquer le tour maintenant",
            icon: 'warning',
          })
        } else if (answer.msg == 'already unlock') {
          Swal.fire({
            title: "Second tour déjà débloqué",
            icon: 'error',
          })
        }
      }
    })
  }

  async function handleAliceInWonderland() {
    Swal.fire({
      title: 'Voulez-vous vraiment activer cette fonctionnalité spéciale ?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await coursesApi.generateLogs(user.admin_id, customer.audits[index].course_id)
        Swal.fire({
          title: "Pays des merveilles activé",
          icon: 'success',
        })
      }
    })
  }

  async function handleReinitializeCourse() {
    Swal.fire({
      title: 'Voulez-vous vraiment réinitialiser le cours ?',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Annuler'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await coursesApi.reinitializeCourse(user.admin_id, customer.audits[index].course_id)
        Swal.fire({
          title: "Cours réinitialisé",
          icon: 'success',
        })
      }
    })
  }
  async function handleDeleteCourse(course_id) {
      Swal.fire({
        title: 'Voulez-vous vraiment Supprimer le cours ?',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await coursesApi.deleteCourse(user.admin_id, course_id)
          getCustomer();
          Swal.fire({
            title: "Cours supprimé",
            icon: 'success',
          })
        }
      })
    }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };const handleCloseAuditId = () => {
    setOpenAuditId(null);
  };
    // Récupérer les permissions lorsque le composant est monté
    useEffect(() => {
      if (user && user.roles_id) {
        getPermissionsByRole(user.roles_id);
      }
    }, [user]);
  // Fonction pour récupérer les permissions
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      roles_id: roles_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Fonction pour vérifier si l'utilisateur a la permission "Voir" pour le composant "Cours"
  const hasViewPermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Cours" && permission.permission === "Voir"
    );
  };

  // Fonction pour vérifier si l'utilisateur a la permission "delete" pour le composant "Cours"
  const hasDeletePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Cours" && permission.permission === "Supprimer"
    );
  };

  // Fonction pour vérifier si l'utilisateur a la permission "update" pour le composant "Cours"
  const hasUpdatePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Cours" && permission.permission === "Mettre à jour"
    );
  };


  return (
    <Card
      sx={{
        backgroundColor:(customer.audits[index].activated ? "#FDFDFD" : "#CBCBCB")
      }}
    >
      <CardHeader
        action={customer.audits[index].sessions_id ? <ExportMenu
          course_id={customer.audits[index].course_id}
        />
        : ""
        }
        title={
          <RouterLink
            to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/session/1`}
            state={{
              data_from: {
                auditId: customer.audits[index].audit_id,
                startSession: sessionDate.start_session,
                endSession: sessionDate.end_session,
                sessions_id: customer.audits[index].sessions_id,
                from: `/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants/` + customer_id
              }
            }}
            style={{ textDecoration: 'none', color:process.env.REACT_APP_PRIMARY_COLOR}}
          >
            {customer.audits[index].audit_name}
            <Typography
              variant="h6"
              color="textSecondary"
            >
              Id: {customer.audits[index].course_id}
            </Typography>
            {customer.audits[index].sessions_id ?
              <Typography
                variant='h6'
              >
                Session n° {customer.audits[index].session_number}
              </Typography>
              :
              ""
            }
            <Typography
              variant="h5"
            >
              {customer.audits[index].activated ? "" : "(Désactivé)"}
            </Typography>
          </RouterLink>
        }
        titleTypographyProps={{variant:'h3' }}
        style={{ textAlign: "center"}}
      />
      <PropertyList>
        <PropertyListItem
          sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Type de formation"
          value={
            modify.action_type?
            <Select
              labelId="select-label"
              id="select"
              value={customer.audits[index].action_type}
              onChange={(event) => handleModify("action_type", event.target.value)}
            >
              <MenuItem value="EPP">EPP</MenuItem>
              <MenuItem value="Programme intégré">Programme intégré</MenuItem>
            </Select>
            : // else
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                ...modify,
                action_type: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
            {/* {sessionDate.start_session} */}
            {customer.audits[index].action_type ? customer.audits[index].action_type.toString() : 'Non renseigné'}
          </Typography>
          }
        />
        <PropertyListItem
          sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Format"
          value={
            modify.format?
            <Select
              labelId="select-format"
              id="select"
              value={customer.audits[index].format}
              onChange={(event) => handleModify("format", event.target.value)}
            >
              <MenuItem value="Mixte">Mixte</MenuItem>
              <MenuItem value="Présentiel">Présentiel</MenuItem>
              <MenuItem value="Non présentiel">Non présentiel</MenuItem>
            </Select>
            : //else
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                  ...modify,
                  format: true
                }))
              }}
              color="textSecondary"
              variant='body2'
            >
              {customer.audits[index].format ? customer.audits[index].format.toString() : 'Non renseigné'}
            </Typography>
          }
          // value={customer.audits[index].format ? customer.audits[index].format.toString() : 'Non renseigné'}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Numéro de programme"
          value={
            modify.program_number?
            <TextField
              size='small'
              focued
              autoFocus
              defaultValue={customer.audits[index].program_number}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleModify("program_number", e.target.value);
                }
              }}
              onBlur={() => {
                handleModify("program_number", e.target.value);
              }}
            />
            : //else
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                  ...modify,
                  program_number: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
              {customer.audits[index].program_number ? customer.audits[index].program_number.toString() : 'Non renseigné'}
            </Typography>
          }
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Nombre de patients tour 1"
          value={customer.audits[index].nb_patients1.toString()}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Nombre de patients tour 2"
          value={customer.audits[index].nb_patients2.toString()}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Début de session"
          value={
            modify.start_session?
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale='frFR'
            >
              <DatePicker
                label="Date de début de session"
                showTodayButton
                focused
                autoFocus
                clearable
                inputFormat='DD/MM/YYYY'
                dateFormat='DD/MM/YYYY'
                InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                onChange={(date) => {
                  if (dayjs(date).isValid()) {
                    const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                    handleModify("start_session", value);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  />
                }
                value={dayjs(sessionDate.start_session, 'DD/MM/YYYY').format('MM/DD/YYYY')}
              />
            </LocalizationProvider>
            : //ELSE
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                ...modify,
                start_session: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
              {sessionDate.start_session}
            </Typography>
          }
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Fin de session"
          value={
            modify.end_session?
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale='frFR'
            >
              <DatePicker
                label="Date de fin de session"
                showTodayButton
                focused
                autoFocus
                clearable
                inputFormat='DD/MM/YYYY'
                dateFormat='DD/MM/YYYY'
                InputAdornmentProps={{ position: 'start', variant: 'standard' }}
                onChange={(date) => {
                  if (dayjs(date).isValid()) {
                    const value = dayjs(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
                    handleModify("end_session", value);
                  }
                }}
                renderInput={(params) =>
                  <TextField
                  {...params}
                  />
                }
                value={dayjs(sessionDate.end_session, 'DD/MM/YYYY').format('MM/DD/YYYY')}
              />
            </LocalizationProvider>
            : //ELSE
            <Typography
              onDoubleClick={() => {
                setModify(modify => ({
                ...modify,
                end_session: true
                }))
              }}
              color="textSecondary"
              variant="body2"
            >
              {sessionDate.end_session}
            </Typography>
          }
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          setMaxWidth={150}
          label="Progression"
          value={<ProgressBar
            value={customer.audits[index].progression}
          />}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Étape"
          value={`${customer.audits[index].step}`}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="État"
          value={`${customer.audits[index].state}`}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Nombre d'heures passées"
          value={customer.audits[index].spent_time}
        />
        <PropertyListItem
        sx={{
          color : "#1E2022" , 
          backgroundColor : (theme) => theme.palette.mode == "dark" ? "#555B61" : "#FFFFFF"}}
          align={align}
          divider
          label="Prédiction fin"
          value={customer.audits[index].prediction}
        />
      </PropertyList>
      <Grid
        display="flex"
        margin={1}
      >
        <Grid
          marginRight={'1%'}
        >
          {hasViewPermission() &&<Button
            variant="contained"
            onClick={handleClickOpen}
            >
            Voir les réponses
          </Button>}
        </Grid>
        <Grid>
          {hasDeletePermission() && 
            (customer.audits[index].activated ?
              <Button
                variant="contained"
                onClick={handleDesactivate}
              >
                Désactiver
              </Button>
              :
              <Button
                variant="contained"
                onClick={handleActivate}
              >
                Réactiver
              </Button>)}
        </Grid>
        <Grid
          marginLeft={'1%'}
        >
          {user.access_level === 0 && user.id_service === 0 ?
          <Button
            variant="contained"
            onClick={handleDeleteFacturation}
            >
            Supprimer la facturation
          </Button>
          : ""
          }
        </Grid>
        <Grid
          marginLeft={'auto'}
        >
         {(
          ['apteed', 'onedys', 'hp','igeya','kalanke'].includes(tenant) && 
  user.access_level === 0 && user.id_service === 0) ?
            <Button
              onClick={handleUnlockSecondTurn}
              variant="contained"
              >
              Débloquer le second tour
            </Button>
            : ""
          }
          
          {(['apteed', 'onedys', 'hp'].includes(tenant) && 
  user.access_level === 0 && user.id_service === 0) ?
            <Button
              onClick={handleAliceInWonderland}
              sx={{ml: 2}}
              variant="contained"
            >
              Pays des merveilles
            </Button>
          : ""
          }
          {hasUpdatePermission() &&
            <Button
              onClick={handleReinitializeCourse}
              sx={{ml: 2}}
              variant="contained"
            >
              Réinitialisation
            </Button>
          }
           {user.access_level === 0 && user.id_service === 0?
            <Button
            onClick={() => handleDeleteCourse(customer.audits[index].course_id)}
              sx={{ml: 2}}
              variant="contained"
            >
              Suppression
            </Button>
          : ""
          }
        </Grid>
        <Box
          margin={1}
        >
        {user.access_level == 0 || user.id_service == 0 ? 
          <CustomerFeedbackAudit 
          open={open}
          handleClose={handleCloseAuditId}
          audit_id={openAuditId}
          customer_id={customer_id}
          start_session={customer.audits[index].start_session}
          end_session={customer.audits[index].end_session}
          /> :
          <CustomerResponseAudit
            open={open}
            handleClose={handleClose}
            audit_id={customer.audits[index].audit_id}
            customer_id={customer_id}
            start_session={customer.audits[index].start_session}
            end_session={customer.audits[index].end_session}
          />
        }
        </Box>
        </Grid>
    </Card>
  );
};

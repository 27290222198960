import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  Popover,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { feedBacksApi } from "../../../api/feedback-api";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import toast from "react-hot-toast";
import { useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import DoneIcon from "@mui/icons-material/Done";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useAuth } from "../../../hooks/use-auth";

export const SendFeedbackSecondTurn = (props) => {
  const {
    feedbackId,
    evaluationFormateur,
    isTurn2Filled,
    isEditingSecondTurn,
    setIsEditingSecondTurn,
    feedbackSecondTurnSent,
    setFeedbackSecondTurnSent,
    setFeedbackTextSecondTurn,
    feedbackTextSecondTurn,
    courseId,
    correctionSecondTurn,
    setUpdateFeedbackState
  } = props;

  const [modifText, setModifText] = useState(false);
  const { user } = useAuth()

  const [score_formateur, setScoreFormateur] = useState(7.5);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async () => {
    try {
      const data = await feedBacksApi.setFeedbackScore(feedbackId, score_formateur, user.admin_id);
      toast.success("Votre évaluation à été pris en compte avec succès ! 🎉");
      setAnchorEl(null);
    } catch (err) {
      toast.error("Une erreur est survenue lors de votre évaluation. Veuillez réessayer ! 🚨");
      console.error(err);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleModify = () => {
    setModifText(!modifText);
  };

  const handleEditSecondTurn = async () => {
    setIsEditingSecondTurn(false);
    try {
      const data = await feedBacksApi.validateFeedback(
        user.admin_id,
        courseId,
        feedbackTextSecondTurn,
        2,
        1
      );
      toast.success("Feedback enregistré avec succés");
      setModifText(false);
      setFeedbackSecondTurnSent(true);
      setUpdateFeedbackState(true)
    } catch (err) {
      toast.error("Erreur lors de l'enregistrement du feedback");
      console.error(err);
    }
  };

  return (
    <>
      <Grid>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 2, mb: 1, ml: 2 }}
        >
          Feedbacks
        </Typography>
      </Grid>
      <Card sx={{ m: 1, borderRadius: 5 }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: isEditingSecondTurn ? "column" : "row",
          }}
        >
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item xs={5}>
              <Typography
                variant="h5"
                color={(theme) =>
                  theme.palette.mode === "dark"
                    ? "white"
                    : process.env.REACT_APP_PRIMARY_COLOR
                }
                sx={{ mt: 2, mb: 1, ml: 2 }}
              >
                Retour prégénéré
              </Typography>
              <TextField
                multiline
                minRows={8}
                maxRows={8}
                placeholder="Votre FeedBack par rapport au tour 2"
                sx={{
                  "& textarea": {
                    color: "black",
                    backgroundColor: feedbackSecondTurnSent || !modifText
                      ? process.env.REACT_APP_NEW_SECONDARY_COLOR
                      : "white",
                    borderColor: "black",
                    p: 2,
                  },
                  marginBottom: 2,
                  overflowY: "auto",
                  resize: "none",
                  pointerEvents: "auto",
                  scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`
                }}
                value={feedbackSecondTurnSent ? correctionSecondTurn : feedbackTextSecondTurn}
                onChange={(e) => setFeedbackTextSecondTurn(e.target.value)}
                fullWidth
                inputProps={{
                  readOnly: feedbackSecondTurnSent || !modifText,
                }}
              />
              {!feedbackSecondTurnSent && (
                <Grid container>
                  <Grid item>
                    <Button
                      variant={"outlined"}
                      sx={{opacity:modifText==true?0:1,
                        borderRadius: 999,
                        marginRight: 1,
                        textTransform: "none",
                        width: "150px",
                        color: "black",
                        boxShadow: "0 0 0 1px grey",
                      }}
                      onClick={handleEditSecondTurn}
                      disabled={!feedbackTextSecondTurn}
                    >
                      Valider
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant={"outlined"}
                      sx={{
                        borderRadius: 999,
                        marginRight: 1,
                        textTransform: "none",
                        width: "150px",
                        color: "black",
                        boxShadow: "0 0 0 1px grey",
                      }}
                      onClick={handleModify}
                    >
                      Modifier
                    </Button>
                  </Grid>
                  
                  {feedbackTextSecondTurn && (
                    <Grid item>
                      <Button
                       variant={"outlined"}
                       sx={{opacity:modifText==true?0:1,
                         borderRadius: 999,
                         marginRight: 1,
                         textTransform: "none",
                         width: "150px",
                         color: "black",
                         boxShadow: "0 0 0 1px grey",
                       }}
                        aria-describedby={id}
                        onClick={handleClick}
                        disabled={evaluationFormateur === 1}
                      >
                        Evaluer
                      </Button>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                color={(theme) =>
                  theme.palette.mode === "dark"
                    ? "white"
                    : process.env.REACT_APP_PRIMARY_COLOR
                }
                sx={{ mt: 2, mb: 1, ml: 2 }}
              >
                Étapes
              </Typography>
              <Grid container direction="column" spacing={2} marginTop={3}>
                <Grid item xs={12}>
                  <Grid sx={{ display: "flex" }}>
                    <Grid item xs={4}>
                      <TimelineItem
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <TimelineContent>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                          >
                            Tour 2
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            effectué
                          </Typography>
                        </TimelineContent>
                        <TimelineDot
                          color={isTurn2Filled ? "primary" : "grey"}
                          variant="outlined"
                          sx={{ ml: 2, width: 50, height: 50 }}
                        >
                          {isTurn2Filled ? (
                            <DoneIcon
                              sx={{
                                color: process.env.REACT_APP_YES_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          ) : (
                            <WatchLaterIcon
                              sx={{
                                color: process.env.REACT_APP_NO_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          )}
                        </TimelineDot>
                      </TimelineItem>
                    </Grid>
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px solid black",
                        width: "90%",
                        marginTop: "12%",
                      }}
                    />
                    <Grid item xs={4}>
                      <TimelineItem
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <TimelineContent>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                          >
                            Feedback
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            prégénéré
                          </Typography>
                        </TimelineContent>
                        <TimelineDot
                          color={
                            feedbackTextSecondTurn.length > 0
                              ? "primary"
                              : "grey"
                          }
                          variant="outlined"
                          sx={{ ml: 2, width: 50, height: 50 }}
                        >
                          {feedbackTextSecondTurn.length > 0 ? (
                            <DoneIcon
                              sx={{
                                color: process.env.REACT_APP_YES_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          ) : (
                            <WatchLaterIcon
                              sx={{
                                color: process.env.REACT_APP_NO_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          )}
                        </TimelineDot>
                      </TimelineItem>
                    </Grid>
                    <hr
                      style={{
                        border: "none",
                        borderTop: "1px solid black",
                        width: "90%",
                        marginTop: "12%",
                      }}
                    />
                    <Grid item xs={4}>
                      <TimelineItem
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <TimelineContent>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                              marginBottom: "5px",
                            }}
                          >
                            Feedback
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "bold",
                              textAlign: "center",
                            }}
                          >
                            envoyé
                          </Typography>
                        </TimelineContent>
                        <TimelineDot
                          color={feedbackSecondTurnSent ? "primary" : "grey"}
                          variant="outlined"
                          sx={{ ml: 2, width: 50, height: 50 }}
                        >
                          {feedbackSecondTurnSent ? (
                            <DoneIcon
                              sx={{
                                color: process.env.REACT_APP_YES_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          ) : (
                            <WatchLaterIcon
                              sx={{
                                color: process.env.REACT_APP_NO_COLOR,
                                width: 40,
                                height: 40,
                              }}
                            />
                          )}
                        </TimelineDot>
                      </TimelineItem>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        sx={{ ml: 2 }}
      >
        <Box
          color="transparent"
          sx={{
            // backgroundColor: "background.default",
            minHeight: "100%",
            p: 3,
          }}
        >
          <Container maxWidth="sm">
            <Box
              sx={{
                p: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography color="textPrimary" variant="h5">
                Votre avis ! 🤔
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Rating
                name="customized-10"
                defaultValue={7.5}
                precision={0.5}
                value={score_formateur}
                max={10}
                onChange={(event, newValue) => {
                  setScoreFormateur(newValue);
                }}
              />
              <Typography
                align="center"
                color="textSecondary"
                sx={{ mt: 1 }}
                variant="body2"
              >
                🎉 Nous espérons que vous êtes satisfait de la précision de ce
                feedback. Votre expérience est importante pour nous et nous
                aimerions connaître votre avis. Pourriez-vous prendre un moment
                pour évaluer la précision de ce feedback sur une échelle de 1 à 10
                ? <br />
                Votre feedback nous aidera à améliorer et à affiner la précision
                de formulation pour mieux répondre aux prochaines évaluations.
                Merci d’avance pour votre temps et votre contribution précieuse.
                👍
              </Typography>
              <Button
                color="primary"
                fullWidth
                size="large"
                sx={{ mt: 4 }}
                variant="contained"
                onClick={handleClose}
              >
                Soumettre
              </Button>
            </Box>
          </Container>
        </Box>
      </Popover>
    </>
    // <>
    //   <Card sx={{ m: 1 }}>
    //     <CardContent sx={{ display: "flex", flexDirection: isEditingSecondTurn ? 'column' : 'row' }}>
    //       <Typography variant="subtitle1" gutterBottom marginLeft={3}>
    //         Veuillez renseigner votre feedback par rapport au tour 2 :
    //       </Typography>
    //       {isTurn2Filled && (
    //         <>
    //           {!isEditingSecondTurn
    //             ? turn === 2 && (
    //                 <Grid marginLeft={3} item xs={4}>
    //                   <Button
    //                     variant="contained"
    //                     sx={{ width: "300px" }}
    //                     onClick={handleSendSecondTurn}
    //                   >
    //                     {" "}
    //                     FeedBack Tour 2{" "}
    //                   </Button>
    //                 </Grid>
    //               )
    //             : turn === 2 && (
    //                 <Grid
    //                   item
    //                   xs={8}
    //                   sx={{
    //                     ml: 2,
    //                   }}
    //                 >
    //                   <TextField
    //                     multiline
    //                     minRows={8}
    //                     maxRows={8}
    //                     placeholder="Votre FeedBack par rapport au tour 2"
    //                     sx={{
    //                       "& textarea": {
    //                         color: "black",
    //                         backgroundColor: feedbackSecondTurnSent
    //                           ? "#f0f0f0"
    //                           : "white",
    //                         borderColor: "black",
    //                       },
    //                       marginBottom: 2,
    //                       overflowY: "auto",
    //                       resize: "none",
    //                       pointerEvents: "auto",
    //                     }}
    //                     value={feedbackTextSecondTurn}
    //                     onChange={(e) =>
    //                       setFeedbackTextSecondTurn(e.target.value)
    //                     }
    //                     fullWidth
    //                     inputProps={{
    //                       readOnly: feedbackSecondTurnSent,
    //                     }}
    //                   />
    //                   <Grid container display="flex">
    //                     <Grid item>
    //                       {!feedbackSecondTurnSent && (
    //                         <Button
    //                           variant="contained"
    //                           onClick={() => setOpenModal(true)}
    //                           startIcon={<SendIcon />}
    //                           disabled={!feedbackTextSecondTurn}
    //                         >
    //                           Envoyer à l'apprenant
    //                         </Button>
    //                       )}
    //                     </Grid>
    //                     <Grid item>
    //                       <Button
    //                         variant="contained"
    //                         onClick={handleSendSecondTurn}
    //                         startIcon={<ClearIcon />}
    //                         sx={{
    //                           marginLeft: feedbackSecondTurnSent
    //                             ? "0px"
    //                             : "20px",
    //                         }}
    //                       >
    //                         Ignorer
    //                       </Button>
    //                     </Grid>
    //                   </Grid>
    //                   <Dialog
    //                     open={openModal}
    //                     onClose={handleCloseModal}
    //                     aria-labelledby="alert-dialog-title"
    //                     aria-describedby="alert-dialog-description"
    //                   >
    //                     <DialogTitle id="alert-dialog-title">{"Voulez-vous vraiment envoyer ce feedback ?"}</DialogTitle>
    //                     <DialogActions>
    //                       <Button onClick={handleCloseModal} color="primary">
    //                         Annuler
    //                       </Button>
    //                       <Button onClick={handleEditSecondTurn} color="primary" autoFocus>
    //                         Confirmer
    //                       </Button>
    //                     </DialogActions>
    //                   </Dialog>
    //                 </Grid>
    //               )}
    //         </>
    //       )}
    //     </CardContent>
    //   </Card>
    // </>
  );
};

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Avatar,
  Divider,
  Breadcrumbs,
  Tooltip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";

const DetailedResultsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { results, searchQuery } = location.state || { results: [], searchQuery: "" };
  const [selectedCategory, setSelectedCategory] = useState("");

  // Fonction pour mettre en surbrillance le mot-clé dans une boîte
  const highlightQuery = (text) => {
    if (!searchQuery || !text) return text;
    const regex = new RegExp(`(${searchQuery})`, "gi"); // Insensible à la casse
    return text.replace(regex, (match) => {
      return `<span style="background-color: #A3A8E6; color: #fff; padding: 1px 3px; border-radius: 3px;">${match}</span>`;
    });

  };

  // console.log(results)

  // Fonction pour filtrer les résultats en fonction de la catégorie sélectionnée
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Filtrer les résultats en fonction de la catégorie sélectionnée
  const filteredResults = selectedCategory
    ? results.filter((result) => result.title === selectedCategory)
    : results;

  return (
    <Box sx={{ padding: "32px", backgroundColor: "#f4f5fb", minHeight: "100vh" }}>
      {/* Barre de navigation */}
      <Box
        sx={{
          padding: "16px 24px",
          backgroundColor: "#6060B8",
          borderRadius: "12px",
          marginBottom: "24px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#fff" }}>
          <Typography
            onClick={() => navigate(`/${localStorage.getItem("tenant")}/dashboard`)}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              color: "#BBDEFB",
              fontWeight: "bold",
              transition: "color 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.color = "#FFF")}
            onMouseLeave={(e) => (e.target.style.color = "#BBDEFB")}
          >
            Accueil
          </Typography>
          <Typography sx={{ color: "#E3F2FD", fontWeight: "bold" }}>Résultat de recherche</Typography>
        </Breadcrumbs>
      </Box>

      {/* Titre de la recherche */}
      <Box
  sx={{
    display: "flex",
    alignItems: "center", // Aligne verticalement au centre
    justifyContent: "space-between", // Sépare le titre et le filtre
    marginBottom: "16px",
  }}
>
  <Typography
    variant="h6"
    sx={{
      fontWeight: "bold",
      color: "#4A4A4A",
    }}
  >
    Résultats pour :{" "}
    <span style={{ fontWeight: "bold", color: "#1A237E" }}>
      {searchQuery || "Non spécifié"}
    </span>
  </Typography>

  {/* Filtre par catégorie */}
  <FormControl sx={{ minWidth: "200px" }}>
    <InputLabel>Filtrer par catégorie</InputLabel>
    <Select
      value={selectedCategory}
      label="Filtrer par catégorie"
      onChange={handleCategoryChange}
    >
      <MenuItem value="">Toutes les catégories</MenuItem>
      <MenuItem value="Administrateurs">Administrateurs</MenuItem>
      <MenuItem value="Utilisateurs">Utilisateurs</MenuItem>
      <MenuItem value="EPP">EPP</MenuItem>
    </Select>
  </FormControl>
</Box>


      {/* Liste des résultats */}
      <Paper
        elevation={4}
        sx={{
          padding: "24px",
          backgroundColor: "#fff",
          borderRadius: "12px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
        }}
      >
        <List>
          {filteredResults.map((result, index) => (
            result.items?.length > 0 && ( // Vérifie si des items existent avant d'afficher le titre
              <Box key={index} sx={{ marginBottom: "32px" }}>
                {/* Titre de la catégorie */}
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: "'Roboto', sans-serif",
                    marginBottom: "16px",
                    color: "#5C6BC0",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: highlightQuery(result.title || "Titre non spécifié"),
                  }}
                />

                {/* Items */}
                {result.items.map((item, i) => (
                  <Box key={i}>
                    <ListItem
                      onClick={() => {
                        if (result.title === "Utilisateurs") {
                          navigate(`/${localStorage.getItem("tenant")}/dashboard/apprenants/${item.Id}`);
                        } else if (item.type === "audit") {
                          navigate(`/${localStorage.getItem("tenant")}/dashboard/audits/${item.id}`);
                        }
                      }}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "12px",
                        backgroundColor: "#FAFAFA",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          transform: "scale(1.02)",
                          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                        },
                        marginBottom: "8px",
                      }}
                    >
                      {result.title === "Administrateurs" && (
                        <>
                          <Avatar
                            src={item.avatar}
                            sx={{
                              width: "56px",
                              height: "56px",
                              marginRight: "16px",
                              fontWeight: "bold",
                            }}
                          />
                          <Box>
                            <Box display="flex" gap={1}>
                              <Tooltip title={item.firstname} arrow>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal", // Suppression du gras
                                    color: "#000",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    maxWidth: "200px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: highlightQuery(item.firstname),
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={item.lastname} arrow>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal", // Suppression du gras
                                    color: "#000",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    maxWidth: "200px",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: highlightQuery(item.lastname),
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Tooltip title={item.email} arrow>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#7F8C8D",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: highlightQuery(item.email),
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </>
                      )}

                      {result.title === "Utilisateurs" && (
                        <>
                          <Avatar
                            src={item.image}
                            sx={{
                              width: "56px",
                              height: "56px",
                              marginRight: "16px",
                            }}
                          />
                          <Box>
                            <Box display="flex" gap={1}>
                              <Tooltip title={item.firstname} arrow>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal",
                                    color: "#000",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: highlightQuery(item.firstname),
                                  }}
                                />
                              </Tooltip>
                              <Tooltip title={item.lastname} arrow>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    fontWeight: "normal",
                                    color: "#000",
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: highlightQuery(item.lastname),
                                  }}
                                />
                              </Tooltip>
                            </Box>
                            <Tooltip title={item.email} arrow>
                              <Typography
                                variant="body2"
                                sx={{
                                  color: "#7F8C8D",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: highlightQuery(item.email),
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </>
                      )}

                      {result.title === "EPP" && (

                         <>
                         <img
                           src={item.image_link}
                           alt="EPP"
                           style={{
                            height: "50px",
                            width: "50px",
                            ml:"30px",
                            marginRight: "10px",
                            objectFit: "cover",
                            borderRadius: "10%",
                          }}
                         />

                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "normal",
                              color: "#000",
                              ml:"18px"
                            }}
                            dangerouslySetInnerHTML={{
                              __html: highlightQuery(item.name),
                            }}
                          />
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#7F8C8D",
                              ml:"18px"
                            }}
                            dangerouslySetInnerHTML={{
                              __html: highlightQuery(item.type),
                            }}
                          />
                        </Box>
                        </>
                      )}
                    </ListItem>
                    <Divider />
                  </Box>
                ))}
              </Box>
            )
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default DetailedResultsPage;

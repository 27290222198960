import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ForumIcon from '@mui/icons-material/Forum';
import {
  Box,
  Divider,
  Drawer,
  Typography,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { useAuth } from "../../hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import ListIcon from "@mui/icons-material/List";
import TroubleshootOutlinedIcon from "@mui/icons-material/TroubleshootOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { usePermissionRedirect } from "./usePermissionRedirect";
import MenuIcon from "@mui/icons-material/Menu";
import usePermissionCheck from "./usePermissionCheck"; // Importer le hook
// Styles des toasts

export const DashboardSidebar = (props) => {
  const { onClose, open, anchorEl, openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"), {
    noSsr: true,
  });
  const [permissions, setPermissions] = useState([]);

  const [sections, setSections] = useState([]);
  const [expandedSections, setExpandedSections] = useState(null);
  const [selectedSubtitles, setSelectedSubtitles] = useState({
    Analyses: "Tableau de bord",
  });

  // Utiliser le hook pour vérifier les permissions
  usePermissionCheck();

  const hasPermission = (component, requiredPermission) => {
    return user.components_and_permissions.some(
      (perm) => perm.component === component && perm.permission === requiredPermission
    );
  };

  const handleOpen = () => setOpenSide(true);
  const handleClose = () => setOpenSide(false);
  const isLargeScreen = useMediaQuery("(min-width:1700px)");

  usePermissionRedirect("Scores");
  usePermissionRedirect("Apprenants");
  usePermissionRedirect("Forum");
  usePermissionRedirect("Formation");
  usePermissionRedirect("EPP");
  usePermissionRedirect("Satisfaction");
  usePermissionRedirect("Liste des sessions");
  usePermissionRedirect("Agenda");
  usePermissionRedirect("Experts");
  usePermissionRedirect("Administrateurs");
  usePermissionRedirect("Activités");
  usePermissionRedirect("Paramètres");
  usePermissionRedirect("Rôles");

  useEffect(() => {   
    if (user?.id_service === 0) {
      const filteredSections = [
        {
          title: null,
          items:  [
                {
                  icon: <HomeIcon fontSize="small" />,
                  title: <span style={{ fontSize: "13px" }}>Tableau de bord</span>,
                  path: `/${globalThis.localStorage.getItem('tenant')}/dashboard`,
                }
              ]
          
        },
        {
          title: "Analyses",
          icon: <TroubleshootOutlinedIcon fontSize="small" />,
          items: [
            hasPermission("Scores", "Voir") && { title: "Scores", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/scores` },
            hasPermission("Performances", "Voir") && { title: "Performances" },
        ].filter(Boolean),
          
        },
        {
          title: "Apprenants",
          icon: <GroupOutlinedIcon fontSize="small" />,
          items: [
            hasPermission ("Apprenants", "Voir") &&  { title: "Liste des apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants` },
            hasPermission("Apprenants", "Créer") && { title: "Créer un apprenant", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/create/apprenants` },
            hasPermission("Forum", "Voir") && { title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` },
          ].filter(Boolean),
        },
        {
          title: "Conception",
          icon: <AssessmentOutlinedIcon fontSize="small" />,
          items: [
            (hasPermission("Formation", "Voir") || hasPermission("Formation", "Créer")  ) && { title: "Formation",  path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/formations`},
            (hasPermission("EPP", "Voir") || hasPermission("EPP", "Créer")) &&   { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/audits` },
            (hasPermission("EPP", "Voir") || hasPermission("EPP", "Créer")) &&   { title: "TCS", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/epp/tcs` },
            hasPermission("Dossier patient", "Voir") && { title: "Dossiers patients", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/patients_folder` },
            hasPermission("Gestion des Risques", "Voir") && { title: "Gestion des Risques" },
          ].filter(Boolean),
        },
        {
          title: "Qualité",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [  hasPermission("Satisfaction", "Voir") && { title: "Satisfaction", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/satisfaction` }].filter(Boolean),
        },
        {
          title: "Sessions",
          icon: <Groups2OutlinedIcon fontSize="small" />,
          items: [
            (hasPermission("Sessions", "Voir") || hasPermission("Sessions", "Créer")) &&    { title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` },
            hasPermission("Agenda", "Voir") && { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
          ].filter(Boolean),
        },
        {
          title: "Experts",
          icon: <WorkOutlineIcon fontSize="small" />,
          items: [
            (hasPermission("Experts", "Voir")) && { title: "Liste des experts", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/experts` },
           /* hasPermission("Comité scientifique", "Voir") &&*/ { title: "Comité scientifique" },
          ].filter(Boolean),
        },
        {
          title: "Administration",
          icon: <AdminPanelSettingsIcon fontSize="small" />,
          items: [
hasPermission("Administrateurs", "Voir") &&  { title: "Liste des administrateurs" , path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/admins`},
            hasPermission("Activités", "Voir") && { title: "Activités", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/activities` },
            hasPermission("Administrateurs", "Créer") && { title: "Créer un admin", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/create/admin` },
          ].filter(Boolean),
        },
        {
          title: "Règlages",
          icon: <SettingsIcon fontSize="small" />,
          items: [  hasPermission("Paramètres", "Voir") && { title: "Paramètres", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/parametres` },
          //  { title: "Permission", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/Permission` },
          hasPermission("Rôles", "Voir") &&  { title: "Rôles", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/roles` }].filter(Boolean),
        },
      ].filter(section => section.items.length > 0); // Filtrer les sections avec au moins un élément
  
      setSections(filteredSections);
    } else if (user?.id_service === 1) {
      const filteredSections = [
        {
          title: "Analyses",
          icon: <HomeIcon fontSize="small" />,
          items: [
            { title: "Tableau de bord", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard` },
            { title: "Agenda", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/agenda` },
          ],
        },
        {
          title: "Évaluations",
          icon: <VerifiedOutlinedIcon fontSize="small" />,
          items: [
            { title: "Audits", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` },
            { title: "TCS", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs` },
            { title: "Apprenants", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants` },
          ],
        },
        {
          title: "Sessions",
          icon: <ListIcon fontSize="small" />,
          items: [{ title: "Liste des sessions", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session` }],
        },
        {
          title: "Autres",
          icon: <ForumIcon fontSize="small" />,
          items: [{ title: "Forum", path: `/${globalThis.localStorage.getItem('tenant')}/dashboard/forum` }],
        },
      ].filter(section => section.items.length > 0); // Filtrer les sections avec au moins un élément
  
      setSections(filteredSections);
    }
  }, [user]);

  const handleToggleSection = (title) => {
    setExpandedSections((prev) => (prev === title ? null : title));
  };

  const handleSelectSubtitle = (sectionTitle, subtitle, path) => {
    if (expandedSections !== sectionTitle) {
      handleToggleSection(sectionTitle);
    }

    setSelectedSubtitles((prev) => ({
      ...prev,
      [sectionTitle]: subtitle,
    }));

    if (path) {
      navigate(path);
    }
  };

  const content = (
    <Box
      {...other}
      anchorEl={anchorEl}
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "1999px",
        backgroundColor: "#8282CA",
        paddingTop: 3,
      }}
    >
      <Box
        component="img"
        sx={{
          mt: 2,
          ml: 4,
          width: "150px",
          height: "65px",
        }}
        alt="Kalanke"
        src={process.env.REACT_APP_LOGO_NAVBAR}
      />
      <Box sx={{ flexGrow: 1, p: 1, pt: 4 }}>
        {sections.map((section) => (
          <Box key={section.title} mt={1} mb={4}>
            <Box
              onClick={(e) => e.stopPropagation()}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "default",
                color: section.title && expandedSections === section.title ? "#7676C5" : "#FFFFFF",
                backgroundColor: section.title && expandedSections === section.title ? "#DDDDF1" : "transparent",
                borderRadius: 1,
                padding: section.title && expandedSections === section.title ? 1 : "0",
              }}
            >
              {section.icon}
              <Typography
                variant="h7"
                sx={{ ml: 1, fontWeight: "bold", fontSize: 14 }}
              >
                {section.title}
              </Typography>
            </Box>
            <Box sx={{ pl: 5 }}>
              {section.items.map((item) => (
                <Typography
                  key={item.title}
                  variant="body1"
                  sx={{
                    color: "#FFFFFF",
                    mt: 2,
                    fontSize: 11,
                    fontWeight: "bold",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    ml: ((expandedSections === section.title && selectedSubtitles[section.title] === item.title) || (!expandedSections && section.title === null)) ? -1 : 1,
                  }}
                  onClick={() => handleSelectSubtitle(section.title, item.title, item.path)}
                >
                  {((expandedSections === section.title && selectedSubtitles[section.title] === item.title) || (!expandedSections && section.title === null)) && (
                    <Box
                      sx={{
                        width: "6px",
                        height: "6px",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "50%",
                        marginRight: 1,
                        fontWeight: "bold",
                      }}
                    />
                  )}
                  {item.title}
                </Typography>
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) => theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 210,
          },
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open sidebar"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={true}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "white",
            borderRightColor: "divider",
            borderRightStyle: "solid",
            borderRightWidth: (theme) => theme.palette.mode === "dark" ? 1 : 0,
            color: process.env.REACT_APP_PRIMARY_COLOR,
            width: 240,
          },
        }}
      >
        {content}
      </Drawer>
      <ToastContainer /> {/* Ajouter ce composant pour afficher les toasts */}
    </div>
  );
};

DashboardSidebar.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.any,
};

export default DashboardSidebar;
import { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { useParams, Link as RouterLink, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from "prop-types";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  TextField,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthGuard from '../../../components/authentication/auth-guard';
import { DashboardLayout } from '../../../components/dashboard/dashboard-layout';
import { CustomerBasicDetails } from '../../../components/dashboard/customer/customer-basic-details';
import { CustomerCourses } from '../../../components/dashboard/customer/customer-courses';
import { CustomerLogs } from '../../../components/dashboard/customer/customer-logs';
import { useMounted } from '../../../hooks/use-mounted';
import * as moment from 'moment';
import { gtm } from '../../../lib/gtm';
import { userApi } from '../../../api/user-api';
import { coursesApi } from '../../../api/course-api';
import { formationsApi } from '../../../api/formation-api';

const tabs = [
  { label: 'Détails', value: 'details' },
  { label: 'Logs', value: 'logs' },
];

const CustomerDetails = (props) => {
  let { customer_id, F_W } = props;
  const { customerId } = useParams();
  const isMounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const [currentTab, setCurrentTab] = useState('details');
  const [open, setOpen] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [formations, setFormations] = useState([]);
  const [blockButton, setBlockButton] = useState(false);
  const [disableChoiceSessions, setDisableChoiceSessions] = useState(true);
  const [choiceSession, setChoiceSession] = useState();
  
  const [updateFeedbackState, setUpdateFeedbackState] = useState(true);
  const [sessionsList, setSessionsList] = useState([]);
  const [permissions, setPermissions] = useState([]); // État pour stocker les permissions

  const location = useLocation();

  if (!location.state) {
    location.state = {
      "from": "apprenants",
      "data_from": []
    }
  }
  const { from, data_from } = location.state;
  const { user } = useAuth();

  // Fonction pour récupérer les permissions
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      roles_id: roles_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Fonction pour vérifier si l'utilisateur a la permission "Voir" pour le composant "Cours"
  const hasViewPermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Cours" && permission.permission === "Voir"
    );
  };

  // Fonction pour vérifier si l'utilisateur a la permission "create" pour le composant "Cours"
  const hasCreatePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Cours" && permission.permission === "Créer"
    );
  };

  // Récupérer les permissions lorsque le composant est monté
  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getCustomer = useCallback(async () => {
    try {
      const data = await userApi.getUsersStats(user.admin_id, customer_id ? customer_id : customerId);
      if (isMounted()) {
        setCustomer(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getFormations = useCallback(async () => {
    try {
      const data = await formationsApi.getAllFormations(user.admin_id)
      if (isMounted()) {
        setFormations(data)
      }
    } catch (err) {
      console.error(err)
    }
  }, [isMounted])

  useEffect(() => {
    getFormations();
    getCustomer();
    setUpdateFeedbackState(false);
  }, []);

  async function handleChangeFormation(formation_id) {
    const sessions_list = await formationsApi.getSessionsByFormationsId(user.admin_id, formation_id)
    setSessionsList(sessions_list.sessions)
  }

  async function sendIds() {
    await userApi.sendIds(user.admin_id, customer.details.id)
    toast.success('Identifiants de connexion envoyés');
    setBlockButton(true)
    setOpenSend(false)
  }

  const handleSendOpen = () => {
    setOpenSend(true)
  }

  const handleSendClose = () => {
    setOpenSend(false)
  }

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleAdd() {
    await coursesApi.createCourse(user.admin_id, choiceSession.id, customer_id ? customer_id : customerId)
    setSessionsList([])
    setChoiceSession()
    handleClose()
    getCustomer()
    toast.success('Apprenant ajouté à une formation');
  }

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          py: 8,
          pt: F_W ? 4 : 8,
          maxWidth: F_W ? "100vw" : "60vw",
        }}
      >
        <Container sx={{
          pt: 8,
          width: "100%",
          minWidth: "100%",
        }}>
          <div>
            <Box sx={{ mb: 4 }}>
              <RouterLink
                to={`/:tenant/dashboard/${from}`}
                state={{
                  data_from: {
                    auditId: data_from.auditId,
                    startSession: data_from.startSession,
                    endSession: data_from.endSession,
                    sessions_id: data_from.sessions_id,
                    from: data_from.from
                  }
                }}
                style={{ textDecoration: 'none' }}
              >
                <Link
                  color="textPrimary"
                  component="a"
                  sx={{
                    alignItems: 'center',
                    display: 'flex'
                  }}
                >
                  <ArrowBackIcon
                    fontSize="small"
                    sx={{ mr: 1 }}
                  />
                  <Typography variant="subtitle2">
                    {from === "apprenants" ? "Liste des apprenants" : from === "" ? "Tableau de bord" : "Retour à la session"}
                  </Typography>
                </Link>
              </RouterLink>
            </Box>
            {customer ?
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid
                    item
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      overflow: 'hidden'
                    }}
                  >
                    <div>
                      <Typography variant="h4">
                        {customer.details.firstname} {customer.details.lastname}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant="subtitle2">
                          Id de l&apos;utilisateur:
                        </Typography>
                        <Chip
                          label={customer.details.id}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Grid
                  marginTop={2}
                  display="flex"
                >
                  <Box>
                    {/* Conditionner l'affichage du bouton en fonction de la permission */}
                    {hasCreatePermission() && (
                      <Button
                        variant="contained"
                        onClick={handleClickOpen}
                      >
                        Ajouter dans une formation
                      </Button>
                    )}
                    <Box
                      justifyContent="flex-end"
                      margin={1}
                    >
                      <Dialog
                        onClose={handleClose}
                        open={open}
                      >
                        <DialogTitle>
                          Ajouter dans une formation
                        </DialogTitle>

                        <DialogContent>
                          <DialogContentText>
                            <Grid
                              container
                              spacing={3}
                              sx={{ mt: 0.5 }}
                            >
                              <Grid
                                item
                                xs
                                width={600}
                              >
                                <Autocomplete
                                  getOptionLabel={(option) => option.name}
                                  options={formations}
                                  onChange={(e, value) => {
                                    if (value !== null) {
                                      setDisableChoiceSessions(false);
                                      handleChangeFormation(value.id)
                                    } else {
                                      setDisableChoiceSessions(true);
                                      setSessionsList([]);
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Choisir une formation"
                                      name="choiseFormation"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid
                                item
                                xs
                                width={600}
                              >
                                <Autocomplete
                                  key={sessionsList}
                                  getOptionLabel={(option) => (`${option.session_number}. Début: ${moment(option.start_date).format('DD/MM/YYYY')}, Fin: ${moment(option.end_date).format('DD/MM/YYYY')}`)}
                                  options={sessionsList}
                                  onChange={(e, value) => {
                                    if (value !== null) {
                                      setChoiceSession(value);
                                    } else {
                                      setChoiceSession()
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      label="Choisir une session"
                                      name="choiceFormations"
                                      variant="outlined"
                                      disabled={disableChoiceSessions}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            variant='contained'
                            onClick={handleClose}
                            color="primary"
                          >
                            Annuler
                          </Button>
                          <Button
                            variant='contained'
                            onClick={handleAdd}
                            color="primary"
                            type="submit"
                            disabled={(choiceSession ? false : true)}
                          >
                            Enregistrer
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Box>
                  </Box>
                  <Box
                    marginLeft={3}
                  >
                    <Button
                      onClick={customer.details.is_mail_sent_credentials === 1 ? handleSendOpen : sendIds}
                      variant='contained'
                      disabled={blockButton}
                    >
                      Envoyer les identifiants de connexion
                    </Button>
                    <Dialog
                      onClose={handleSendClose}
                      open={openSend}
                    >
                      <DialogContent>
                        <DialogContentText>
                          <Typography
                            variant='h5'
                          >
                            Attention, vous allez réinitialiser le mot de passe de l'utilisateur. Êtes-vous sûr?
                          </Typography>
                        </DialogContentText>
                        <Grid
                          marginTop={3}
                        >
                          <Button
                            size='large'
                            variant="contained"
                            sx={{
                              marginLeft: 16
                            }}
                            onClick={handleSendClose}
                          >
                            Annuler
                          </Button>
                          <Button
                            size='large'
                            sx={{
                              marginLeft: 10
                            }}
                            variant="contained"
                            onClick={sendIds}
                          >
                            Confirmer
                          </Button>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                  </Box>
                </Grid>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  sx={{ mt: 3 }}
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
              </>
              : //ELSE
              <Container
                style={{ minWidth: '40vw', justifyContent: 'center', alignItems: 'center', height: "80vh", maxWidth: "40vw" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress
                    color="inherit"
                    size={100}
                  />
                </Box></Container>
            }
          </div>
          {customer ?
            <>
              <Divider />
              <Box sx={{ mt: 3 }}>
                {currentTab === 'details' && (
                  <Grid
                    container
                    spacing={12}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <CustomerBasicDetails
                        customer={customer}
                        setCustomer={setCustomer}
                      />
                    </Grid>
                    {/* Afficher CustomerCourses uniquement si l'utilisateur a la permission "Voir" pour "Cours" */}
                    {hasViewPermission() && (
                      <Grid
                        item
                        xs={12}
                      >
                        <CustomerCourses
                          customer={customer}
                          setCustomer={setCustomer}
                          customerId={customer_id?customer_id:customerId}
                          setUpdateFeedbackState={setUpdateFeedbackState}
                          getCustomer={getCustomer}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                {currentTab === 'logs' && <CustomerLogs
                  admin_id={user.admin_id}
                  customerid={customer_id ? customer_id : customerId} />}
              </Box>
            </>
            : ""
          }
        </Container>
      </Box>
    </>
  );
};

CustomerDetails.getLayout = (page) => (
  <AuthGuard>
    <DashboardLayout>
      {page}
    </DashboardLayout>
  </AuthGuard>
);

export default CustomerDetails;
CustomerDetails.propTypes = {
  customerId: PropTypes.number,
  F_W: PropTypes.bool,
};
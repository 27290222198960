class CriteriasApi {
  async createCriteria(admin_id, criteria_name, criteria_number, audits_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/create/`;
    const values = {
      admin_id: admin_id,
      criteria_name: criteria_name,
      criteria_number: criteria_number,
      audits_id: audits_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async deleteCriteria(admin_id, criteria_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/delete/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyCriteria(admin_id, data) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modify/`;
    const values = {
      admin_id: admin_id,
      data: data,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyNameCriteria(admin_id, criteria_id, criteria_name) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifyName/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      criteria_name: criteria_name,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyPageNumber(admin_id, criteria_id, criteria_page_number) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifyPageNumber/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      criteria_page_number: criteria_page_number,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyInclude(admin_id, criteria_id, include) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifyInclude/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      include: include,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifySkill(admin_id, criteria_id, skill_id) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifySkill/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      skills_id: skill_id,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyGuide(admin_id, criteria_id, guide) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifyGuide/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      guide: guide,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifySource(admin_id, criteria_id, source) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifySource/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      source: source,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }

  async modifyWeight(admin_id, criteria_id, weight) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/modifyWeight/`;
    const values = {
      admin_id: admin_id,
      criteria_id: criteria_id,
      weight: weight,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async removeSkill(admin_id, criteriaId, skillId) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/remove_skill/`;
    const values = {
      admin_id: admin_id,
      criteriaId: criteriaId,
      skillId: skillId,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
  async addSkill(admin_id, criteriaId, skillId) {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/criteria/add_skill/`;
    const values = {
      admin_id: admin_id,
      criteriaId: criteriaId,
      skillId: skillId,
    };
    const accessToken = globalThis.localStorage.getItem("accessToken");
    const resp = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const json = await resp.json();
    return Promise.resolve(json);
  }
}

export const criteriasApi = new CriteriasApi();

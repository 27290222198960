import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Avatar, Box, ButtonBase, Toolbar, Typography, InputBase, Badge, List, ListItem, ListItemText, Paper, Button } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import SchoolIcon from "@mui/icons-material/School";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { AccountPopover } from "./account-popover";
import { NotificationsPopover } from "./notifications-popover";
import ChatIcon from "@mui/icons-material/Chat";
import { useAuth } from "../../hooks/use-auth";

const DashboardNavbarRoot = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "openSide",
})(({ theme, openSide }) => ({
  backgroundColor: "white",
  left: openSide || [theme.breakpoints.up("lg")] ? 210 : 40,
  zIndex: theme.zIndex.drawer + 1,
  ...(theme.palette.mode === "light"
    ? { boxShadow: theme.shadows[3] }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const AccountButton = () => {
  const { user } = useAuth();
  const anchorRef = useRef(null);
  const [openPopover, setOpenPopover] = useState(false);
  const handleOpenPopover = () => setOpenPopover(true);
  const handleClosePopover = () => setOpenPopover(false);

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          ml: 0,
          mr: 2,
        }}
      >
        <Avatar
          sx={{
            height: 30,
            width: 30,
            mx: 0,
          }}
          src={user.avatar}
        />
        <Box sx={{ ml: 3, mr: 4 }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "black",
              ml: 2,
              fontSize: "0.875rem",
            }}
          >
            {user.firstname} {user.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: "black" }}>
            {user.id_service === 0 ? "Administrateur" : "Expert"}
          </Typography>
        </Box>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};

export const DashboardNavbar = (props) => {
  const { onOpenSidebar, open, openSide, setOpenSide, ...other } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { tenant } = useParams();
  const [messagesNotRead, setMessagesNotRead] = useState(0);
  const paperRef = useRef(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1700);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [showAllResults, setShowAllResults] = useState(false);
  const defaultProfileImage = "https://via.placeholder.com/150"; // URL de l'image par défaut

  useEffect(() => {
    const lastSearchQuery = localStorage.getItem('lastSearchQuery');
    if (lastSearchQuery) {
      setSearchQuery(lastSearchQuery);
      handleSearch(lastSearchQuery); // Optionnel : déclencher une recherche automatique
    }
  }, []);

  const handleSearch = async (query) => {
    setSearchQuery(query);
    localStorage.setItem('lastSearchQuery', query); // Stocker la recherche dans localStorage
    if (query.length >= 3) {
      try {
        const values = {
          keyword: query,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/recherche/`;

        const response = await fetch(url, {
          method: 'POST',
          body: JSON.stringify(values),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        });

        const data = await response.json();
        if (response.ok) {
          const results = data.results || {};
          const filteredResults = Object.entries(results).map(([category, items]) => ({
            category,
            items: items.map((item) => {
              const isAuditCategory = category === "EPP";
              const isAdminCategory = category === "Administrateurs";
              const isUsersCategory = category === "Utilisateurs";

              return {
                firstname: isAuditCategory ? undefined : item.firstname || "Prénom inconnu",
                lastname: isAuditCategory ? undefined : item.lastname || "Nom inconnu",
                email: isAuditCategory ? undefined : item.email || "Email non disponible",
                avatar: isAdminCategory ? item.avatar : defaultProfileImage,
                name: isAuditCategory ? item.name : undefined,
                type: isAuditCategory ? (item.type === "tcs" ? "TCS" : item.type === "audit" ? "Audit" : item.type) : undefined,
                image_link: isAuditCategory ? item.image_link : undefined,
                id: isAuditCategory ? item.id : undefined,
                Id: isUsersCategory ? item.id : undefined,
                url: category === "Utilisateurs",
                icon: category === "Admins" ? <AdminPanelSettingsIcon /> : <SchoolIcon />,
              };
            }),
          }));
          setFilteredResults(filteredResults);
        } else {
          console.error("Erreur lors de la recherche:", data.msg);
        }
      } catch (error) {
        console.error("Erreur réseau:", error);
      }
    } else {
      setFilteredResults([]);
    }
  };

  const handleClickOutside = (event) => {
    if (
      paperRef.current &&
      !paperRef.current.contains(event.target) &&
      !event.target.closest(".MuiInputBase-root") // S'assurer que ce n'est pas l'input
    ) {
      setIsSearchFocused(false); // Masquer uniquement si on clique réellement à l'extérieur
    }
  };
  

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getMaxResults = (filteredCategoriesCount) => {
    switch (filteredCategoriesCount) {
      case 1:
        return 7;
      case 2:
        return 4;
      case 3:
        return 2;
      default:
        return 7;
    }
  };

  const filteredResultsWithLimit = filteredResults
    .filter((result) => result.items && result.items.length > 0)
    .map((result) => {
      const filteredCategoriesCount = filteredResults.filter((r) => r.items && r.items.length > 0).length;
      const maxResults = getMaxResults(filteredCategoriesCount);

      return {
        ...result,
        items: result.items.slice(0, maxResults),
      };
    });

  const handleItemClick = (item, result, event) => {
    if (item.type === "audit") {
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits/${item.id}`);
      setSearchQuery("");
      setFilteredResults([]);
    } else if (item.url == 1) {
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants/${item.Id}`);
      window.location.reload(true);
      setSearchQuery("");
      setFilteredResults([]);
    }
    else if (item.type == "tcs") {
      navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/tcs/${item.id}`);
      window.location.reload(true);
      setSearchQuery("");
      setFilteredResults([]);
    } else {
      // console.log(item.url);
      // console.log("Type non pris en charge :", item.type);
    }
  };

  const highlightText = (text, query) => {
    if (typeof text !== 'string' || !query) return text;

    const regex = new RegExp(`(${query})`, "gi");
    const parts = text.split(regex);

    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} style={{ fontWeight: "bold" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <DashboardNavbarRoot openSide={openSide} {...other}>
      <Toolbar
        disableGutters
        sx={{
          backgroundColor: "white",
          maxWidth: openSide || [theme.breakpoints.up("lg")] ? "90%" : "100%",
          height: isLargeScreen ? "120px" : "100px",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 16px",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Box sx={{ display: "flex", alignItems: "center", borderRadius: "8px", border: "1px solid #ccc", ml: "20px", padding: "4px", "&:focus-within": { borderColor: "#6060B8", boxShadow: "0 0 10px rgba(96, 96, 184, 0.5)" } }}>
            <SearchIcon
              sx={{
                color: "gray",
                mr: 1,
                fontSize: isLargeScreen ? "40px" : "24px",
              }}
            />
           <InputBase
  placeholder="Rechercher..."
  value={searchQuery}
  onChange={(e) => handleSearch(e.target.value)}
  onFocus={() => setIsSearchFocused(true)} // Activer l'état de focus
  sx={{
    flex: 4,
    color: "black",
    fontSize: isLargeScreen ? "1.25rem" : "1rem",
    "&:focus": {
      outline: "none",
      borderColor: "#1976d2",
    },
  }}
/>
          </Box>

          {isSearchFocused && searchQuery && filteredResultsWithLimit.length > 0 && (
            <Paper
              ref={paperRef}
              sx={{
                position: "absolute",
                top: isLargeScreen ? "90px" : "50px",
                left: 0,
                width: isLargeScreen ? "450px" : "300px",
                height: filteredResultsWithLimit.length <= 6 ? "auto" : "600px",
                overflowY: filteredResultsWithLimit.length <= 6 ? "visible" : "auto",
                zIndex: 10,
                ml: "15px",
                borderRadius: "16px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "-10px",
                  left: "10%",
                  transform: "translateX(-50%)",
                  width: "0",
                  height: "0",
                  borderLeft: "10px solid transparent",
                  borderRight: "10px solid transparent",
                  borderBottom: "10px solid #E6E6E6",
                },
              }}
              onMouseDown={(e) => e.stopPropagation()} // Éviter la propagation

            >
              <List>
                {filteredResultsWithLimit.map((result, index) =>
                  result.items ? (
                    <Box key={index} sx={{ marginBottom: 1 }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          padding: "8px 12px",
                          backgroundColor: "#E6E6E6",
                          marginBottom: "8px",
                          color: "#808080",
                          fontSize: "13px",
                          fontFamily: "'Roboto', sans-serif",
                        }}
                      >
                        {result.category}
                      </Typography>

                      {(showAllResults ? result.items : result.items.slice(0, 7)).map((item, i) => (
                        <ListItem
                          key={i}
                          button
                          onClick={() => handleItemClick(item)}
                          sx={{ cursor: "pointer", padding: "10px" }}
                        >
                          <ListItemText
                            primary={
                              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, marginY: -1 }}>
                                {item.image_link && (
                                  <img
                                    src={item.image_link}
                                    alt="EPP"
                                    style={{
                                      height: 35,
                                      width: 35,
                                      marginRight: "10px",
                                      objectFit: "cover",
                                      borderRadius: "5%",
                                    }}
                                  />
                                )}
                                {item.avatar && !item.image_link && (
                                  <Avatar
                                    src={item.avatar}
                                    sx={{
                                      height: 35,
                                      width: 35,
                                      marginRight: "12px",
                                    }}
                                  />
                                )}

                                {!item.avatar && !item.image_link && (
                                  <Avatar
                                    src={item.image}
                                    sx={{
                                      height: 35,
                                      width: 35,
                                      marginRight: "12px",
                                    }}
                                  />
                                )}

                                <Box sx={{ display: "flex", flexDirection: "column" }}>
                                  {item.firstname && item.lastname && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 0.8,
                                      }}
                                    >
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontSize: 13,
                                          fontWeight: 500,
                                          color: "#333",
                                        }}
                                      >
                                        {highlightText(item.firstname, searchQuery)}
                                      </Typography>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontSize: 13,
                                          fontWeight: 500,
                                          color: "#333",
                                        }}
                                      >
                                        {highlightText(item.lastname, searchQuery)}
                                      </Typography>
                                    </Box>
                                  )}

                                  {item.email && (
                                    <Typography
                                      variant="body2"
                                      sx={{ fontSize: 11, color: "gray" }}
                                    >
                                      {highlightText(item.email, searchQuery)}
                                    </Typography>
                                  )}

                                  {item.name && (
                                    <>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          fontSize: 13,
                                          fontWeight: 500,
                                          color: "#333",
                                        }}
                                      >
                                        {highlightText(item.name, searchQuery)}
                                      </Typography>

                                      <Typography
                                        variant="body2"
                                        sx={{ fontSize: 11, color: "gray" }}
                                      >
                                        {highlightText(item.type, searchQuery)}
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            }
                          />
                        </ListItem>
                      ))}
                    </Box>
                  ) : null
                )}

                {!showAllResults &&
                  filteredResults.some((result) => result.items?.length > 8) && (
                    <Button
                      fullWidth
                      onClick={() =>
                        navigate("/detailed-results", {
                          state: {
                            searchQuery: searchQuery,
                            results: filteredResults.map((result) => ({
                              id: result.id,
                              title: result.category || "Non spécifié",
                              items: result.items?.map((item) => ({
                                name: item.name,
                                email: item.email,
                                firstname: item.firstname,
                                avatar: item.avatar,
                                type: item.type,
                                image_link: item.image_link,
                                lastname: item.lastname,
                                image: item.image,
                                Id: item.Id,
                                id: item.id
                              })),
                            })),
                          },
                        })
                      }
                      sx={{ textTransform: "none" }}
                      startIcon={<SearchIcon />}
                    >
                      Voir les résultats détaillés
                    </Button>
                  )}
              </List>
            </Paper>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <NotificationsPopover />
          <Box component={ButtonBase} sx={{ mr: 2 }}>
            <Badge
              badgeContent={messagesNotRead}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  right: -3,
                  top: 13,
                  border: "2px solid #fff",
                  padding: "0 4px",
                },
              }}
            >
              <ChatIcon
                sx={{
                  fontSize: isLargeScreen ? "35px" : "30px",
                  color: "#8282CA",
                }}
                onClick={() =>
                  navigate(
                    `/${localStorage.getItem("tenant")}/dashboard/discussion`
                  )
                }
              />
            </Badge>
          </Box>
          <AccountButton />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
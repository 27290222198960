import { useCallback, useState, useEffect } from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { useParams, Link as RouterLink, useLocation, useNavigate} from 'react-router-dom'
import toast from 'react-hot-toast';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  TextField,
  CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AuthGuard from '../../../components/authentication/auth-guard';
import { DashboardLayout } from '../../../components/dashboard/dashboard-layout';
import { CustomerBasicDetails } from '../../../components/dashboard/customer/customer-basic-details';
import { useMounted } from '../../../hooks/use-mounted';
import { gtm } from '../../../lib/gtm';
import { userApi } from '../../../api/user-api';
import { coursesApi } from '../../../api/course-api';
import { formationsApi } from '../../../api/formation-api';
import { CustomerCoursesToFeedBack } from '../../../components/dashboard/feedbacks/customer-courses-to-feedback';

const tabs = [
  { label: 'Détails', value: 'details' },
];

const FeedBackByCustomerId = () => {
  const {customerId} = useParams();
  const isMounted = useMounted();
  const [customer, setCustomer] = useState(null);
  const [currentTab, setCurrentTab] = useState('details');
  const [open, setOpen] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [formations, setFormations] = useState([]);
  const [blockButton, setBlockButton] = useState(false);
  const [disableChoiceSessions, setDisableChoiceSessions] = useState(true);
  const [choiceSession, setChoiceSession] = useState();
  const [sessionsList, setSessionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  const [updateFeedbackState, setUpdateFeedbackState] = useState(true)

  const navigate = useNavigate();

  useEffect(() => {
    if (isNaN(customerId)) {
      navigate('/page-not-found');
    }
  }, [customerId, navigate]);

  const location = useLocation();
  
  if (!location.state) {
    location.state = {
      "from": "apprenants",
      "data_from": [] 
    }
  }
  const { from, data_from } = location.state;
  const { user } = useAuth(); 

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const getCustomer = useCallback(async () => {
    try {
      const data = await userApi.getUsersStatsFormateur(user.admin_id, customerId);
      if (isMounted()) {
        setCustomer(data);
        if (!data.details)  navigate('/dashboard/apprenants/not-available')
        setIsLoading(false)
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getFormations = useCallback(async () => {
    try {
      const data = await formationsApi.getAllFormations(user.admin_id)
      if (isMounted()) {
        setFormations(data)
      }
    } catch (err) {
      console.error(err)
    }
  }, [isMounted])

  useEffect(() => {
    // Solution rapide pour mettre à jour en temps réel le changement de l'icône lorsque le feedback est envoyé. A modifier plus tard.
    if (updateFeedbackState) {
      getFormations();
      getCustomer();
      setUpdateFeedbackState(false);
    }
  }, [updateFeedbackState]);

  async function handleChangeFormation (formation_id) {
    const sessions_list = await formationsApi.getSessionsByFormationsId(user.admin_id, formation_id)
    setSessionsList(sessions_list.sessions)
  }

  async function sendIds() {
    await userApi.sendIds(user.admin_id, customer.details.id)
    toast.success('Identifiants de connexion envoyés');
    setBlockButton(true)
    setOpenSend(false)

  }

  const handleSendOpen = () => {
    setOpenSend(true)
  }

  const handleSendClose = () => {
    setOpenSend(false)
  }

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function handleAdd () {
    await coursesApi.createCourse(user.admin_id, choiceSession.id, customerId)
    setSessionsList([])
    setChoiceSession()
    handleClose()
    getCustomer()
    toast.success('Apprenant ajouté à une formation');
  }

    return (
      <>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 8
          }}
        >
          <Container maxWidth="mx">
            <div>
              <Box sx={{ mb: 4 }}>
                <RouterLink
                  to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/${from}`}
                  state={{
                    data_from: {
                      auditId: data_from.auditId,
                      startSession: data_from.startSession,
                      endSession: data_from.endSession,
                      sessions_id: data_from.sessions_id,
                      from: data_from.from
                    }
                  }}
                  style={{ textDecoration: 'none' }}
                  >
                  <Link
                    color="textPrimary"
                    component="a"
                    sx={{
                      alignItems: 'center',
                      display: 'flex'
                    }}
                    >
                    <ArrowBackIcon
                      fontSize="small"
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="subtitle2">
                      {from === "apprenants" ? "Liste des apprenants": from === "" ? "Tableau de bord" : "Retour à la session"}
                    </Typography>
                  </Link>
                </RouterLink>
              </Box>
              {!isLoading ?
                <>
                <Grid
                container
                  justifyContent="space-between"
                  spacing={3}
                  >
                  <Grid
                    item
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      overflow: 'hidden'
                    }}
                    >
                    <div>
                      <Typography variant="h4">
                        {customer.details.firstname} {customer.details.lastname}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                        >
                        <Typography variant="subtitle2">
                          Id de l&apos;utilisateur:
                        </Typography>
                        <Chip
                          label={customer.details.id}
                          size="small"
                          sx={{ ml: 1 }}
                        />
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Tabs
                  indicatorColor="primary"
                  onChange={handleTabsChange}
                  scrollButtons="auto"
                  sx={{ mt: 3 }}
                  textColor="primary"
                  value={currentTab}
                  variant="scrollable"
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                    />
                  ))}
                </Tabs>
                </>
              : //ELSE
              <Box
                display= "flex"
                justifyContent= "center"
                alignItems= "center"
              >
                <CircularProgress
                  color="inherit"
                  size={150}
                />
              </Box>
              }
            </div>
            { !isLoading ?
              <>
              <Divider />
              <Box sx={{ mt: 3 }}>
                {currentTab === 'details' && (
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={12}
                    >
                      <CustomerBasicDetails
                        customer={customer}
                        setCustomer={setCustomer}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <CustomerCoursesToFeedBack
                        customer={customer}
                        setCustomer={setCustomer}
                        customerId={customerId}
                        setUpdateFeedbackState={setUpdateFeedbackState}
                      />
                    </Grid>
                  </Grid>
                )}
                {/* {currentTab === 'logs' && <CustomerLogs
                admin_id={user.admin_id}
                customerid={customerId} />} */}
              </Box>
              </>
              : ""
              }
          </Container>
        </Box>
      </>
    );
};

FeedBackByCustomerId.getLayout = (page) => (
  <AuthGuard>
    <DashboardLayout>
      {page}
    </DashboardLayout>
  </AuthGuard>
);

export default FeedBackByCustomerId;


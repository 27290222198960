import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RestoreIcon from "@mui/icons-material/Restore";
import { useMounted } from "../../../hooks/use-mounted";
import { topicsApi } from "../../../api/topic-api";
import { useAuth } from "../../../hooks/use-auth";
import { sessionsApi } from "../../../api/session-api";
import { Link as RouterLink } from "react-router-dom";


const ComingDates = () => {
  const isMounted = useMounted();
  const { user } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [comingMeetings, setComingMeetings] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [page, setPage] = useState(0);

  const cardsPerPage = 3;
  const startIndex = page * cardsPerPage;

  const getComingMeetings = useCallback(async () => {
    try {
      const res = await topicsApi.getComingDates(user.admin_id);
        //  console.log("comingr",res)
      if (isMounted()) {
        setComingMeetings(res);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getSessions = useCallback(async () => {
    try {
      const data = await sessionsApi.getAllSessions(user.admin_id);
      if (isMounted()) {
        setSessions(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getComingMeetings();
    getSessions();
    // console.log(sessions, comingMeetings);
  }, [getComingMeetings,getSessions]);

  useEffect(() => {
    if (
      comingMeetings.matching_sessions &&
      comingMeetings.matching_sessions.length > 0
    ) {
      setPageCount(
        Math.ceil(comingMeetings.matching_sessions.length / cardsPerPage)
      );
      if (startIndex < comingMeetings.matching_sessions.length) {
        setEndIndex(
          Math.min(
            startIndex + cardsPerPage,
            comingMeetings.matching_sessions.length
          )
        );
      } else {
        setEndIndex(0);
      }
    }
    // console.log(comingMeetings.matching_sessions, pageCount, endIndex, page);
  }, [comingMeetings, pageCount, endIndex, page]);

  const nextPage = () => {
    setPage((prevPage) => (prevPage + 1) % pageCount);
  };

  // Fonction pour afficher les cartes de la page précédente
  const prevPage = () => {
    setPage((prevPage) => (prevPage === 0 ? pageCount - 1 : prevPage - 1));
  };

  const formatDate = (dateString) => {
    // Diviser la chaîne de date en jour, mois et année
    const [day, month, year] = dateString.split("/");
    // Formater la date au format 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
  };

  const renderButton = (content) => {
    for (let i = 0; i < sessions.length; i++) {
      const item = sessions[i];
      if (
        item.audit_id === content.audit_id &&
        item.audit_name === content.audit_name &&
        formatDate(item.start_session) === content.start_date &&
        formatDate(item.end_session) === content.end_date
      ) {
        {
          return (
            <RouterLink
              to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/session/${i + 1}`}
              state={{
                data_from: {
                  auditId: item.audit_id,
                  startSession: item.start_session,
                  endSession: item.end_session,
                  auditName: item.audit_name,
                  sessions_id: item.sessions_id,
                  sessions_number: item.sessions_number,
                  from: `/${globalThis.localStorage.getItem('tenant')}/dashboard`,
                },
              }}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 999,
                  backgroundColor: (theme) =>
                    theme.palette.mode === "dark"
                      ? "black"
                      : process.env.REACT_APP_PRIMARY_COLOR,
                  color: "white",
                  whiteSpace: "nowrap",
                  fontSize: "12px",
                }}
              >
                Session N° {content.number_session}
              </Button>
            </RouterLink>
          );
        }
      }
    }
    return (
      <Button
        variant="contained"
        sx={{
          borderRadius: 999,
          backgroundColor: (theme) =>
            theme.palette.mode === "dark"
              ? "black"
              : process.env.REACT_APP_PRIMARY_COLOR,
          color: "white",
          whiteSpace: "nowrap",
          fontSize: "12px",
        }}
      >
        Session N° {content.number_session}
      </Button>
    );
  };

  return (
    <Card sx={{ borderRadius: 3 }}>
      <CardHeader
        title={
          <Grid>
            <Typography
              variant="h6"
              sx={{
                color: "black",
              }}
            >
              Présentielles à Venir
            </Typography>
          </Grid>
        }
      />
      <Divider />
      {comingMeetings.matching_sessions &&
      comingMeetings.matching_sessions.length > 0 ? (
        <Box display="flex" alignItems="center">
          {/* sx={{backgroundColor : process.env.REACT_APP_NEW_SECONDARY_COLOR}} Flèche pour revenir en arrière */}
          <IconButton
            onClick={prevPage}
            disabled={page === 0}
            sx={{
              borderRadius: 3,
              ml: 1,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
              backgroundColor: "#FFFFFF",
              color: "black",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          {/* Contenu des cartes défilable */}
          <Box sx={{ overflowX: "auto", flexGrow: 1, ml: 2 }}>
            <Grid
              container
              spacing={2}
              sx={{
                flexWrap: "nowrap",
                mt: 2,
              }}
            >
              {comingMeetings.matching_sessions
                .slice(startIndex, endIndex)
                .map((content, index) => (
                  <Grid key={index} item xs={12} sm={4} sx={{ ml: 3.5 }}>
                    <Card
                      sx={{
                        maxWidth: "450px",
                        height: "95%",
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.5)",
                        mb: 3,
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 3,
                        }}
                      >
                        <Grid item xs={2}>
                          {(() => {
                            const startDate = new Date(
                              content.meeting_start_date
                            );
                            const timeDiff =
                              startDate.getTime() - new Date().getTime();

                            const daysDiff = Math.ceil(
                              timeDiff / (1000 * 3600 * 24)
                            );

                            return (
                              <Button
                                variant="contained"
                                sx={{
                                  borderRadius: 999,
                                  backgroundColor: (theme) =>
                                    theme.palette.mode === "dark"
                                      ? "black"
                                      : process.env.REACT_APP_PRIMARY_COLOR,
                                  color: "white",
                                  whiteSpace: "nowrap",
                                  fontSize: "12px",
                                  "& .MuiSvgIcon-root": {
                                    fontSize: "16px", // Définir la taille de la police de l'icône
                                    marginRight: "4px", // Ajouter un espacement à droite pour l'icône
                                  },
                                  "&:hover": {
                                    backgroundColor: (theme) =>
                                      theme.palette.mode === "dark"
                                        ? "black"
                                        : process.env.REACT_APP_PRIMARY_COLOR,
                                    color: "white",
                                  },
                                }}
                              >
                                <RestoreIcon /> {daysDiff} Jours
                              </Button>
                            );
                          })()}
                        </Grid>
                        <Grid item xs={2} sx={{ mr: 4 }}>
                          {renderButton(content)}
                        </Grid>
                      </Grid>
                      <Grid sx={{ p: 1 }}>
                        <Typography
                          color="black"
                          sx={{ ml: 3, fontSize: 15, fontWeight: "bold" }}
                        >
                          {content.audit_name}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 3,
                            mt: 2,
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "black"
                                : process.env.REACT_APP_PRIMARY_COLOR,
                          }}
                        >
                          Date :
                        </Typography>
                        {(() => {
                          const options = {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          };
                          const startDate = new Date(
                            content.meeting_start_date
                          );
                          const endDate = new Date(content.meeting_end_date);

                          // Formatage de la date de début
                          const startDateString = startDate.toLocaleDateString(
                            "fr-FR",
                            options
                          );

                          // Récupération de l'heure de début
                          const startHour = startDate.toLocaleTimeString(
                            "fr-FR",
                            {
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          );

                          // Récupération de l'heure de fin
                          const endHour = endDate.toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });

                          return (
                            <Typography
                              variant="body1"
                              color="black"
                              sx={{ ml: 3 }}
                            >
                              {startDateString || "Non Renseigné"}
                              <br />
                              {startHour || "Non Renseigné"} -{" "}
                              {endHour || "Non Renseigné"}
                            </Typography>
                          );
                        })()}
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 3,
                            mt: 2,
                            color: "grey",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "black"
                                : process.env.REACT_APP_PRIMARY_COLOR,
                          }}
                        >
                          Durée :
                        </Typography>
                        <Typography
                          variant="body1"
                          color="black"
                          sx={{ ml: 3 }}
                        >
                          {(() => {
                            const startDate = new Date(
                              content.meeting_start_date
                            );
                            const endDate = new Date(content.meeting_end_date);
                            const duration =
                              (endDate - startDate) / (1000 * 60 * 60); // Durée en heures
                            const hours = Math.floor(duration);
                            const minutes = Math.floor((duration - hours) * 60);
                            if (minutes !== 0) {
                              return `${hours}h ${minutes}min`;
                            } else {
                              return `${hours}h`;
                            }
                          })() || "Non Renseigné"}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            ml: 3,
                            mt: 2,
                            color: "grey",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "black"
                                : process.env.REACT_APP_PRIMARY_COLOR,
                          }}
                        >
                          Lieu :
                        </Typography>
                        <Typography
                          variant="body1"
                          color="black"
                          sx={{ ml: 3 }}
                        >
                          {content.meeting_place || "Non Renseigné"}
                        </Typography>
                      </Grid>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Box>
          {/* Flèche pour avancer */}
          <IconButton
            onClick={nextPage}
            disabled={page === pageCount - 1}
            sx={{
              borderRadius: 3,
              mr: 1,
              ml: 2,
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
              backgroundColor: "#FFFFFF",
              color: "black",
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Box>
      ) : (
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              ml: 3,
              p: 2,
              color: "black",
            }}
          >
            Pas de Présentielles à Venir
          </Typography>
        </Box>
      )}
    </Card>
  );
};
export default ComingDates;

import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useMounted } from "../../../hooks/use-mounted";
import { stepApi } from "../../../api/step-api";
import {
  Alert,
  Button,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CardHeader,Switch
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { auditsApi } from "../../../api/audit-api";
const AuditSettings = (props) => {
  const { auditId, adminId, user } = props;
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useMounted();
  const [steps, setSteps] = useState([]);
  const [timeValues, setTimeValues] = useState({});
  const [auditconfig, setAuditconfig] = useState({
    "limit_by_patient":false,
    "auto_validation_feedback":false
  });
  const [units, setUnits] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (event, isExpanded) => {
    setExpanded(isExpanded ? true : false);
  };

  const getSteps = useCallback(async () => {
    try {
      const steps = await stepApi.getAllSteps(adminId);
      if (isMounted()) {
        setSteps(steps);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]); 
   const getConfig = useCallback(async () => {
    try {
      const conf = await auditsApi.getConfig(adminId,auditId);
      if (isMounted()) {
        setAuditconfig(conf);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);
  const SetConfigApi = useCallback(async (field) => {
    try {
      const conf = await auditsApi.setConfig(adminId,auditId,field);
      if (isMounted()&& conf.msg=='ok') {
        setAuditconfig(conf);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const onSubmit = async () => {
    try {
      for (const stepId in timeValues) {
        const min_time = timeValues[stepId];
        const unit_time = units[stepId];

        let min_time_in_seconds;
        if (unit_time === "minutes") {
          min_time_in_seconds = min_time * 60; // Convertir les minutes en secondes
        } else if (unit_time === "heures") {
          min_time_in_seconds = min_time * 3600; // Convertir les heures en secondes
        }

        await stepApi.BlockStepWithMinTime(
          adminId,
          auditId,
          stepId,
          min_time_in_seconds
        );
      }

      setSuccessMessage("Toutes les étapes ont été bloquées avec succès.");
      setShowSuccess(true);
    } catch (err) {
      console.error(err);
    }
  };

  const getMinTimes = useCallback(async () => {
    try {
      const { min_times } = await stepApi.getMinimumTimeByAudit(
        adminId,
        auditId
      );
      const newTimeValues = {};
      const newUnits = {};

      min_times &&
        min_times.length > 0 &&
        min_times.forEach((item) => {
          const min_time = parseInt(item.minimum_time, 10);
          if (min_time % 3600 === 0) {
            newTimeValues[item.step_id] = min_time / 3600;
            newUnits[item.step_id] = "heures";
          } else {
            newTimeValues[item.step_id] = min_time / 60;
            newUnits[item.step_id] = "minutes";
          }
        });

      if (isMounted()) {
        setTimeValues(newTimeValues);
        setUnits(newUnits);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted, auditId]);

  useEffect(() => {
    getSteps();
    getMinTimes();
    getConfig();
  }, [getSteps, getMinTimes,getConfig]);

  useEffect(() => {
    // console.log("timeValues :", timeValues);
    // console.log("units :", units);
  }, [timeValues, units]);

  const handleTimeChange = (stepId, value) => {
    setTimeValues({
      ...timeValues,
      [stepId]: value,
    });
  };

  const handleUnitChange = (stepId, value) => {
    setUnits({
      ...units,
      [stepId]: value,
    });
  };

  const handleCloseSuccess = () => setShowSuccess(false);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Box sx={{ mt: 3 }}>
            <Accordion expanded={expanded} onChange={handleAccordionChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-content"
                id="panel-header"
              >
                <Typography variant="h6">Blocage des étapes avec un temps minimum</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ p: 3, width: '100%' }}>
                  {steps &&
                    steps.length > 0 &&
                    steps.map((step) => (
                      <Grid
                        key={step.id}
                        container
                        spacing={3}
                        alignItems="center"
                        sx={{ mb: 2 }}
                      >
                        <Grid item xs={3}>
                          <strong>{step.step_name}</strong>
                        </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Temps minimum"
                            type="number"
                            value={timeValues[step.id] || ""}
                            onChange={(e) =>
                              handleTimeChange(step.id, e.target.value)
                            }
                            InputProps={{
                              inputProps: { min: 0 },
                            }}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <Select
                              labelId={`unit-select-label-${step.id}`}
                              id={`unit-select-${step.id}`}
                              value={units[step.id] || "minutes"}
                              onChange={(e) =>
                                handleUnitChange(step.id, e.target.value)
                              }
                            >
                              <MenuItem value="minutes">Minutes</MenuItem>
                              <MenuItem value="heures">Heures</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                       {step.step_name=="Parcours Patients" && <Grid item xs={4}>
                          <FormControl fullWidth>
                          <Box sx={{ display: "flex", }}>
     
     <Typography variant="h6">Divisé sur les patients</Typography>
                  <Switch
                   inputProps={{ 'aria-label': 'controlled'}}
                   checked={auditconfig!== null&&auditconfig.limit_by_patient}
                   onChange={() => {SetConfigApi("division") }}
                 /></Box>
                          </FormControl>
                        </Grid>}
                      </Grid>
                    ))}
                  <Grid container justifyContent="flex-end">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => onSubmit()}
                    >
                      Valider
                    </Button>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Snackbar
              open={showSuccess}
              autoHideDuration={6000}
              onClose={handleCloseSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSuccess}
                severity="success"
                sx={{ width: "100%" }}
              >
                {successMessage}
              </Alert>
            </Snackbar>
          </Box>  <Box sx={{ mt: 3 }}>
         { user.id_service ==0 && (user.access_level ==0
        //  ||user.access_level ==1
        )  && 
          <Accordion expanded={false}>
  <AccordionSummary
    aria-controls="panel-content"
    id="panel-header"
    sx={{  width: '100%' }}
  > <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
     
    <Typography variant="h6">Validation automatique des retours experts générés</Typography>
                 <Switch
                  inputProps={{ 'aria-label': 'controlled'}}
                  checked={auditconfig!== null&& auditconfig.auto_validation_feedback }
                  onChange={() => {SetConfigApi("validation") }}
                /></Box>
                </AccordionSummary>
            </Accordion>
            } 
            
            <Snackbar
              open={showSuccess}
              autoHideDuration={6000}
              onClose={handleCloseSuccess}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSuccess}
                severity="success"
                sx={{ width: "100%" }}
              >
                {successMessage}
              </Alert>
            </Snackbar>
          </Box>
        </>
      )}
    </>
  );
};

export default AuditSettings;

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback, useEffect } from "react";
import "./customCalendarStyles.css";
import React, { useState } from "react";
import "moment/locale/fr";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAuth } from "../../../hooks/use-auth.js";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ButtonGroup,
} from "@mui/material";
import { useMounted } from "../../../hooks/use-mounted.js";
import { sessionsApi } from "../../../api/session-api.js";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import frLocale from "@fullcalendar/core/locales/fr";
import { alpha, styled } from "@mui/material/styles";
import { FormersApi } from "../../../api/formateur-api.js";
import EventIcon from '@mui/icons-material/Event';

moment.locale("fr");
const localizer = momentLocalizer(moment);

const FullCalendarWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  //   "& .fc-more-popover" : {
  //     "& .fc-popover-body" : {
  //       padding: 0;
  //     },
  //     "& .fc-more" : {
  //       border-radius: 50%;
  //       width: 30px;
  //       height: 30px;
  //       background-color: rgb(255, 255, 255);
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //       cursor: pointer;
  //     }
  //   },
  "& .fc-license-message": {
    display: "none",
  },
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-daygrid-event-dot-width": "10px",
    "--fc-event-text-color": theme.palette.primary.contrastText,
    "--fc-list-event-hover-bg-color": theme.palette.background.default,
    "--fc-neutral-bg-color": theme.palette.background.default,
    "--fc-page-bg-color": theme.palette.background.default,
    "--fc-today-bg-color": alpha(theme.palette.primary.main, 0.25),
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  "& .fc .fc-col-header-cell-cushion": {
    paddingBottom: "10px",
    paddingTop: "10px",
    fontSize: theme.typography.overline.fontSize,
    fontWeight: theme.typography.overline.fontWeight,
    letterSpacing: theme.typography.overline.letterSpacing,
    lineHeight: theme.typography.overline.lineHeight,
    textTransform: theme.typography.overline.textTransform,
  },
  "& .fc .fc-day-other .fc-daygrid-day-top": {
    color: theme.palette.text.secondary,
  },
  //   "& .fc-daygrid-event": {
  //     borderRadius: theme.shape.borderRadius,
  //     padding: "0px 4px",
  //     fontSize: theme.typography.subtitle2.fontSize,
  //     fontWeight: theme.typography.subtitle2.fontWeight,
  //     lineHeight: theme.typography.subtitle2.lineHeight,
  //   },
  "& .fc-daygrid-block-event .fc-event-time": {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    lineHeight: theme.typography.body2.lineHeight,
  },
  "& .fc-daygrid-day-frame": {
    padding: "12px",
  },
}));

const EventModal = ({
  open,
  onClose,
  event,
  formers,
  selectedFormer,
  setSelectedFormer,
  admin_id,
  trainer,
  onSubmit,
  setSessions,
  setEvents
}) => {
  if (!event || !formers) return null;

  const handleChange = (event) => {
    const selectedId = event.target.value;
    const selectedFormer = formers.find((former) => former.id === selectedId);
    setSelectedFormer(selectedFormer);
  };

  const handleSubmit = async () => {
    if (selectedFormer) {
      // console.log(event);
      await FormersApi.setTrainerSessions(
        admin_id,
        event.audit_id,
        selectedFormer.id,
        event.id,
        event.formations_id,
        event.session_number
      );
      setSelectedFormer(null);
      onSubmit(selectedFormer);
      const data = await sessionsApi.getAllAvailableSessions(admin_id);
      setSessions(data.sessions);
      setEvents(data.events);
      setIsEditable(true);
      onClose();
    }
  };

  const handleEdit = () => {
    setIsEditable(false);
  }

  const formatDate = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(dateString).toLocaleString('fr-FR', options);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: event.type == 'Event' ? 600 : 400,
          bgcolor: "background.paper",
          outline: "unset",
          border: "0",
          borderRadius: "13%",
          p: 4,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{
              margin: 0,
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "15px",
              fontWeight: "bold",
              color: "#9DA4C8",
              textAlign: "justify",
              textAlignLast: "center",
              ml: 1,
              mr: 1,
            }}
            variant="h6"
          >
            {event.audit_name}
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            height: "20px",
            width: "30%",
            backgroundColor: "#9DA4C8",
            borderRadius: "20px",
            border: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              margin: 0,
              fontFamily: "'Montserrat', sans-serif",
              fontSize: "13px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            Session N°{event.session_number}
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "grey",
            borderRadius: "10px",
            border: "none",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 2,
          }}
        >
          {(() => {
            const options = {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            };

            // Formatage de la date de début
            const startDate = new Date(event.start);
            const endDate = new Date(event.end);
            const startDateString = startDate.toLocaleDateString(
              "fr-FR",
              options
            );
            const endDateString = endDate.toLocaleDateString("fr-FR", options);

            return (
              <Typography
                variant="body1"
                color="black"
                sx={{
                  margin: 0,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: "bold",
                  color: "white",
                  fontSize: "13px",
                }}
              >
                Date de début de session : {startDateString || "Non Renseigné"}
                <br />
                Date de Fin de session : {endDateString || "Non Renseigné"}
              </Typography>
            );
          })()}
        </Box>
        {event.type == "Event" && (
          <Box>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#A9A9A9",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "15px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Date de début de l'évènement :
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "15px",
                fontWeight: "bold",
                margin: 0,
              }}
            >
               {formatDate(event.meeting_start_date)}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#A9A9A9",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "15px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Date de fin de l'évènement :
            </Typography>
            <Typography
              sx={{
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "15px",
                fontWeight: "bold",
                margin: 0,
              }}
            >
              {formatDate(event.meeting_end_date)}
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
              style={{
                color: "#A9A9A9",
                fontFamily: "'Montserrat', sans-serif",
                fontSize: "15px",
                fontWeight: "bold",
                marginTop: "25px",
              }}
            >
              Lieu de l'évènement :
            </Typography>
            {event.event_type === "presentielle" ? (
              <Typography
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                {event.meeting_place}
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontSize: "15px",
                  fontWeight: "bold",
                  margin: 0,
                }}
              >
                Réunion Zoom
              </Typography>
            )}
          </Box>
        )}
        <Box sx={{ mt: 2 }}>
          <FormControl fullWidth>
            {event.assigned ? (
              <>
                {/* <InputLabel id="trainer-label">Formateur</InputLabel> */}
                <Select
                  labelId="trainer-label"
                  id="trainer-select"
                  value={trainer ? trainer.id : ""}
                  disabled={true}
                >
                  <MenuItem key={trainer.id} value={trainer.id}>
                    {trainer.firstname} {trainer.lastname}
                  </MenuItem>
                </Select>
              </>
            ) : (
              <>
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Sélectionner un formateur à associer :
                </Typography>
                <Select
                  labelId="trainer-label"
                  id="trainer-select"
                  value={selectedFormer ? selectedFormer.id : ""}
                  onChange={handleChange}
                >
                  {formers && formers.length > 0 ? (
                    formers.map((former) => (
                      <MenuItem key={former.id} value={former.id}>
                        {former.firstname} {former.lastname}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>Aucun formateur disponible</MenuItem>
                  )}
                </Select>
              </>
            )}
          </FormControl>
        </Box>
        {event.assigned === false ? (
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}
          >
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Confirmer
            </Button>
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 3 }}
          >
            <Button variant="contained" color="primary" onClick={onClose}>
              Modifier
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

const AdminAgenda = () => {
  const { user } = useAuth();
  const isMounted = useMounted();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [sessions, setSessions] = useState({});
  const [events, setEvents] = useState({});
  const [startDate, setStartDate] = useState("");
  const [formers, setFormers] = useState([]);
  const [selectedFormer, setSelectedFormer] = useState(null);
  const [trainer, setTrainer] = useState({});
  const [viewMode, setViewMode] = useState("all");

  const getAllAvailableSessions = useCallback(async () => {
    try {
      const data = await sessionsApi.getAllAvailableSessions(user.admin_id);
      if (isMounted()) {
        setSessions(data.sessions);
        setEvents(data.events);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const getFormers = useCallback(async () => {
    try {
      const data = await FormersApi.getAllFormers(user.admin_id);
      if (isMounted()) {
        setFormers(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getAllAvailableSessions();
    getFormers();
  }, [getAllAvailableSessions, getFormers]);

  const filteredSessions =
    startDate && sessions && sessions.length > 0
      ? sessions.filter((session) => session.start_date === startDate)
      : sessions;

  const filteredEvents =
    startDate && events && events.length > 0
      ? events.filter((event) => event.meeting_start_date === startDate)
      : events;

  const handleDateClick = (info) => {
    setStartDate(info.dateStr);
  };

  const handleEventClick = async (info) => {
    setSelectedEvent(info.event.extendedProps);
    if (info.event.extendedProps.isRedEvent) {
      return;
    }
    const response = await sessionsApi.getTrainerBySession(
      user.admin_id,
      info.event.extendedProps.id
    );
    setTrainer(response);
    setModalOpen(true);
  };

  const getFilteredEvents = () => {
    if (viewMode === "events" && filteredEvents && filteredEvents.length > 0) {
      return filteredEvents.map((event) => ({
        start: new Date(event.meeting_start_date),
        end: new Date(event.meeting_start_date),
        extendedProps: {
          assigned: event.assigned,
          session_number: event.number_session,
          formations_id: event.formations_id,
          start: event.start_date,
          end: event.end_date,
          audit_id: event.audit_id,
          audit_name: event.audit_name,
          id: event.id,
          meeting_start_date: event.meeting_start_date,
          meeting_end_date: event.meeting_end_date,
          meeting_place: event.meeting_place,
          event_type: event.event_type,
          type: "Event",
        },
      }));
    } else if (filteredSessions && filteredSessions.length > 0) {
      return filteredSessions.map((session) => ({
        start: session.start_date,
        end: session.start_date,
        extendedProps: {
          assigned: session.assigned,
          session_number: session.number_session,
          formations_id: session.formations_id,
          start: session.start_date,
          end: session.end_date,
          audit_id: session.audit_id,
          audit_name: session.audit_name,
          id: session.id,
          isRedEvent: session.audit_id === null,
          type: "Session",
        },
      }));
    }
    return [];
  };

  // Function to render custom event content
  const renderEventContent = (eventInfo) => {
    const backgroundColor = eventInfo.event.extendedProps.assigned
      ? "#9DA4C8"
      : "#7EB627";

    return (
      <Box
        sx={{
          backgroundColor,
          textAlign: "center",
          color: "white",
        }}
      >
        <Typography variant="body2">
          Session N° {eventInfo.event.extendedProps.session_number}
        </Typography>
      </Box>
    );
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleFormSubmit = (selectedFormerData) => {
    // console.log("Données sélectionnées :", selectedFormerData);
  };

  const handleViewModeChange = () => {
    setViewMode(viewMode === "all" ? "events" : "all");
  };

  return (
    <Box sx={{ height: "100%", padding: 4, background: "#A2A0C14A" }}>
      {/* <Box> */}
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Montserrat",
          padding: "40px 0 0 20px",
          color: (theme) => (theme.palette.mode === "dark" ? "white" : "black"),
          mt: 2,
        }}
      >
        Votre Agenda
      </Typography>
      {/* </Box> */}
      <Box
        sx={{
          fontFamily: "Montserrat",
          padding: 2,
          background: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.3)",
          mt: 2,
        }}
      >
        <center>
          <Box
            component="main"
            sx={{
              backgroundColor: "background.paper",
              flexGrow: 1,
            }}
          >
            <FullCalendarWrapper>
              <FullCalendar
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  timeGridPlugin,
                  timelinePlugin,
                ]}
                initialView="dayGridMonth"
                allDayMaintainDuration
                droppable
                eventDisplay="block"
                height={800}
                selectable
                eventResizableFromStart
                events={getFilteredEvents()}
                // dateClick={handleDateClick}
                eventClick={handleEventClick}
                eventContent={renderEventContent}
                dayMaxEvents={2}
                rerenderDelay={10}
                moreLinkClick="popover"
                locale={frLocale}
                weekends={true}
                dayHeaderContent={(args) => {
                  return args.date.toLocaleDateString("fr-FR", {
                    weekday: "long",
                  });
                }}
                headerToolbar={{
                  start: "custom1",
                  center: "title",
                  end: "prev,next today",
                }}
                customButtons={{
                  custom1: {
                    text: viewMode === "all" ? "Vue Evènements" : "Vue Sessions",
                    click: handleViewModeChange,
                  },
                }}
                titleFormat={(date) => {
                  const options = { month: "long", year: "numeric" };
                  const dateString = date.date.marker.toLocaleDateString(
                    "fr-FR",
                    options
                  );
                  return dateString.toUpperCase();
                }}
              />
              <EventModal
                open={modalOpen}
                onClose={handleCloseModal}
                event={selectedEvent}
                formers={formers}
                selectedFormer={selectedFormer}
                setSelectedFormer={setSelectedFormer}
                admin_id={user.admin_id}
                trainer={trainer}
                onSubmit={handleFormSubmit}
                setSessions={setSessions}
                setEvents={setEvents}
              />
            </FullCalendarWrapper>
          </Box>
        </center>
      </Box>
    </Box>
  );
};

export default AdminAgenda;

// import { decode } from '../utils/jwt';
import jwt_decode from 'jwt-decode';

class AuthApi {
  async getOrgName(tenant) {
    const url = `${process.env.REACT_APP_BACK}/api/get_org_domain/`
      const values = {
        "tenant_name": tenant.toLowerCase(),
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
        }
      });
      const json = await resp.json();
      // console.log(json);
      
      return Promise.resolve(json)

  }

    async login(email, password) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/login_back_office/`
      const values = {
        "email": email,
        "password": password
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }
    
    async getPermissionsByRole(roles_id) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/permissions/getByRole/`;
      const values = {
          "roles_id": roles_id
      };
      const accessToken = globalThis.localStorage.getItem('accessToken');
  
      try {
          const resp = await fetch(url, {
              method: 'POST',
              body: JSON.stringify(values),
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`
              }
          });
  
          if (!resp.ok) {
              throw new Error(`HTTP error! Status: ${resp.status}`);
          }
  
          const json = await resp.json();
          return Promise.resolve(json);
      } catch (error) {
          console.error("Error fetching permissions:", error);
          return Promise.reject(error);
      }
  }
  
    async resetAdminPassword(email) {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/admin_password_reset/`
      const values = {
        "email": email,
        
      };
      const resp = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
            'Content-Type': 'application/json',
        }
      });
      const json = await resp.json();
  
      return Promise.resolve(json)
    }
    
    async me(accessToken) {
      try {
        // Decode access token
        var decoded = jwt_decode(accessToken);
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/admins_informations/get/`
        const resp = await fetch(url, {
          method: 'POST',
          body: JSON.stringify({"admin_id": decoded.id}),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
          }
        })
        const user = await resp.json();
        return new Promise((resolve, reject) => {
          if (!user.id) {
            reject(new Error('Invalid authorization token'));
            return;
          }
          resolve({
            admin_id: user.id,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            avatar: user.avatar,
            access_level: user.access_level,
            id_service: user.id_service,
            roles_id: user.roles_id,
            // Tableau avec la liste des EPP où l'utilisateur est formateur
            epps: user.epps,
            components_and_permissions: user.components_and_permissions || [],
          });
        })
      } catch (err) {
        console.error('[Auth Api]: ', err);
        // reject(new Error('Internal server error'));
      };
  }
}
  
  export const authApi = new AuthApi();
import { useEffect } from "react";
import { useAuth } from "../../hooks/use-auth";
import { useLocation, useNavigate } from "react-router-dom";

export const usePermissionRedirect = (componentName) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const path = location.pathname.toLowerCase();

    // Détection des pages spécifiques
    const isAccessingCreate = path.includes(`/create/${componentName.toLowerCase()}`);
    const isAccessingList = path.endsWith(`/${componentName.toLowerCase()}`);

    let requiredPermissions = [];

    if (isAccessingCreate) {
      requiredPermissions = ["Créer"];
    } else if (isAccessingList) {
      // Exception pour "Formation" : il faut au moins une des permissions "Voir" ou "create"
      if (componentName === "Formation" || componentName === "EPP" ||componentName === "Liste des sessions" ||componentName === "Administrateurs" ) {
        const hasAnyPermission = ["Voir", "Créer"].some((perm) =>
          user.components_and_permissions.some(
            (userPerm) => userPerm.component === componentName && userPerm.permission === perm
          )
        );

        if (!hasAnyPermission) {
          navigate(`/${globalThis.localStorage.getItem("tenant")}/404_not_found`);
        }
        return; // Arrête la vérification pour éviter une redirection non voulue
      }

      requiredPermissions = ["Voir"];
    }

    if (requiredPermissions.length > 0) {
      const hasRequiredPermissions = requiredPermissions.every((perm) =>
        user.components_and_permissions.some(
          (userPerm) => userPerm.component === componentName && userPerm.permission === perm
        )
      );

      if (!hasRequiredPermissions) {
        navigate(`/${globalThis.localStorage.getItem("tenant")}/404_not_found`);
      }
    }
  }, [location.pathname, user, navigate, componentName]);
};

import { Box, Button, Card, Grid, IconButton, TextField} from '@mui/material';
import { TcsRulesModify } from './tcs-rulesModify';
import { tcsApi } from '../../../api/tcs-api';
import { useAuth } from '../../../hooks/use-auth';
import { DeleteOutline } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';

export const TcsVignetteModify = (props) => {
  const { tcsInformations, setTcsInformations, index} = props;
  const { user } = useAuth(); 

  const handleDeleteVignette = async (id) => {
    let newInfo = {...tcsInformations}
    let id_bdd = newInfo.details[index].vignettes.splice(id, 1)[0]["id"]
    newInfo.details[index].vignettes.map((item, index) => ({
      ...item,
      rank: index + 1
    }))
    await tcsApi.deleteVignette(user.admin_id, id_bdd)
    setTcsInformations(newInfo);
  }
  
  const handleDuplicateLastVignette = async () => {
    const newInfo = {...tcsInformations}

    let indexDetails = index;

    // On regarde pour Voir la dernière vignette au dessus
    if (newInfo.details[indexDetails].vignettes.length == 0) {
      indexDetails -= 1
      while (newInfo.details[indexDetails]) {
        if (newInfo.details[indexDetails].vignettes.length > 0) {
          break
        }
        indexDetails -= 1
      }
    }

    // Si y'en a aucune alors on revient tout en bas et on recommence
    if (indexDetails == -1) {
      indexDetails = newInfo.details.length - 1
      while (newInfo.details[indexDetails]) {
        if (newInfo.details[indexDetails].vignettes.length > 0) {
          break
        }
        indexDetails -= 1
      }
    }

    if (indexDetails != -1) {
      let last_index = newInfo.details[indexDetails].vignettes.length - 1
      const vignetteId = await tcsApi.createVignette(user.admin_id, tcsInformations.details[indexDetails].vignettes.length + 1, tcsInformations.details[indexDetails].id)
      newInfo.details[index].vignettes.push(
        {
          "id": vignetteId.id,
          "minus_one_answer": newInfo.details[indexDetails].vignettes[last_index]["minus_one_answer"],
          "minus_two_answer": newInfo.details[indexDetails].vignettes[last_index]["minus_two_answer"],
          "plus_one_answer": newInfo.details[indexDetails].vignettes[last_index]["plus_one_answer"],
          "plus_two_answer": newInfo.details[indexDetails].vignettes[last_index]["plus_two_answer"],
          "rank": tcsInformations.details[index].vignettes.length + 1,
          "title_first_rule": newInfo.details[indexDetails].vignettes[last_index]["title_first_rule"],
          "title_response": newInfo.details[indexDetails].vignettes[last_index]["title_response"],
          "title_second_rule": newInfo.details[indexDetails].vignettes[last_index]["title_second_rule"],
          "title_vignette": newInfo.details[indexDetails].vignettes[last_index]["title_vignette"],
          "vignette_group_id": tcsInformations.details[index].id,
          "vignette_rules": [],
          "zero_answer": newInfo.details[indexDetails].vignettes[last_index]["zero_answer"]
        }
      )
      // console.log(newInfo.details);
      sendVignetteChangeToDb(newInfo.details[indexDetails].vignettes.length - 1)
      setTcsInformations(newInfo)
    } else {
      toast.error("Aucune vignette trouvée");
    }
  }

  const handleAddVignette = async () => {
    const newInfo = {...tcsInformations}
    const vignetteId = await tcsApi.createVignette(user.admin_id, tcsInformations.details[index].vignettes.length + 1, tcsInformations.details[index].id)
    newInfo.details[index].vignettes.push(
      {
        "id": vignetteId.id,
        "minus_one_answer": "",
        "minus_two_answer": "",
        "plus_one_answer": "",
        "plus_two_answer": "",
        "rank": tcsInformations.details[index].vignettes.length + 1,
        "title_first_rule": "",
        "title_response": "",
        "title_second_rule": "",
        "title_vignette": "",
        "vignette_group_id": tcsInformations.details[index].id,
        "vignette_rules": [],
        "zero_answer": ""
      }
    )
    setTcsInformations(newInfo)
  }

  const handleChangeTcsInformations = (field, value, indexVignette) => {
    const newDetails = [...tcsInformations.details];
    newDetails[index].vignettes[indexVignette][field] = value;
    setTcsInformations({ ...tcsInformations, details: newDetails });
  };

  const sendVignetteChangeToDb = async (indexVignette) => {
    const dataVignetteAll = tcsInformations.details[index].vignettes[indexVignette]
    const dataVignette = (({vignette_rules, rank, ...rest}) => rest)(dataVignetteAll)
    await tcsApi.updateVignette(user.admin_id, dataVignette)
  }

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    let items = [...tcsInformations.details];
    const [reorderedItem] = items[index].vignettes.splice(result.source.index, 1);
    items[index].vignettes.splice(result.destination.index, 0, reorderedItem);

    items[index].vignettes = items[index].vignettes.map((item, index) => ({
      ...item,
      rank: index + 1,
    }))

    setTcsInformations({
      ...tcsInformations,
      details: items
    })

    const vignette = items[index].vignettes.map(item => {
      const { id, rank } = item;
      return { id, rank };
    })
    await tcsApi.updateVignetteOrder(user.admin_id, vignette)
  }

  return (
  <>
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId='tcs-vignette'>
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
          {tcsInformations.details[index].vignettes.map((item, indexVignette) => (
            <Draggable key={item.id} draggableId={item.id.toString()} index={indexVignette}>
              {(provided) => (
                <Card
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  variant='outlined'
                  sx={{
                    p: 2,
                    pb: 5,
                    mt: 3,
                    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
                    backgroundColor: '#F8F8FF'
                  }}>
                  <Grid container alignItems="center" justifyContent={'center'}>
                    <Grid item sm={11} sx={{textAlign: 'center', pl: '18em', pr: '9em'}}>
                      <TextField
                        placeholder='Entrez le titre de la vignette'
                        fullWidth
                        sx={{
                          mt: 3,
                          mb: 1,
                          backgroundColor: 'white',
                        }}
                        size='small'
                        value={item.title_vignette}
                        onChange={(e) => {
                          handleChangeTcsInformations("title_vignette", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                    <Grid item sm={1} sx={{textAlign: 'center'}}>
                      <IconButton
                        onClick={() => handleDeleteVignette(indexVignette)}
                      >
                        <DeleteOutline/>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid  container sx={{pl: '2em'}}>
                    <Grid item sm={4}
                      sx={{
                        border: '2px solid grey',
                        backgroundColor: '#1B2445',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          "& fieldset": { border: 'none' },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Titre de la première règle'
                        fullWidth
                        multiline
                        value={item.title_first_rule}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("title_first_rule", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}
                      sx={{
                        borderTop: '2px solid grey',
                        borderBottom: '2px solid grey',
                        borderRight: '2px solid grey',
                        backgroundColor: '#1B2445',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          "& fieldset": { border: 'none' },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Titre de la seconde règle'
                        fullWidth
                        multiline
                        value={item.title_second_rule}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("title_second_rule", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}
                      sx={{
                        borderTop: '2px solid grey',
                        borderBottom: '2px solid grey',
                        borderRight: '2px solid grey',
                        backgroundColor: '#1B2445',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          "& fieldset": { border: 'none' },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Titre de la troisième règle'
                        fullWidth
                        multiline
                        value={item.title_response}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("title_response", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                  </Grid>
                  <TcsRulesModify
                    tcsInformations={tcsInformations}
                    setTcsInformations={setTcsInformations}
                    indexVignette={indexVignette}
                    index={index}
                  />
                  <Grid  container sx={{pl: '2em'}}>
                    <Grid item sm={4}
                      sx={{
                        borderLeft: '2px solid grey',
                        borderBottom: '2px solid grey',
                        borderRight: '2px solid grey',
                        backgroundColor: '#778899',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        fullWidth
                        multiline
                        placeholder='Réponse -2'
                        value={item.minus_two_answer}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("minus_two_answer", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Réponse -1'
                        fullWidth
                        multiline
                        value={item.minus_one_answer}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("minus_one_answer", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}
                      sx={{
                        borderBottom: '2px solid grey',
                        borderRight: '2px solid grey',
                        backgroundColor: '#778899',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Réponse 0'
                        fullWidth
                        multiline
                        value={item.zero_answer}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("zero_answer", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                    <Grid item sm={4}
                      sx={{
                        borderRight: '2px solid grey',
                        borderBottom: '2px solid grey',
                        backgroundColor: '#778899',
                      }}
                    >
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Réponse +1'
                        fullWidth
                        multiline
                        value={item.plus_one_answer}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("plus_one_answer", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                      <TextField
                        sx={{
                          textarea: {
                            color: "white"
                          },
                          '& ::placeholder': {
                            color: 'lightgrey',
                            fontStyle: 'italic',
                            fontWeight: 'normal'
                          }
                        }}
                        placeholder='Réponse +2'
                        fullWidth
                        multiline
                        value={item.plus_two_answer}
                        size='small'
                        onChange={(e) => {
                          handleChangeTcsInformations("plus_two_answer", e.target.value, indexVignette)
                        }}
                        onBlur={() => {
                          sendVignetteChangeToDb(indexVignette)
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
    <Button
      onClick={handleAddVignette}
      variant="contained"
      sx={{
        mt: 2
      }}
    >
      Ajouter une vignette
    </Button>
    <Button
      onClick={handleDuplicateLastVignette}
      variant="contained"
      sx={{
        mt: 2,
        ml: 2
      }}
    >
      Dupliquer la dernière vignette sauvegardée
    </Button>
  </>
  );
}
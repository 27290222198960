import { Box, Button, Typography,  Container, Alert,Grid, CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import Logo_Kalanke from './../images/Logo-Kalanke.png';
// import { Box, Card, Typography } from '@mui/material';
import pauline from './../images/new_pauline_1.png';
import { UAParser } from 'ua-parser-js';
// import Logo_Kalanke from './../images/Logo-Kalanke.png';

export default function Email_sent() {
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight
    const navigate = useNavigate()
   
    const auth = useAuth();
    const { user } = useAuth();

     const [loading, setLoading] = useState(true); // Loading state to manage tenant check
    const [first_login,setFirst_login]=useState("");
    const [new_device,setNew_device]=useState("");
    const parser = new UAParser();
    const uaData = parser.getResult();
    const [userAgentData, setUserAgentData] = useState({
      parser:parser,
      uaData:uaData,
    });


const checkAdminFirstLoginAndIp=async()=> {
    
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/check_login_and_ip/`
      const values = {
        "admin_id": user.admin_id,
       
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
  }}
);
      const json = await resp.json();
      setFirst_login(json.first_login);
      setNew_device(json.new_device);
      // console.log(`new_device : ${new_device}`);
      // console.log(`first_login : ${first_login}`);
      setLoading(false);
      if (!json.new_device && !json.first_login) {
        // localStorage.removeItem('accessToken');
        // 
        navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard`);  
        
      }
      else{
        // localStorage.removeItem('accessToken');
        // navigate(`/${globalThis.localStorage.getItem('tenant')}`); 
      }
   setLoading(false);
      
      
      // return Promise.resolve(json)
    
  }

    const send_first_login_email=async()=> {
    
      const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/validate-admin-email/`
      const values = {
        "admin_id": user.admin_id,
       
      };
      const accessToken = globalThis.localStorage.getItem("accessToken");
      const resp = await fetch(url, {
        
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
  }}
);
      const json = await resp.json();
  
      return Promise.resolve(json)
    
  }



  // useEffect(async ()=>{
  //   // console.log(auth);
  //    if (auth.isAuthenticated) {
  //     await checkAdminFirstLoginAndIp();
  //     if (first_login || new_device){
  //        await send_first_login_email();
  //        localStorage.removeItem('accessToken');
  //     // navigate(`/${globalThis.localStorage.getItem('tenant')}`)      
  //       } 
  //       else{
  //               navigate(`/${globalThis.localStorage.getItem('tenant')}`);     

  //       } 
  //     }else{
  //       if(globalThis.localStorage.getItem('tenant')){
  //         navigate(`/${globalThis.localStorage.getItem('tenant')}`)
  //       }else{
  //         navigate(`/`)
  //       }
        
  //     }
    
  // });

  useEffect(() => {
    // console.log(userAgentData.parser.getResult());
    // console.log(userAgentData.uaData);
    // console.log( userAgentData.uaData.device); // Check the output here
    let isMounted = true; // Track if the component is mounted
  
    const checkAndSendEmail = async () => {
      try {
        if (globalThis.localStorage.getItem('accessToken')) {
          await checkAdminFirstLoginAndIp();
          // console.log(first_login,new_device);
          if ((first_login===true || new_device===true) && isMounted) {
            await send_first_login_email();
           
          } else if (isMounted) {
            // localStorage.removeItem('accessToken');
            navigate(`/${globalThis.localStorage.getItem('tenant')}`);
          }
        } else if (isMounted) {
          if (globalThis.localStorage.getItem('tenant')) {
            navigate(`/${globalThis.localStorage.getItem('tenant')}`);
          } else {
            navigate(`/`);
          }
        }
      } catch (error) {
        console.error('Error during authentication check:', error);
      } finally {
        if (isMounted) setLoading(false); // Only set loading state if still mounted
      }
    };
  
    checkAndSendEmail();
  
    return () => {
      isMounted = false; // Cleanup function sets isMounted to false
    };
  }, [auth.isAuthenticated, first_login, new_device, navigate]);
  

    const handleClick = () => {
        if (auth.isAuthenticated) {
         localStorage.removeItem('accessToken');
          navigate(`/${globalThis.localStorage.getItem('tenant')}`)  
        } 
        else {
          navigate("/")
        }
    }

    if (loading) {
      return (
        <Grid 
        container 
        justifyContent="center" 
        alignItems="center" 
        style={{ height: '100vh', position: 'relative' }}
      >
        {/* Circular Progress underneath the image */}
        <CircularProgress 
          size={170} // Adjust the size as needed
          style={{ position: 'absolute' }}
        />
      
        {/* Image on top */}
        <img
          alt="logo"
          src={Logo_Kalanke}
          style={{
            width: '120px', // Adjust the size to fit over the CircularProgress
            position: 'absolute',
          }}
        />
      </Grid>
      
  
      );
    }
  


    return (
      <Grid container style={{ height: '100vh', width: '100vw' }}>
      {/* Left Side (Logo and Background) */}
      <Grid item style={{ height: '100%', width: '47%', backgroundColor: '#5d5db7', position: 'relative' }}>
        <img
          alt="logo"
          src={Logo_Kalanke}
          style={{ width: '200px', position: 'absolute', bottom: '60px', left: '60px', zIndex: 2 }}
        />
        <div style={{ width: '100%', height: '100%', display: 'flex', zIndex: 0 }}>
          <img alt="Image" src={pauline} style={{ width: '100%', opacity: 1 }} />
        </div>
      </Grid>

      {/* Right Side (Form) */}
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        style={{ height: '100%', width: '53%', backgroundColor: '#ffffff' }}
      >
        {/* <div>
          <Typography style={{ textAlign: 'center', color: '#5d5db7', fontSize: '24px', fontFamily: '"Montserrat", sans-serif' }}>
            Kalanke
          </Typography>
          <Typography
            variant='h3'
            style={{ textAlign: 'center', color: '#5d5db7', fontSize: '26px', fontWeight: 900, fontFamily: '"Montserrat", sans-serif' }}
          >
            Back-Office
          </Typography>
        </div> */}

        
        <Container maxWidth="lg" sx={{ mt: 5 }}>
        
      
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',
          // border:"1px solid"
            //  width: windowWidth, height: windowHeight
              }}>
            
          {first_login && 
         <Box sx={{width:"80%"}}><Typography variant="h3" sx={{ mt: 2,textAlign:'center',mb:2 }}>Bienvenue sur le back-office Kalanke ! </Typography>
          <Typography variant="h5" sx={{ fontWeight: 400,textAlign:'center' }}>Nous sommes ravis de vous accueillir pour votre première connexion. Afin de sécuriser votre compte, un e-mail vient de vous être envoyé. Il contient un lien qui vous permettra de créer votre mot de passe personnel. </Typography>
          <Typography variant="h5" sx={{mt:1, fontWeight: 400 ,textAlign:'center'}}>Si vous ne voyez pas l’e-mail dans votre boîte de réception, pensez à vérifier vos courriers indésirables (spam). Une fois votre mot de passe créé, vous pourrez accéder à toutes nos fonctionnalités en toute sécurité. </Typography>
         </Box>  }
         {!first_login && new_device && 
         <Box sx={{width:"80%"}}><Typography variant="h3" sx={{ mt: 2,textAlign:'center',mb:2 }}>Bienvenue sur le back-office Kalanke ! </Typography>
          <Typography variant="h5" sx={{ fontWeight: 400,textAlign:'center' }}>Nous avons détecté une connexion à partir d'un nouvel appareil. Pour des raisons de sécurité, un e-mail vient de vous être envoyé pour confirmer cette tentative de connexion. </Typography>
          <Typography variant="h5" sx={{ mt:2,fontWeight: 400 ,textAlign:'center'}}>Dans cet e-mail, vous trouverez un lien vous permettant de confirmer que vous êtes bien à l'origine de cette connexion. Une fois confirmé, vous pourrez accéder à votre compte en toute tranquillité. </Typography>
          {/* <Typography variant="h5" sx={{ fontWeight: 400 ,textAlign:'center'}}>Vous allez recevoir un message sur votre email contenant un lien de confirmation pour pouvoir accéder à votre compte</Typography> */}
         </Box>  }
           <Button size="large" sx={{ fontSize: 18, mt: 5 }} onClick={handleClick}>Revenir à la page d'accueil</Button>
        </Box>
      
      </Container>

      {

      }
     
      </Grid>
    </Grid>
   
        
    )
}

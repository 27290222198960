import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { gtm } from "../../../lib/gtm";
import {
  useLocation,
  Link as RouterLink,
  useParams,
  useNavigate,
} from "react-router-dom";

import {
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import LinkIcon from "@mui/icons-material/Link";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Scrollbar } from "../../../components/scrollbar";
import ProgressBar from "../../../components/dashboard/session/progress-bar";
import { useMounted } from "../../../hooks/use-mounted";
import { statsApi } from "../../../api/stat-api";
import SearchIcon from "@mui/icons-material/Search";
import { SeverityPill } from "../../../components/severity-pill";

const CourseSession = () => {
  const { sessionId } = useParams();
  const isMounted = useMounted();
  const [dataCourses, setDataGraphics] = useState([]);
  const [filteredDataCourses, setFilteredDataCourses] = useState([]);
  const [filteredCustomers, setSelectedCustomers] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  if (!location.state) {
    location.state = {
      from: `/${globalThis.localStorage.getItem('tenant')}/dashboard/session`,
      data_from: [],
    };
  }
  const { data_from } = location.state;

  const { user } = useAuth();
  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
    setFilteredDataCourses(
      dataCourses.filter(
        (el) =>
          `${el.details.firstname} ${el.details.lastname}`
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          `${el.details.lastname} ${el.details.firstname}`
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      )
    );
  };

  const getGraphics = useCallback(async () => {
    try {
      const data = await statsApi.getCoursesSessionStats(
        user.admin_id,
        data_from.auditId,
        data_from.startSession,
        data_from.endSession,
        data_from.sessions_id
      );
      if (data.msg) navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/session/not-available`);
      if (isMounted()) {
        setDataGraphics(data);
        setFilteredDataCourses(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  useEffect(() => {
    getGraphics();
    gtm.push({ event: "page_view" });
  }, []);

  useEffect(() => {
    if (filteredCustomers.length) {
      setSelectedCustomers([]);
    }
  }, [dataCourses]);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="mx">
          <Box sx={{ mb: 4 }}>
            <RouterLink to={data_from.from} style={{ textDecoration: "none" }}>
              <Link
                color="textPrimary"
                component="a"
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
                <Typography variant="subtitle2">
                  {data_from.from === `/${globalThis.localStorage.getItem('tenant')}/dashboard/session`
                    ? "Liste des sessions"
                    : "Apprenant"}
                </Typography>
              </Link>
            </RouterLink>
          </Box>
          <Box sx={{ mb: 3 }}>
            <Grid container justifyContent="space-between" spacing={3}>
              <Grid item>
                <Typography
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark" ? "#F5F6FC" : "#1c2531",
                  }}
                  variant="h4"
                >
                  Liste des apprenants dans la session
                </Typography>
                <Typography variant="h5">{data_from.auditName}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Card>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: -1,
                p: 3,
              }}
            >
              <Box
                sx={{
                  m: 1,
                  maxWidth: "100%",
                  width: 450,
                }}
              >
                <TextField
                  value={searchName}
                  onChange={handleChangeSearch}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Chercher un apprenant"
                />
              </Box>
              <Box
                sx={{
                  m: 1,
                  maxWidth: "100%",
                  width: 450,
                }}
              >
                <Typography sx={{ mx: 3 }} fontWeight="Bold" fontSize={13}>
                  Debut de session: {data_from.startSession}
                </Typography>
                <Typography sx={{ mx: 3 }} fontWeight="Bold" fontSize={13}>
                  Fin de session: {data_from.endSession}
                </Typography>
              </Box>
              {data_from.sessions_id ? (
                <Box>
                  <Typography display="inline" fontWeight="Bold" fontSize={15}>
                    Session n°
                  </Typography>
                  <Typography display="inline" fontWeight="Bold" fontSize={15}>
                    {data_from.sessions_number}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
            {dataCourses.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                padding={10}
              >
                <CircularProgress color="inherit" size={150} />
              </Box>
            ) : (
              <Scrollbar>
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ p: 2 }}>
                        <Typography
                          sx={{ mx: 3 }}
                          fontWeight="Bold"
                          fontSize={13}
                        >
                          Nom de l&apos;apprenant
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Tlms
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Syna
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Étape
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Dernière connexion
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Progression
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Durée
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          État
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Patients <br /> tour 1
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Patients <br /> tour 2
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="Bold" fontSize={13}>
                          Prédiction fin
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {filteredDataCourses.length > 0
                    ? filteredDataCourses.map((item, index) => (
                        <TableBody>
                          <TableRow hover key={index}>
                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 2 }}>
                                  <RouterLink
                                    // to={
                                    //   user.access_level == 2 ||
                                    //   user.id_service == 1
                                    //     ? `/:tenant/dashboard/feedbacks/${item.details.id}`
                                    //     : `/:tenant/dashboard/apprenants/${item.details.id}`
                                    // }
                                    to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants/${item.details.id}`}
                                    state={{
                                      from: `session/${sessionId}`,
                                      data_from: {
                                        auditId: data_from.auditId,
                                        startSession: data_from.startSession,
                                        endSession: data_from.endSession,
                                        sessions_id: data_from.sessions_id,
                                        from: data_from.from,
                                      },
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Typography
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? process.env
                                              .REACT_APP_SECONDARY_COLOR
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontWeight="Bold"
                                      fontSize={14}
                                    >
                                      {item.details.firstname +
                                        " " +
                                        item.details.lastname}{" "}
                                      <br />
                                    </Typography>
                                    <Typography
                                      color={(theme) =>
                                        theme.palette.mode === "dark"
                                          ? process.env
                                              .REACT_APP_SECONDARY_COLOR
                                          : process.env.REACT_APP_PRIMARY_COLOR
                                      }
                                      fontSize={13}
                                    >
                                      {item.details.email}
                                    </Typography>
                                  </RouterLink>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  {item.details.tlms_id ? (
                                    <IconButton
                                      href={`${process.env.REACT_APP_TLMS_URL}/user/info/id:${item.details.tlms_id}`}
                                      target="_blank"
                                    >
                                      <LinkIcon fontSize="small" />
                                    </IconButton>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <IconButton
                                    href={`${process.env.REACT_APP_SYNA_URL}/dashboard/apprenants/${item.details.syna_id}`}
                                    target="_blank"
                                  >
                                    <LinkIcon fontSize="small" />
                                  </IconButton>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    fontSize={18}
                                  >
                                    {item.audits_list.step}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    fontSize={18}
                                  >
                                    {item.details.last_activity_date
                                      ? item.details.last_activity_date
                                      : "Aucune connexion"}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ minWidth: 100, ml: 1 }}>
                                  <ProgressBar
                                    value={item.audits_list.progression}
                                  />
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    variant="h6"
                                  >
                                    {item.audits_list.spent_time}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  {/* <Typography
                           color={(theme) => theme.palette.mode === 'dark' ? '#F5F6FC' : process.env.REACT_APP_PRIMARY_COLOR}
                           variant="h6"
                          >
                             {item.audits_list.state}
                          </Typography> */}
                                  <SeverityPill
                                    color={
                                      item.audits_list.state === "Non commencé"
                                        ? "error"
                                        : item.audits_list.state ===
                                          "Terminé en retard"
                                        ? "warning"
                                        : "success"
                                    }
                                  >
                                    {item.audits_list.state}
                                  </SeverityPill>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box>
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    // fontWeight='Bold'
                                    variant="h6"
                                  >
                                    {item.audits_list.nb_patients1}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    // fontWeight='Bold'
                                    variant="h6"
                                  >
                                    {item.audits_list.nb_patients2}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>

                            <TableCell>
                              <Box
                                sx={{
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <Box sx={{ ml: 1 }}>
                                  <Typography
                                    color={(theme) =>
                                      theme.palette.mode === "dark"
                                        ? "#F5F6FC"
                                        : process.env.REACT_APP_PRIMARY_COLOR
                                    }
                                    // fontWeight='Bold'
                                    variant="h6"
                                  >
                                    {item.audits_list.prediction
                                      ? item.audits_list.prediction
                                      : "-"}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      ))
                    : ""}
                </Table>
              </Scrollbar>
            )}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default CourseSession;

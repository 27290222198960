import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  InputAdornment,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Tooltip,
} from "@mui/material";
import { Scrollbar } from "../../../components/scrollbar";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "../../../hooks/use-auth";// Importer useAuth pour récupérer l'utilisateur connecté

const List_admin = () => {
  const [admins, setAdmins] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [pagin, setPagin] = useState(0);
  const [totalNumberOfAdmins, setTotalNumberOfAdmins] = useState(0);
  const [limit, setLimit] = useState(25);
  const [isSearching, setIsSearching] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [adminToDelete, setAdminToDelete] = useState(null);
  const [hoveredId, setHoveredId] = useState(null);
  const [permissions, setPermissions] = useState([]); // État pour stocker les permissions
  const { user } = useAuth(); // Récupérer l'utilisateur connecté
  const navigate = useNavigate();

  // Récupérer les permissions de l'utilisateur
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      roles_id: roles_id,
    };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Vérifier la permission de suppression
  const hasDeletePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Administrateurs" && permission.permission === "Supprimer"
    );
  };

  // Vérifier la permission de mise à jour
  const hasUpdatePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Administrateurs" && permission.permission === "Mettre à jour"
    );
  };

  // Récupérer les administrateurs
  const fetchAdmins = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/admin/get`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setAdmins(data.admin);
      setTotalNumberOfAdmins(data.admin.length);
    } catch (error) {
      console.error("Error fetching admins:", error);
    }
  }, []);

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id); // Récupérer les permissions de l'utilisateur
      fetchAdmins(); // Récupérer les administrateurs
    }
  }, [user, fetchAdmins]);

  // Logique de recherche
  const handleSearch = useCallback(() => {
    const results = admins.filter((admin) => {
      const firstname = admin.firstname ? admin.firstname.toLowerCase() : '';
      const lastname = admin.lastname ? admin.lastname.toLowerCase() : '';
      const email = admin.email ? admin.email.toLowerCase() : '';
      const role_name = admin.role_name ? admin.role_name.toLowerCase() : '';

      return (
        firstname.includes(searchName.toLowerCase()) ||
        lastname.includes(searchName.toLowerCase()) ||
        email.includes(searchName.toLowerCase()) ||
        role_name.includes(searchName.toLowerCase())
      );
    });
    setAdmins(results);
  }, [searchName, admins]);

  useEffect(() => {
    if (searchName.length >= 3) {
      setIsSearching(true);
      handleSearch();
    } else {
      if (isSearching) {
        fetchAdmins();
        setIsSearching(false);
      }
    }
  }, [searchName]);

  // Gestion de la pagination
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPagin(0);
  };

  const handlePageChange = (event, newPage) => {
    setPagin(newPage);
  };

  // Gestion de la modification
  const handleEdit = (admin) => {
    if (hasUpdatePermission()) {
      const tenant = localStorage.getItem('tenant');
      navigate(`/${tenant}/dashboard/admins/update`, { state: { admin } });
    }
  };

  // Gestion de la suppression
  const handleOpenDeleteDialog = (id) => {
    if (hasDeletePermission()) {
      setAdminToDelete(id);
      setOpenDeleteDialog(true);
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setAdminToDelete(null);
  };

  const handleDeleteAdmin = async () => {
    if (!adminToDelete) return;

    try {
      const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/admin/delete/`;
      const accessToken = localStorage.getItem("accessToken");

      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          id: adminToDelete,
        }),
      });

      if (!response.ok) throw new Error(`Erreur HTTP! Status: ${response.status}`);

      const data = await response.json();

      if (data.msg === "Admin deleted successfully") {
        toast.success('Admin supprimé avec succès !');
        fetchAdmins();
      } else {
        toast.error(data.msg || "Erreur lors de la suppression.");
      }
    } catch (err) {
      console.error(err);
      toast.error('Une erreur est survenue lors de la suppression !');
    } finally {
      handleCloseDeleteDialog();
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, py: 11 }}>
      <Container maxWidth="xl">
        <Box sx={{ mb: 5 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h4" sx={{ fontFamily: "Montserrat" }}>
                Administrateurs
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Card>
          <Divider />
          <Box sx={{ alignItems: "center", display: "flex", flexWrap: "wrap", m: -1, p: 3 }}>
            <Box sx={{ m: 1, maxWidth: "100%", width: 450 }}>
              <TextField
                value={searchName}
                onChange={(e) => setSearchName(e.target.value)}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Chercher un administrateur"
              />
            </Box>
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ pl: 3 }}>
                    <Typography fontWeight="Bold" fontSize={13}>
                      Nom
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pl: 3 }}>
                    <Typography fontWeight="Bold" fontSize={13}>
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell align="center"  sx={{ pl: 3 }}>
                    <Typography fontWeight="Bold" fontSize={13} ml={0}>
                      Rôle
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ pl: 3 }}>
                    <Typography fontWeight="Bold" fontSize={13}>
                      Actions
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.slice(pagin * limit, pagin * limit + limit).map((admin) => (
                  <TableRow
                    hover
                    key={admin.id}
                    onMouseEnter={() => setHoveredId(admin.id)}
                    onMouseLeave={() => setHoveredId(null)}
                  >
                    <TableCell sx={{ pl: 3 }}>
  <Typography
    component={hasUpdatePermission() ? RouterLink : "span"} // Conditionner le composant
    to={
      hasUpdatePermission()
        ? `/${localStorage.getItem('tenant')}/dashboard/admins/update`
        : "#"
    }
    state={{ admin }}
    fontWeight="bold"
    sx={{
      color: "#6060B8",
      textDecoration: "none",
      cursor: hasUpdatePermission() ? "pointer" : "default", // Curseur conditionnel
      "&:hover": {
        textDecoration: hasUpdatePermission() ? "underline" : "none", // Soulignement conditionnel
      },
    }}
  >
    {`${admin.firstname} ${admin.lastname}`}
  </Typography>
</TableCell>
                    <TableCell sx={{ pl: 3 }}>
                      <Typography sx={{ color: "#6060B8" }}>
                        {admin.email}
                      </Typography>
                    </TableCell>
                    <TableCell 
                    // align="center"
                     sx={{ pl: admin.role_name ?3:3 }}>
                     {admin.role_name ? <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: admin.role_name ? "0.875rem" : "0.75rem",
                          color: admin.role_name ? "inherit" : "text.secondary",

                        }}
                      >
                        {admin.role_name }
                      </Typography>:<Typography
                        sx={{
                          textAlign: "center",
                          fontSize: admin.role_name ? "0.875rem" : "0.75rem",
                          color: admin.role_name ? "inherit" : "text.secondary",
                        }}
                      >
                        {"—"}
                      </Typography>}
                    </TableCell>
                    <TableCell sx={{ pl: 3 }}>
                      {hoveredId === admin.id && (
                        <Box className="action-buttons" sx={{ display: "flex", gap: 1 }}>
                          {hasUpdatePermission() && (
                            <Tooltip title="Modifier">
                              <IconButton onClick={() => handleEdit(admin)} color="default">
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {hasDeletePermission() && (
                            <Tooltip title="Supprimer">
                              <IconButton onClick={() => handleOpenDeleteDialog(admin.id)} color="default">
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Scrollbar>
          <TablePagination
            component="div"
            count={totalNumberOfAdmins}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={pagin}
            labelRowsPerPage="Lignes par page :"
            rowsPerPage={limit}
            rowsPerPageOptions={[25, 50, 100]}
          />
        </Card>
      </Container>

      {/* Pop-up de confirmation de suppression */}
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer cet administrateur ?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Annuler
          </Button>
          <Button onClick={handleDeleteAdmin} color="error">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default List_admin;
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { PieChart } from "@mui/x-charts";
import { useEffect, useState } from "react";

export const GlobalStatsFirstTurn = (props) => {
  const { dataFirstTurn, criteriasNonComplianceFirstTurn } = props;

  const [observations, setObservations] = useState([]);
  const [nonConformitesMineures, setNonConformitesMineures] = useState([]);
  const [nonConformitesMajeures, setNonConformitesMajeures] = useState([]);

  useEffect(() => {
    // Filtrer les données en fonction du type
    const observationsData = criteriasNonComplianceFirstTurn.filter(
      (item) => item.type === "Observation"
    );
    const nonConformitesMineuresData = criteriasNonComplianceFirstTurn.filter(
      (item) => item.type === "NC mineure"
    );
    const nonConformitesMajeuresData = criteriasNonComplianceFirstTurn.filter(
      (item) => item.type === "NC majeure"
    );

    // Mettre à jour les états avec les données filtrées
    setObservations(observationsData);
    setNonConformitesMineures(nonConformitesMineuresData);
    setNonConformitesMajeures(nonConformitesMajeuresData);
  }, [criteriasNonComplianceFirstTurn]);

  return (
    <>
      <Typography
        variant="h5"
        color={(theme) =>
          theme.palette.mode === "dark"
            ? "white"
            : process.env.REACT_APP_PRIMARY_COLOR
        }
        sx={{ mt: 4, mb: 1, ml: 2 }}
      >
        Analyse globale
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Grid container>
        <Grid item xs={12} md={6} lg={6}>
          <Card sx={{ p: 3, m: 2, backgroundColor: "#9DA4C8", minHeight: 470}}>
            <Typography variant="h6" color={"white"}>
              {" "}
              Pourcentage global des réponses du tour 1 :
            </Typography>
            
            <Divider sx={{ my: 2 }} />
            <Grid container>
              <Grid item xs={12} md={9} lg={9}>
                <PieChart
                  series={[
                    {
                      data: [
                        {
                          id: 0,
                          value: dataFirstTurn.total_percent_oui,
                        },
                        {
                          id: 1,
                          value: dataFirstTurn.total_percent_non,
                        },
                        {
                          id: 2,
                          value: dataFirstTurn.total_percent_pnc,
                        },
                      ],
                    },
                  ]}
                  width={500}
                  height={300}
                  colors={[
                    process.env.REACT_APP_YES_COLOR,
                    process.env.REACT_APP_NO_COLOR,
                    process.env.REACT_APP_PNC_COLOR,
                  ]}
                  legend={false}
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
              <Box sx={{ display: "flex", pt: 10 }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_YES_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                   {dataFirstTurn.total_percent_oui}% OUI
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_NO_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                    {dataFirstTurn.total_percent_non}% NON
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_PNC_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                    {dataFirstTurn.total_percent_na}% NA
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Card sx={{ p: 3, m: 2,
             backgroundColor: "#9DA4C8",
              minHeight: 470 }}>
            <Typography variant="h6" color={"white"}>
              {" "}
              Indicateur de performances du tour 1 :
            </Typography>
            
            <Divider sx={{ my: 2 }} />
            <Box sx={{display: "flex",
    alignItems: "center", // Centers vertically
    justifyContent: "center", // Centers horizontally
       minHeight: 360
   }}>
            <Typography variant="h6" color={"white"}>
             
              Pas de données de performance pour le tour 1
            </Typography>
            </Box>
            {/* <Grid container>
              <Grid item xs={12} md={9} lg={9} height={370}>
                  <iframe
                    src="/radar.html"
                    width={"450px"}
                    height={"100%"}
                    style={{ border: "none", alignSelf: "center", marginTop: -30
                      // , border:'1px solid'
                    }}
                  ></iframe>
              </Grid>
              <Grid item xs={12} md={3} lg={3} pt={10} >
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_VOUS_RADAR_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                    VOUS
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_SESSION_RADAR_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                    SESSION
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", mt: 1 }}>
                  <Box
                    sx={{
                      width: 20,
                      height: 20,
                      backgroundColor: process.env.REACT_APP_TOTAL_RADAR_COLOR,
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ ml: 2, fontWeight: "bold", color: "white" }}
                  >
                    TOTAL
                  </Typography>
                </Box>
              </Grid>
            </Grid> */}
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {/* Analyse de non-conformité des critères */}
        <Card
          sx={{
            p: 1,
            m: 2,
            backgroundColor: "#9DA4C8",
            overflowY: "auto",
            scrollbarColor: `${process.env.REACT_APP_NEW_PRIMARY_COLOR} #f1f1f1`,
            height: "485px",
          }}
        >
          <Typography variant="h6" color={"white"} sx={{ mt: 2 }}>
            Analyse de non-conformité des critères :
          </Typography>
          
          <Divider sx={{ my: 2 }} />

          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", m: 2 }}
          >
            {/* Section Observations */}
            <Grid item xs={3.9}>
              <Typography variant="h6" sx={{ m: 1, color: "white" }}>
                Observations
              </Typography>
              {observations.length === 0 ? (
                <Typography variant="body1" sx={{ m: 1, color: "white" }}>
                  Il n'y a pas de données disponibles
                </Typography>
              ) : (
                observations.map((item) => (
                  <Card
                    key={item.id}
                    sx={{ borderRadius: 2, width: "70%", m: 1, p: 1 }}
                  >
                    <Typography variant="h6">
                      Critère {item.criteria_info.number}
                    </Typography>
                    <Typography>{item.criteria_info.criteria}</Typography>
                  </Card>
                ))
              )}
            </Grid>

            {/* Section Non-conformités mineures */}
            <Grid item xs={3.9}>
              <Typography variant="h6" sx={{ m: 1, color: "white" }}>
                Non-conformités mineures
              </Typography>
              {nonConformitesMineures.length === 0 ? (
                <Typography variant="body1" sx={{ m: 1, color: "white" }}>
                  Il n'y a pas de données disponibles
                </Typography>
              ) : (
                nonConformitesMineures.map((item) => (
                  <Card
                    key={item.id}
                    sx={{ borderRadius: 2, width: "70%", m: 1, p: 1 }}
                  >
                    <Typography variant="h6">
                      Critère {item.criteria_info.number}
                    </Typography>
                    <Typography>{item.criteria_info.criteria}</Typography>
                  </Card>
                ))
              )}
            </Grid>

            {/* Section Non-conformités majeures */}
            <Grid item xs={3.9}>
              <Typography variant="h6" sx={{ m: 1, color: "white" }}>
                Non-conformités majeures
              </Typography>
              {nonConformitesMajeures.length === 0 ? (
                <Typography variant="body1" sx={{ m: 1, color: "white" }}>
                  Il n'y a pas de données disponibles
                </Typography>
              ) : (
                nonConformitesMajeures.map((item) => (
                  <Card
                    key={item.id}
                    sx={{ borderRadius: 2, width: "70%", m: 1, p: 1 }}
                  >
                    <Typography variant="h6">
                      Critère {item.criteria_info.number}
                    </Typography>
                    <Typography>{item.criteria_info.criteria}</Typography>
                  </Card>
                ))
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

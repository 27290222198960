import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { toast, Toaster } from 'react-hot-toast';
import { useAuth } from "../../../../hooks/use-auth";

const RolesTable = () => {
  const [roles, setRoles] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const { user } = useAuth();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [roleToDelete, setRoleToDelete] = useState(null);
  const [permissions, setPermissions] = useState([]); // État pour stocker les permissions
  const navigate = useNavigate();

  // Récupérer les rôles depuis l'API
  const getAllRoles = async () => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/role/get/`;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setRoles(json.roles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  // Supprimer un rôle
  const deleteRole = async (roleId) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/role/delete/${roleId}/`;
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      console.log("Role deleted successfully:", json);
      return true;
    } catch (error) {
      console.error("Error deleting role:", error);
      return false;
    }
  };

  // Récupérer les permissions de l'utilisateur
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = {
      roles_id: roles_id,
    };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  // Vérifier la permission de suppression
  const hasDeletePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Rôles" && permission.permission === "Supprimer"
    );
  };

  // Vérifier la permission de mise à jour
  const hasUpdatePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Rôles" && permission.permission === "Mettre à jour"
    );
  };

  // Vérifier la permission de création
  const hasCreatePermission = () => {
    return permissions.some(
      (permission) =>
        permission.component === "Rôles" && permission.permission === "Créer"
    );
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
      getAllRoles(); // Appeler getAllRoles ici
    }
  }, [user]);

  // Gestion de la suppression
  const handleDeleteClick = (id) => {
    setRoleToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (roleToDelete) {
      const isDeleted = await deleteRole(roleToDelete);

      if (isDeleted) {
        setRoles((prevRoles) => prevRoles.filter((role) => role.id !== roleToDelete));
        toast('Rôle supprimé avec succès !', {
          icon: '🗑️',
          style: {
            backgroundColor: '#28a745',
            color: 'white',
          },
        });
      } else {
        toast.error("Erreur lors de la suppression du rôle. Veuillez réessayer.", {
          style: {
            backgroundColor: '#dc3545',
            color: 'white',
          },
        });
      }

      setOpenDeleteDialog(false);
      setRoleToDelete(null);
    }
  };

  const handleCancelDelete = () => {
    setOpenDeleteDialog(false);
    setRoleToDelete(null);
  };

  const handleEdit = (id, roleName) => {
    sessionStorage.setItem("roleId", id);
    sessionStorage.setItem("roleName", roleName);
    const tenant = localStorage.getItem('tenant');
    navigate(`/${tenant}/dashboard/permission_edit`);
  };

  const handleAddUserType = () => {
    const tenant = localStorage.getItem('tenant');
    navigate(`/${tenant}/dashboard/permission_role`);
  };

  return (
    <div style={{ padding: 20, marginTop: 50 }}>
      <div><Toaster /></div>

      {/* Bouton "Ajouter un rôle" (affiché uniquement si l'utilisateur a la permission) */}
      {hasCreatePermission() && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginBottom: 10 }}
          onClick={handleAddUserType}
        >
          Ajouter un rôle
        </Button>
      )}

      <div style={{ marginTop: 20 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><b>Nom</b></TableCell>
                <TableCell align="right"><b>Options</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {roles.map((role) => (
                <TableRow
                  key={role.id}
                  onMouseEnter={() => setHoveredId(role.id)}
                  onMouseLeave={() => setHoveredId(null)}
                >
                  <TableCell
                    style={{ cursor: hasUpdatePermission() ? 'pointer' : 'default' }} // Curseur conditionnel
                    sx={{
                      '&:hover': {
                        textDecoration: hasUpdatePermission() ? 'underline' : 'none', // Soulignement conditionnel
                      },
                    }}
                    onClick={hasUpdatePermission() ? () => handleEdit(role.id, role.role_name) : undefined} // Gestionnaire de clic conditionnel
                  >
                    {role.role_name.charAt(0).toUpperCase() + role.role_name.slice(1)}
                  </TableCell>
                  <TableCell align="right" style={{ width: 100 }}>
                    {hoveredId === role.id && (
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {/* Icône "Modifier" (affichée uniquement si l'utilisateur a la permission) */}
                        {hasUpdatePermission() && (
                          <Tooltip title="Modifier">
                            <IconButton color="default" onClick={() => handleEdit(role.id, role.role_name)}>
                              <Edit />
                            </IconButton>
                          </Tooltip>
                        )}

                        {/* Icône "Supprimer" (affichée uniquement si l'utilisateur a la permission) */}
                        {hasDeletePermission() && (
                          <Tooltip title="Supprimer">
                            <IconButton color="default" onClick={() => handleDeleteClick(role.id)}>
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Boîte de dialogue de confirmation de suppression */}
      <Dialog open={openDeleteDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirmer la suppression</DialogTitle>
        <DialogContent>
          Êtes-vous sûr de vouloir supprimer ce rôle ? Cette action est irréversible.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Annuler
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Supprimer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RolesTable;
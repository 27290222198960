import { useCallback, useEffect, useState } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Typography,
  TextField,
} from "@mui/material";
import { useAuth } from "../../../hooks/use-auth";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { criteriasApi } from "../../../api/criteria-api";
import CriteriaCard from "./criteriaCard";

const CriteriaSection = (props) => {
  const {
    criterias,
    updateCriterias,
    skills,
    modifyCriteria,
    setModifyCriteria,skillsArray,setSkillsArray,getAuditsDetails
  } = props;
  //   const [criterias, updateCriterias] = useState([]);
  //   const [skills, updateSkills] = useState([]);
  //   const [modifyCriteria, setModifyCriteria] = useState('');
  const { user } = useAuth();

  async function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(criterias);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    await criteriasApi.modifyCriteria(user.admin_id, items);
    updateCriterias(items);
  }

  async function handleDoubleClick(id, state) {
    let items = Array.from(criterias);
    if (state === false) {
      items[id]["criteria"] = modifyCriteria;
      await criteriasApi.modifyNameCriteria(
        user.admin_id,
        items[id]["id"],
        modifyCriteria
      );
    }
    items[id]["edited"] = state;
    updateCriterias(items);
  }

  async function handleModifyInclude(id, include) {
    let items = Array.from(criterias);
    items[id]["include"] = include;
    await criteriasApi.modifyInclude(user.admin_id, items[id]["id"], include);
    updateCriterias(items);
  }

  async function handleModifyCriteriaSkill(id, skillId) {
    let items = Array.from(criterias);
    if (skillId === "Aucune") skillId = null;
    items[id]["skills_id"] = skillId;
    await criteriasApi.modifySkill(user.admin_id, items[id]["id"], skillId);
    updateCriterias(items);
  }

  async function handleDeleteButton(id) {
    let items = Array.from(criterias);
    let id_bdd = items.splice(id, 1)[0]["id"];
    await criteriasApi.deleteCriteria(user.admin_id, id_bdd);
    updateCriterias(items);
  }

  return (
    <Grid>
      <Typography variant="h4">Critères</Typography>
      <Divider />
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="criterias">
          {(provided) => (
            <ul
              className="criterias"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {criterias.map(
                (
                  {
                    id,
                    criteria,
                    weight,
                    guide,
                    source,
                    edited,
                    more_infos_page_number,
                    include,
                    skills: skill_id,
                  },
                  index
                ) => {
                  return (
                    <Draggable
                      key={id}
                      draggableId={id.toString()}
                      index={index}
                      isDragDisabled={edited === true}
                    >
                      {(provided) =>
                        edited === true ? (
                          <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <TextField
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              sx={{
                                m: 1,
                                mr: 0,
                                border: 2,
                                borderColor: "primary.main",
                                borderRadius: "10px",
                                backgroundColor: "white",
                                width: "93%",
                              }}
                              focused
                              autoFocus
                              defaultValue={criteria}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Numéro {index + 1}{" "}
                                  </InputAdornment>
                                ),
                              }}
                              onFocus={() => {
                                setModifyCriteria(criteria);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleDoubleClick(index, false);
                                }
                              }}
                              onBlur={(e) => {
                                handleDoubleClick(index, false);
                              }}
                              onChange={(event) => {
                                setModifyCriteria(event.target.value);
                              }}
                            />
                          </Box>
                        ) : (
                          //else DRAG AND DROP NORMAL
                          <CriteriaCard
                          skillsArray={skillsArray}
                          setSkillsArray={setSkillsArray}
                            index={index}
                            include={include}
                            skill_id={skill_id}
                            criteriaId={id}
                            criteria={criteria}
                            initialWeight={weight}
                            initialGuide={guide}
                            initialSource={source}
                            handleDoubleClick={handleDoubleClick}
                            handleModifyInclude={handleModifyInclude}
                            handleModifyCriteriaSkill={
                              handleModifyCriteriaSkill
                            }
                            handleDeleteButton={handleDeleteButton}
                            skills={skills}
                            provided={provided}
                            getAuditsDetails={getAuditsDetails}
                          />
                        )
                      }
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
};

export default CriteriaSection;

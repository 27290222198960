import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import { useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { wait } from '../../../utils/wait';
import { userApi } from '../../../api/user-api';
import { useNavigate } from "react-router-dom";

export const CustomerEditForm = (props) => {
  const { user } = useAuth();
  const [permissions, setPermissions] = useState([]);

  const [redirectBool, setRedirectBool] = useState(false);
  const navigate = useNavigate();

  const redirectChange = event => {
    setRedirectBool(true)
  }
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = { roles_id };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);


  const hasCreatePermission = permissions.some(
    (permission) => permission.component === "Apprenants" && ["Voir", "Créer"].includes(permission.permission)
  );

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      tlms_id: '',
      syna_id: '',
      submit: null,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().max(255).required(),
      lastname: Yup.string().max(255).required(),
      email: Yup.string().email('Must be a valid email').max(255).required(),
      tlms_id: Yup.number().positive().integer(),
      syna_id: Yup.number().positive().integer()
    }),
    onSubmit: async (values, helpers) => {
      try {
        const id = await userApi.createUser(user.admin_id, values)
        await wait(500)
        if (!id.id) {
          toast.error("Erreur lors de la création de l'utilisateur")
          return
        }
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        toast.success('Utilisateur créé');
        if (redirectBool) {
          navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/apprenants/${id.id}`)
        }
      } catch (err) {
        console.error(err);
        toast.error('Une erreur est survenue!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardHeader title="Apprenant" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                fullWidth
                helperText={formik.touched.firstname && formik.errors.firstname}
                label="Prénom"
                name="firstname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.firstname}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                fullWidth
                helperText={formik.touched.lastname && formik.errors.lastname}
                label="Nom"
                name="lastname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.lastname}
              />
            </Grid>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.email}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.tlms_id && formik.errors.tlms_id)}
                fullWidth
                helperText={formik.touched.tlms_id && formik.errors.tlms_id}
                label="Id tlms"
                name="tlms_id"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // required
                value={formik.values.tlms_id}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                error={Boolean(formik.touched.syna_id && formik.errors.syna_id)}
                fullWidth
                helperText={formik.touched.syna_id && formik.errors.syna_id}
                label="Id syna"
                name="syna_id"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // required
                value={formik.values.syna_id}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions
          sx={{
            flexWrap: 'wrap',
            m: -1
          }}
        >
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
            // onClick={formik.handleSubmit}
          >
            Créer
          </Button>
          {permissions.some(
  (permission) =>
    permission.component === "Apprenants" &&
    ["Voir", "Créer"].every((perm) => permissions.some(p => p.component === "Apprenants" && p.permission === perm))
) && (
  <Button
    disabled={formik.isSubmitting}
    type="submit"
    sx={{ m: 1 }}
    variant="contained"
    onClick={redirectChange}
  >
    Créer et aller sur la fiche
  </Button>
)}

        </CardActions>
      </Card>
    </form>
  );
};

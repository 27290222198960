import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAuth } from '../../../hooks/use-auth';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { wait } from '../../../utils/wait';
import { userApi } from '../../../api/user-api';
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';

export const AdminCreateForm = (props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [roles, setRoles] = useState([]); // État pour stocker les rôles
  const [avatarUrl, setAvatarUrl] = useState('');
  // Récupérer les rôles depuis l'API
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/role/get/`;
        const accessToken = localStorage.getItem("accessToken");

        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setRoles(data.roles); // Mettre à jour l'état des rôles
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error("Erreur lors de la récupération des rôles.");
      }
    };

    fetchRoles();
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      avatar: '',
      roles_id: '', // Nouveau champ pour le rôle
      submit: null,
    },
    validationSchema: Yup.object({
      firstname: Yup.string().max(255).required(),
      lastname: Yup.string().max(255).required(),
      email: Yup.string().email('Must be a valid email').max(255).required(),
      avatar: Yup.string().max(255),
      password: Yup.string().max(255).required(),
      roles_id: Yup.string().required('Le rôle est requis'), // Validation pour le rôle
    }),
    onSubmit: async (values, helpers) => {
      try {
        // Appeler l'API pour créer l'admin
        const response = await userApi.createAdmin(
          user.admin_id, // ID de l'admin actuel
          values, // Données du formulaire
          0, // access_level (à adapter selon vos besoins)
          0, // id_service (à adapter selon vos besoins)
          values.roles_id // Rôle sélectionné
        );

        // Vérifier si la création a réussi
        if (response.id) {
          toast.success('Admin créé avec succès !');
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          helpers.resetForm(); // Réinitialiser les champs du formulaire
        } else {
          toast.error(response.msg || "Erreur lors de la création de l'admin.");
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: response.msg });
        }
      } catch (err) {
        console.error(err);
        toast.error('Une erreur est survenue !');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader title="Admin" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.firstname && formik.errors.firstname)}
                fullWidth
                helperText={formik.touched.firstname && formik.errors.firstname}
                label="Prénom"
                name="firstname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.firstname}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.lastname && formik.errors.lastname)}
                fullWidth
                helperText={formik.touched.lastname && formik.errors.lastname}
                label="Nom"
                name="lastname"
                autoCapitalize='characters'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.lastname}
              />
            </Grid>
            <Grid item md={12} xs={12} container alignItems="center" spacing={1}>

            <Grid item xs={-10} md={2} container direction="column" alignItems="flex-start">
    {avatarUrl ? (
      <img
        src={avatarUrl}
        alt="Avatar"
        style={{ marginTop: '8px', maxWidth: '90px', maxHeight: '90px', marginRight: '90px' }}
      />
    ) : (
      <img
        src="https://static-00.iconduck.com/assets.00/avatar-default-icon-2048x2048-h6w375ur.png" // Avatar par défaut
        alt="Avatar par défaut"
        style={{ marginTop: '8px', maxWidth: '90px', maxHeight: '90px', marginRight: '90px' }}
      />
    )}
  </Grid>
  <Grid item xs={9} md={10}>
  <TextField
    error={Boolean(formik.touched.avatar && formik.errors.avatar)}
    fullWidth
    helperText={formik.touched.avatar && formik.errors.avatar}
    label="Avatar"
    name="avatar"
    onBlur={formik.handleBlur}
    onChange={(e) => {
      formik.handleChange(e); // Gère le changement pour Formik
      setAvatarUrl(e.target.value); // Met à jour l'URL de l'avatar
    }}
    value={formik.values.avatar}
  />
</Grid>
</Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                required
                value={formik.values.email}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                error={Boolean(formik.touched.password && formik.errors.password)}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Mot de passe"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <InputLabel htmlFor="role">Rôle</InputLabel>
              <Select
                error={Boolean(formik.touched.roles_id && formik.errors.roles_id)}
                fullWidth
                helperText={formik.touched.roles_id && formik.errors.roles_id}
                label="Rôle"
                name="roles_id"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.roles_id}
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.role_name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ flexWrap: 'wrap', m: -1 }}>
          <Button
            disabled={formik.isSubmitting}
            type="submit"
            sx={{ m: 1 }}
            variant="contained"
          >
            Créer
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
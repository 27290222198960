import React, { useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Collapse, TextField, IconButton, Button, Link, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { Link as RouterLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useAuth } from "../../../../hooks/use-auth";

// Fonction pour transformer les permissions en structure hiérarchique
const transformPermissions = (rolesPermissions) => {
    if (!rolesPermissions || !Array.isArray(rolesPermissions)) {
      console.error("Invalid rolesPermissions data:", rolesPermissions);
      return [];
    }
  
    const components = [];
  
    rolesPermissions.forEach(({ components: roleComponents }) => {
      roleComponents.forEach(({ component, permissions }) => {
        const cleanedComponent = component.trim();
        const componentNode = {
          label: cleanedComponent,
          key: cleanedComponent, // Clé unique pour le composant
          children: [],
        };
  
        // Filtrer les permissions pour ne garder que celles avec assigned: true
        permissions
          .filter((permission) => permission.assigned === true)
          .forEach(({ id, name }) => {
            componentNode.children.push({
              label: name,
              key: `${cleanedComponent}-${id}`, // Clé unique pour la permission
              children: [],
            });
          });
  
        components.push(componentNode);
      });
    });
  
    return components;
  };

// Fonction pour récupérer les permissions depuis l'API
const getAllPermissions = async () => {
  const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission/get/`;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!resp.ok) {
      throw new Error(`HTTP error! Status: ${resp.status}`);
    }

    const json = await resp.json();
    return json;
  } catch (error) {
    console.error("Error fetching permissions:", error);
    return {};
  }
};

// Fonction pour envoyer les permissions au backend
const savePermissions = async (selectedPermissions) => {
  const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission_role/save/`;
  const accessToken = localStorage.getItem("accessToken");

  try {
    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(selectedPermissions),
    });

    if (!resp.ok) {
      throw new Error(`HTTP error! Status: ${resp.status}`);
    }

    const json = await resp.json();
    console.log("Permissions sauvegardées avec succès:", json);
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des permissions:", error);
  }
};

// Composant PermissionsTree
const PermissionsTree = ({ permissions = [], setPermissions }) => {
    const [expanded, setExpanded] = useState({});
    const [checked, setChecked] = useState({});
    const [search, setSearch] = useState("");
    const [roleName, setRoleName] = useState("");
  
    useEffect(() => {
        const initialChecked = {};
        permissions.forEach((component) => {
          const componentKey = component.key; // Clé unique pour le composant
      
          // Vérifier l'état des permissions enfants
          const allAssigned = component.children.every((perm) => perm.assigned);
          const someAssigned = component.children.some((perm) => perm.assigned);
      
          // Définir l'état du composant
          if (allAssigned) {
            initialChecked[componentKey] = true; // Tout est coché
          } else if (someAssigned) {
            initialChecked[componentKey] = "indeterminate"; // Certains sont cochés
          } else {
            initialChecked[componentKey] = false; // Rien n'est coché
          }
      
          // Initialiser les permissions enfants
          component.children.forEach((perm) => {
            const permKey = perm.key; // Clé unique de la permission
            initialChecked[permKey] = perm.assigned; // Utiliser la valeur `assigned` de l'API
          });
        });
      
        setChecked(initialChecked);
      }, [permissions]);
    
  
    const handleToggle = (label) => {
      setExpanded((prev) => ({ ...prev, [label]: !prev[label] }));
    };
  
    const handleCheck = (node, isChecked) => {
        let newChecked = { ...checked };
        const nodeKey = node.key;
        newChecked[nodeKey] = isChecked;
    
        if (node.children && node.children.length > 0) {
            node.children.forEach((child) => {
                newChecked[child.key] = isChecked;
            });
        }
    
        // Mise à jour des permissions dans le parent
        const updatedPermissions = permissions.map((component) => {
            if (component.key === node.key) {
                return {
                    ...component,
                    children: component.children.map((perm) => ({
                        ...perm,
                        assigned: isChecked, // Mise à jour de assigned
                    })),
                };
            } else {
                return {
                    ...component,
                    children: component.children.map((perm) =>
                        perm.key === node.key ? { ...perm, assigned: isChecked } : perm
                    ),
                };
            }
        });
    
        setChecked(newChecked);
        setPermissions(updatedPermissions); // Met à jour le parent
    };
    
    
    
  
    const handleSearch = (event) => {
      setSearch(event.target.value);
    };
  
    const collectSelectedPermissions = () => {
        const selectedPermissions = [];
      
        permissions.forEach((component) => {
          component.children.forEach((permission) => {
            const permissionKey = permission.key; // Clé unique de la permission
            if (checked[permissionKey]) {
              selectedPermissions.push({
                role_name: roleName,
                component: component.label,
                permission: permission.label,
                permission_id: permission.key.split("-")[1], // Extraire l'ID de la permission
              });
            }
          });
        });
      
        return selectedPermissions;
      };
  
    const handleSave = async () => {
      if (!roleName) {
        toast.error("Veuillez saisir un nom de rôle.");
        return;
      }
  
      const selectedPermissions = collectSelectedPermissions();
      console.log("Permissions sélectionnées à sauvegarder:", selectedPermissions);
  
      const loadingToast = toast.loading("Sauvegarde en cours...", {
        duration: 0,
      });
  
      try {
        await savePermissions(selectedPermissions);
        toast.success("Rôle et permissions sauvegardés avec succès !", {
          id: loadingToast,
          duration: 5000,
        });
      } catch (error) {
        console.error("Erreur lors de la sauvegarde des permissions:", error);
        toast.error("Une erreur est survenue lors de la sauvegarde.", {
          id: loadingToast,
          duration: 5000,
        });
      }
    };
  
    const renderTree = (nodes, level = 0) =>
        nodes
          .filter((node) => typeof node.label === "string" && node.label.toLowerCase().includes(search.toLowerCase()))
          .map((node) => {
            const nodeKey = node.key; // Clé unique du nœud
      
            return (
              <div key={nodeKey} style={{ marginLeft: `${level * 50}px`, marginBottom: "-5px" }}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "-6px" }}>
                  {node.children && node.children.length > 0 && (
                    <IconButton
                      onClick={() => handleToggle(node.label)}
                      style={{
                        transform: expanded[node.label] ? "rotate(0deg)" : "rotate(-90deg)",
                        transition: "transform 0.3s ease",
                        padding: "4px",
                      }}
                    >
                      <ExpandMore />
                    </IconButton>
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[nodeKey] === true}
                        indeterminate={checked[nodeKey] === "indeterminate"}
                        onChange={(e) => handleCheck(node, e.target.checked)}
                        size="small"
                      />
                    }
                    label={node.label}
                    style={{ margin: "0px", padding: "0px" }}
                  />
                </div>
                {node.children && (
                  <Collapse in={expanded[node.label]}>
                    {renderTree(node.children, level + 1)}
                  </Collapse>
                )}
              </div>
            );
          });
  
    return (
      <div>
        <TextField
  label="Nom du rôle"
  variant="outlined"
  value={roleName}
  onChange={(e) => setRoleName(e.target.value)}
  style={{ marginBottom: "20px", width: "25%" }}
/>

        {renderTree(permissions)}
        <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: "40px" }}>
          Sauvegarder
        </Button>
      </div>
    );
  };

  export default function PermissionsComponent() {
    const [permissions, setPermissions] = useState([]);
    const { user } = useAuth(); // Récupérer les informations de l'utilisateur connecté
  
    useEffect(() => {
      const fetchPermissions = async () => {
        const data = await getAllPermissions();
        if (data && data.roles_permissions) {
          // Filtrer les permissions en fonction du role_id de l'utilisateur connecté
          const filteredPermissions = data.roles_permissions.filter(
            (rolePermission) => rolePermission.role_id === user.roles_id
          );
          const transformedData = transformPermissions(filteredPermissions);
          setPermissions(transformedData);
        }
      };
      fetchPermissions();
    }, [user.roles_id]);
  
    return (
      <div style={{ padding: "40px" }}>
        <RouterLink to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/roles`} style={{ textDecoration: "none" }}>
          <Link color="textPrimary" component="a" sx={{ alignItems: "center", display: "flex", marginTop: "30px" }}>
            <ArrowBackIcon fontSize="small" sx={{ mr: 1 }} />
            <Typography variant="subtitle2" component="span"> Liste des Rôles</Typography>
          </Link>
        </RouterLink>
        <h3>Création d'un Rôle et de ses permissions</h3>
        <div><Toaster /></div>
        <PermissionsTree permissions={permissions} setPermissions={setPermissions} />
      </div>
    );
  }
import { useCallback, useEffect, useState,useRef } from "react";
import { useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Fab,
  InputLabel,
  Link,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  CardHeader,Autocomplete,Checkbox
} from "@mui/material";
import { useAuth } from "../../../hooks/use-auth";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { criteriasApi } from "../../../api/criteria-api";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Editor } from "@tinymce/tinymce-react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
const CriteriaCard = (props) => {
  const {skillsArray,setSkillsArray,
    index,
    include,
    skill_id,
    skills,
    criteriaId,
    criteria,
    initialWeight,
    initialGuide,
    initialSource,
    handleModifyInclude,
    handleModifyCriteriaSkill,
    handleDeleteButton,
    handleDoubleClick,
    provided,getAuditsDetails
  } = props;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [showDetails, setShowDetails] = useState(false);
  const [weight, setWeight] = useState(initialWeight);
  const [source, setSource] = useState(initialSource);
  const [guide, setGuide] = useState(initialGuide);
  const [selectedSkills, setSelectedSkills] = useState(skills.filter((r) => r.criterias.some((c) => c.criteria_id === criteriaId)) || []);
  const { user } = useAuth();
const editorRefGuide = useRef(null);
const editorRefSource = useRef(null);
// const [content, setContent] = useState(
//     editionMode ? existingChapter.content : ""
//   );
  const handleGuide = async (value) => {
    setGuide(value);
    await criteriasApi.modifyGuide(user.admin_id, criteriaId, value);
  }; 
  const removeSkill = async (criteriaId,skillId) => {
    // setGuide(value);
    await criteriasApi.removeSkill(user.admin_id, criteriaId, skillId);
    getAuditsDetails();
    setSelectedSkills(skills.filter((r) => r.criterias.some((c) => c.criteria_id === criteriaId)) || []);
  }; 
  const addSkill = async (criteriaId,skillId) => {
    // setGuide(value);
    await criteriasApi.addSkill(user.admin_id, criteriaId, skillId);
  getAuditsDetails(); 
  setSelectedSkills(skills.filter((r) => r.criterias.some((c) => c.criteria_id === criteriaId)) || []);
};

  const handleSource = async (value) => {
    setSource(value);
    await criteriasApi.modifySource(user.admin_id, criteriaId, value);
  };

  const handleWeight = async (value) => {
    setWeight(value);
    await criteriasApi.modifyWeight(user.admin_id, criteriaId, value);
  };

  const choiceOptions = [
    { label: "Non inclus", value: 1 },
    { label: "Neutre", value: 2 },
    { label: "Inclus", value: 3 },
  ];

  return (
    <Grid
      sx={{
        width: "95%",
      }}
    >
      <Card
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          m: 1,
          p: 1,
          width: "100%",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
          }}
        >
          <Grid
            item
            sm={7}
            sx={{
              width: "100%",
              alignItems: "center",
              display: "flex",
              px: 2,
            }}
            onDoubleClick={() => {
              handleDoubleClick(index, true);
            }}
          >
            <Typography>
              {"Numéro : " + (index + 1) + " " + criteria}
            </Typography>
          </Grid>
          <Grid container sm={4} spacing={1} sx={{ my: 2 }}>
            <Grid item sm={4}>
              <TextField
                label="Pondération"
                textAlign={"center"}
                type="number"
                value={weight}
                onChange={(event) => handleWeight(event.target.value)}
              />
            </Grid>
            <Grid
              item
              sm={8}
              // style={{ marginLeft: '10px' }}
            >
              <TextField
                label="Inclusion"
                name="choice"
                onChange={(event) => {
                  handleModifyInclude(index, event.target.value);
                }}
                select
                SelectProps={{ native: true }}
                value={include}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                {choiceOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            {/* <Grid
              item
              sm={12}
              sx={{ mt: 1 }}
              // style={{ marginLeft: '10px' }}
            >
              <TextField
                label="Compétence"
                name="choice"
                onChange={(event) => {
                  handleModifyCriteriaSkill(index, event.target.value);
                }}
                select
                SelectProps={{ native: true }}
                defaultValue={skill_id}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
              >
                <option key="default" value={null}>
                  Aucune
                </option>
                {skills.map((skill) => (
                  <option key={skill.id} value={skill.id}>
                    {skill.skill}
                  </option>
                ))}
              </TextField>
            </Grid> */}
             <Grid item sm={12} sx={{ mt: 1 }}>
           <Autocomplete  multiple id="tags-standard" options={skillsArray} getOptionLabel={(option) => option?.skill}
  defaultValue={ selectedSkills }
  disableCloseOnSelect
  onChange={(option,value,o, details) => {
    const selectedOption = details.option;
    console.log(details);
    console.log(details.option.id);
 if(skills.some((r) => r.criterias.some((c) => c.criteria_id === criteriaId && c.skill === details.option.id))){
    removeSkill(criteriaId, details.option.id)
    // setSelectedSkills([...selectedSkills, selectedOption]); // Then update stat
 }
 else{
  addSkill(criteriaId, details.option.id)
  // setSelectedSkills([...selectedSkills, selectedOption]); // Then update stat
 }
  }}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }}
                  checked={skills.some((r) => r.criterias.some((c) => c.criteria_id === criteriaId && c.skill === option.id))}
                />


                  {option.skill}
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
               variant="outlined"
                label="Compétences"
                // placeholder="Compétences"
              />
            )}
          />
            </Grid>
          </Grid>
          <Grid
            item
            sm={1}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box textAlign={"right"}>
              <Button
                sx={{
                  textAlign: "right",
                }}
                onClick={() => {
                  handleDeleteButton(index);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            </Box>
            <Box textAlign={"right"}>
              <Button
                sx={{
                  textAlign: "right",
                }}
                onClick={() => {
                  // handleDeleteButton(index)
                  setShowDetails(!showDetails);
                }}
              >
                {showDetails ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {showDetails && (
        <Box> 
           {/* <Grid container>
            <Grid item xs={12
              // 5.8
              } sx={{ mt: 1 }}>
              <TextField
                label="Source"
                name="source"
                multiline
                rows={5}
                variant="outlined"
                value={source}
                onChange={(e) => handleSource(e.target.value)}
                // InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>
            <Grid xs={0.4} />
            <Grid item xs={5.8} sx={{ mt: 1 }}>
              <TextField
                label="Guide"
                name="guide"
                multiline
                rows={5}
                variant="outlined"
                value={guide}
                onChange={(e) => handleGuide(e.target.value)}
                // InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid> 
          </Grid> */}
          <Box style={{marginTop:'20px'}}>
           <Typography variant="h6" style={{marginBottom:'20px'}}>Source:</Typography>
            <Editor     
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    onInit={(_evt, editor) => (editorRefSource.current = editor)}
                    // initialValue={content}
                    value={source}
                    onEditorChange={(newContent, editor) => handleSource(newContent)}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  </Box>
          <Box style={{marginTop:'20px'}}>
           <Typography variant="h6" style={{marginBottom:'20px'}}>Guide:</Typography>
            <Editor     
                    apiKey={process.env.REACT_APP_TINYMCE_KEY}
                    onInit={(_evt, editor) => (editorRefGuide.current = editor)}
                    // initialValue={content}
                    value={guide}
                    onEditorChange={(newContent, editor) => handleGuide(newContent)}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  </Box>
       </Box> )}
      
      </Card>
    </Grid>
  );
};

export default CriteriaCard;

import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";

const usePermissionCheck = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkPermissions = () => {
      const path = location.pathname;
      const requiredPermission = getRequiredPermission(path);

      if (requiredPermission && !hasPermission(requiredPermission)) {
        // Afficher un toast pour informer l'utilisateur
        toast.error("Vous n'avez plus accès à cette page. Redirection en cours...", {
          autoClose: 3000, // Fermer le toast après 3 secondes
        });

        // Rediriger vers le tableau de bord après 3 secondes
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      }
    };

    // Vérifier les permissions immédiatement
    checkPermissions();

    // Vérifier les permissions toutes les 30 secondes
    const interval = setInterval(checkPermissions, 30000);

    // Nettoyer l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, [user, location.pathname, navigate]);

  const hasPermission = (requiredPermission) => {
    return user.components_and_permissions.some(
      (perm) => perm.component === requiredPermission.component && perm.permission === requiredPermission.permission
    );
  };

  const getRequiredPermission = (path) => {
    // Mappez les chemins aux permissions requises
    const permissionsMap = {
      "/dashboard/scores": { component: "Scores", permission: "Voir" },
      "/dashboard/apprenants": { component: "Apprenants", permission: "Voir" },
      "/dashboard/forum": { component: "Forum", permission: "Voir" },
      "/dashboard/formations": { component: "Formation", permission: "Voir" },
      "/dashboard/epp/audits": { component: "EPP", permission: "Voir" },
      "/dashboard/epp/tcs": { component: "EPP", permission: "Voir" },
      "/dashboard/satisfaction": { component: "Satisfaction", permission: "Voir" },
      "/dashboard/session": { component: "Liste des sessions", permission: "Voir" },
      "/dashboard/agenda": { component: "Agenda", permission: "Voir" },
      "/dashboard/experts": { component: "Experts", permission: "Voir" },
      "/dashboard/activities": { component: "Activités", permission: "Voir" },
      "/dashboard/parametres": { component: "Paramètres", permission: "Voir" },
    };

    return permissionsMap[path];
  };
};

export default usePermissionCheck;
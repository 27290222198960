class FeedBacksApi {
    async getPercentRecap(courses_id, turn,admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/feedback/percent/get/`
        const values = {
            "courses_id": courses_id,
            "turn": turn,
            "admin_id":admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    

    async createFeedback(courses_id, feedback_turn_1, feedback_turn_2, feedback_improvement, admin_id, auditName, firstname, lastname,typeFeedback,email) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/feedback/create/`
        const values = {
            "courses_id": courses_id,
            "feedback_turn_1": feedback_turn_1,
            "feedback_turn_2":feedback_turn_2,
            "feedback_improvement":feedback_improvement,
            "admin_id":admin_id,
            "audit_name":auditName,
            "firstname":firstname,
            "lastname":lastname,
            "email":email,
            "type":typeFeedback
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getFeedback(courses_id, admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/feedback/get/`
        const values = {
            "courses_id": courses_id,
            "admin_id":admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async determinateFeedback(courses_id, admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/feedback/determinate/`
        const values = {
            "courses_id": courses_id,
            "admin_id":admin_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async UpdateImprovements(origin, admin_id, improvement_id, type, data_change) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/improvement/update/`
        const values = {
            "origin": origin,
            "admin_id": admin_id,
            "improvement_id": improvement_id,
            "type":type,
            "data_change":data_change
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };


    async getPatientFolderAnswers(admin_id, patient_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/patients_folder/getAllPatientsFolderAnswers/`
        const values = {
            "admin_id": admin_id,
            "patient_id": patient_id
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };


    async getGeneratedFeedbacks(admin_id, course_id) {
        try {
            const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/feedback/get`;
            const values = {
                'course_id': course_id,
                'admin_id': admin_id
            }
            const accessToken = globalThis.localStorage.getItem('accessToken');
    
            const resp = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(values)
            });
    
            const json = await resp.json();
            return json;
        } catch (error) {
            console.error('Error fetching feedback:', error);
            throw error; // Propage l'erreur pour la gérer où la fonction est appelée
        }
    }
    
    async validateFeedback(admin_id, course_id, modification, turn, type_generated) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/feedback/validation`
        const values = {
            "admin_id": admin_id,
            "course_id": course_id,
            "isvalid": true,
            "modifcation": modification,
            "turn": turn,
            "type_generated": type_generated,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getCriteriasNonCompliance(course_id, turn) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/criteria_classification?course_id=${course_id}&turn=${turn}`
        // console.log("test id",course_id)
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async getCriteriasGaps(patents_id, turn) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/gap_cause/get?patients_id=${patents_id}&turn=${turn}`

        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };

    async setFeedbackScore(feedbackId, score, admin_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/rag_system/feedback/set-feedback-score`
        const values = {
            "feedbackId": feedbackId,
            "score": score,
            "admin_id": admin_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
    async deleteImprovement(admin_id,improvement_id) {
        const url = `${process.env.REACT_APP_BACK}/subdomain/${globalThis.localStorage.getItem('tenant')}/api/delete_improvement/`
        const values = {
            "improvement_id": improvement_id,
            "admin_id": admin_id,
        };
        const accessToken = globalThis.localStorage.getItem('accessToken');
        const resp = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`
            }
        });
        const json = await resp.json();
        return Promise.resolve(json);
    };
}

export const feedBacksApi = new FeedBacksApi();
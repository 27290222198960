import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
// import events from "./events.js";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect } from "react";
import "./styles.css";
import React, { useState } from "react";
import "moment/locale/fr";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { mt } from "date-fns/locale";
import { useAuth } from "../../../hooks/use-auth.js";
import { Modal, Box, Typography, Paper, Link } from "@mui/material";
import { useTheme } from "@material-ui/core/styles";
import Statistiques from "./elements/Statistiques.js";
import ListeAmeliorations from "./elements/ListeAmeliorations.js";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const StatistiquesAudit = () => {
  const theme = useTheme();
  const {tenant , auditId, startSession, endSession } = useParams();
  const { user } = useAuth();
  const [selectedTour, setSelectedTour] = useState(0);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1150px)").matches
  );
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(`tenant : ${tenant}`);
    if (tenant==globalThis.localStorage.getItem('tenant')){
      navigate(`/not-found`);
    }

    window
      .matchMedia("(min-width: 1150px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div
      style={{
        fontFamily: "Montserrat",
        paddingTop: "45px",
        backgroundColor: "white",
      }}
    >
      {/* <Box sx={{ mb: 4 }}>
        <RouterLink to=`/${globalThis.localStorage.getItem('tenant')}/dashboard/audits` style={{ textDecoration: "none" }}>
          <Link
            // color="textPrimary"
            component="a"
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <ArrowBackIcon fontSize="small" sx={{ mr: 1, color: "#000" }} />
            <Typography variant="subtitle2">Audits</Typography>
          </Link>
        </RouterLink>
      </Box> */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignContent: "end",
          marginTop: "1rem"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <ArrowBackIcon
            fontSize="medium"
            color="black"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
        </div>
        <h3
          style={{
            fontFamily: "Montserrat",
            // padding: "40px 0 0 20px",
            color: "black",
            background: "white",
          }}
        >
          AUDIT SESSION
        </h3>
      </div>
      <div
        style={{
          padding: "0 0 0 20px",
          background: "#9DA4C8",
          position: "relative",
          height: "70px",
        }}
      >
        <div
          style={{
            width: "280px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto",
            verticalAlign: "middle",
            height: "100%",
          }}
        >
          <p
            style={{
              backgroundColor: selectedTour == 0 ? "#6F78A7" : "#fff",
              color: selectedTour == 0 ? "#fff" : "",
              textAlign: "center",
              borderRadius: "1.5rem",
              minWidth: "100px",
              maxWidth: "100px",
              margin: "0 5px 0 5px",
              fontWeight: "bold",
              cursor: "pointer",
              padding: "5px 0",
            }}
            onClick={() => setSelectedTour(0)}
          >
            Tour 1
          </p>
          <p
            style={{
              backgroundColor: selectedTour == 1 ? "#6F78A7" : "#fff",
              color: selectedTour == 1 ? "#fff" : "",
              textAlign: "center",
              borderRadius: "1.5rem",
              minWidth: "100px",
              maxWidth: "100px",
              margin: "0 5px 0 5px",
              fontWeight: "bold",
              cursor: "pointer",
              padding: "5px 0",
            }}
            onClick={() => setSelectedTour(1)}
          >
            Tour 2
          </p>
        </div>
        <div
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: "#6F78A7",
            borderRadius: "100%",
            position: "absolute",
            top: 45,

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              margin: 0,
              paddingTop: "5px",
              color: "white",
              fontFamily: "Montserrat",
              fontWeight: "300",
            }}
          >
            {user.firstname[0]}
            {user.lastname[0]}
          </p>
        </div>
      </div>
      <div
        style={{ minHeight: "100vh", padding: 40, background: "#A2A0C14A" }}
        id="statisticsContainer"
      >
        <Statistiques
          matches={matches}
          auditId={auditId}
          selectedTour={selectedTour}
          start_session={startSession}
          end_session={endSession}
        />
        <ListeAmeliorations
          matches={matches}
          auditId={auditId}
          selectedTour={selectedTour}
          start_session={startSession}
          end_session={endSession}
        />
      </div>
    </div>
  );
};

export default StatistiquesAudit;

import {
  Box,
  Button,
  Card,
  Input,
  Divider,
  TextField,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Modal,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../../../hooks/use-auth";
import { Scrollbar } from "../../../components/scrollbar";
import { surveyApi } from "../../../api/survey-api";
import { useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { auditsApi } from "../../../api/audit-api";
import { Delete as DeleteIcon, FileCopy as DuplicateIcon } from '@mui/icons-material';
import { useMounted } from '../../../hooks/use-mounted';


export const SatisfactionQuestionnaireList = (props) => {
  const isMounted = useMounted();
  const { questionnaires, setQuestionnaires } = props;
  const { user } = useAuth();
  const [permissions, setPermissions] = useState([]);

  const [filteredQuestionnaires, setfilteredQuestionnaires] = useState([]);
  const [searchName, setSearchName] = useState([]);
  const [createSurveyName, setCreateSurveyName] = useState([]);
  const [selectedAudits, setSelectedAudits] = useState([]);
  const [audits, setAudits] = useState([]);
  const [isCreateSurveyModalOpen, setIsCreateSurveyModalOpen] = useState(false);
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  useEffect(() => {
    setfilteredQuestionnaires(questionnaires);
  }, [questionnaires]);

  const handleChangeSearch = (event) => {
    setSearchName(event.target.value);
    setfilteredQuestionnaires(
      questionnaires.filter((el) =>
        el.name.toLowerCase().includes(event.target.value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const fetchAudits = async () => {
      try {
        const data = await auditsApi.getAllAudits(user.admin_id)
        setAudits(data);
      } catch (error) {
        console.error("Erreur lors du chargement des audits", error);
      }
    };

    fetchAudits();
  }, []);

  const handleCreateSurvey = async (event) => {
    try {
      const id = await surveyApi.createSurvey(user.admin_id, createSurveyName, selectedAudits);
      if (id.msg) {
        setAlertMessage(id.msg);
        setIsCreateSurveyModalOpen(false);
        setCreateSurveyName("");
        setSelectedAudits([]);
      } else {
        navigate(`/${globalThis.localStorage.getItem('tenant')}/dashboard/satisfaction/${id.id}`);
      }
    } catch (error) {
      console.error("Erreur lors de la création du questionnaire", error);
    }
  };

  const getQuestionnaires = useCallback(async () => {
    try {
      const data = await surveyApi.getAllSurvey(user.admin_id)
      if (isMounted()) {
        setQuestionnaires(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMounted]);

  const handleDuplicateClick = (id) => {
    setSelectedQuestionnaireId(id);
    setDuplicateDialogOpen(true);
  };

  const handleDeleteClick = (id) => {
    setSelectedQuestionnaireId(id);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDuplicate  = async () => {
    try{
      const data = await surveyApi.DuplicateSurveyById(user.admin_id, selectedQuestionnaireId);
      getQuestionnaires()
      setDuplicateDialogOpen(false)
    } catch (error) {
      console.error("Erreur lors de la création du questionnaire", error);
    }
  }

  const handleConfirmDelete  = async () => {
    try{
      await surveyApi.DeleteSurveyById(user.admin_id, selectedQuestionnaireId);
      getQuestionnaires()
      setDeleteDialogOpen(false)
    } catch (error) {
      console.error("Erreur lors de la création du questionnaire", error);
    }
  }
  const getPermissionsByRole = async (roles_id) => {
    const url = `${process.env.REACT_APP_BACK}/subdomain/${localStorage.getItem('tenant')}/api/permission-verification/get/`;
    const values = { roles_id };
    const accessToken = localStorage.getItem("accessToken");

    try {
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify(values),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!resp.ok) {
        throw new Error(`HTTP error! Status: ${resp.status}`);
      }

      const json = await resp.json();
      setPermissions(json.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    if (user && user.roles_id) {
      getPermissionsByRole(user.roles_id);
    }
  }, [user]);


  const hasCreatePermission = permissions.some(
    (permission) => permission.component === "Satisfaction" && permission.permission === "Créer"
  );
  const hasDeletePermission = permissions.some(
    (permission) => permission.component === "Satisfaction" && permission.permission === "Supprimer"
  );

  const handleCancel = () => {
    setDuplicateDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  return (
    <>
      <Box
        sx={{
          m: 1,
          width: "95%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        { hasCreatePermission &&(
          <Button
            onClick={() => {
                setIsCreateSurveyModalOpen(true);
            }}
            variant="contained"
          >
            Créer un questionnaire de satisfaction
          </Button>
        )}
          <Box>
            <Modal
              open={isCreateSurveyModalOpen}
              onClose={() => {
                // Réinitialiser les états lorsque le modal se ferme
                setIsCreateSurveyModalOpen(false);
                setCreateSurveyName("");
                setSelectedAudits([]);
                }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: '50%',
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        value={createSurveyName}
                        onChange={(event) => setCreateSurveyName(event.target.value)}
                        label="Entrer le nom du questionnaire"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                        <InputLabel id="select-multiple-checkbox-label">EPP</InputLabel>
                        <Select
                            labelId="select-multiple-checkbox-label"
                            id="select-multiple-checkbox"
                            multiple
                            value={selectedAudits}
                            onChange={(event) => setSelectedAudits(event.target.value)}
                            renderValue={(selected) =>
                                selected
                                .map((auditId) =>
                                audits.find((audit) => audit.id === auditId)?.name || ""
                                )
                                .join('\n')
                            }
                        >
                            {audits.map((audit) => (
                            <MenuItem key={audit.id} value={audit.id}>
                                {audit.name}
                            </MenuItem>
                            ))}
                        </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            handleCreateSurvey();
                            setIsCreateSurveyModalOpen(false);
                        }}
                        >
                        Créer
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setIsCreateSurveyModalOpen(false);
                            setCreateSurveyName("");
                            setSelectedAudits([]);
                        }}
                        >
                        Annuler
                        </Button>
                    </Grid>
                    </Grid>
              </Box>
            </Modal>
          </Box>
      </Box>
      <Card {...props}>
        <Scrollbar>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              p: 2.5,
            }}
          >
            <SearchIcon fontSize="small" />
            <Box
              sx={{
                flexGrow: 1,
                ml: 3,
              }}
            >
              <Input
                color="secondary"
                disableUnderline
                fullWidth
                value={searchName}
                onChange={handleChangeSearch}
                placeholder="Chercher un questionnaire de satisfaction"
              />
            </Box>
          </Box>
          <Divider />
          <Table sx={{ minWidth: 700 }}>
            <TableBody>
              {questionnaires
                ? filteredQuestionnaires.map((questionnaire, index) => (
                    <TableRow 
                    hover 
                    key={index} 
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}>
                      <TableCell sx={{ width: '85%' }}>
                        <Box sx={{ ml: 3 }}>
                          <IconButton
                            component={RouterLink}
                            to={`/${globalThis.localStorage.getItem('tenant')}/dashboard/satisfaction/${questionnaire.id}`}
                          >
                            <Typography variant="h6" color="primary">
                              {questionnaire.name}
                            </Typography>
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell  sx={{ width: '15%' }}>
                      {hoveredRowIndex === index && hasCreatePermission && (
                        <>
                          <Tooltip title="Dupliquer le questionnaire">
                            <IconButton
                              onClick={() => handleDuplicateClick(questionnaire.id)}
                              color="primary"
                            >
                              <DuplicateIcon />
                            </IconButton>
                          </Tooltip>
                          </>
                      )}
                       {hoveredRowIndex === index && hasDeletePermission && (
                        <>
                          <Tooltip title="Supprimer le questionnaire">
                            <IconButton
                              onClick={() => handleDeleteClick(questionnaire.id)}
                              color="error"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                      </TableCell>
                    </TableRow>
                  ))
                : NULL}
            </TableBody>
            {/* Dialog pour la duplication */}
            <Dialog open={duplicateDialogOpen} onClose={handleCancel}>
              <DialogTitle>Confirmation de duplication</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir dupliquer ce questionnaire ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDuplicate} color="primary">
                  Dupliquer
                </Button>
              </DialogActions>
            </Dialog>

            {/* Dialog pour la suppression */}
            <Dialog open={deleteDialogOpen} onClose={handleCancel}>
              <DialogTitle>Confirmation de suppression</DialogTitle>
              <DialogContent>
                Êtes-vous sûr de vouloir supprimer ce questionnaire ?
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Annuler
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                  Supprimer
                </Button>
              </DialogActions>
            </Dialog>
          </Table>
        </Scrollbar>
      </Card>
      {alertMessage && (
        <Alert severity="error" onClose={() => setAlertMessage("")}>
          {alertMessage}
        </Alert>
      )}
    </>
  );
};

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

export const RecapSecondTurn = (props) => {
  const {
    dataSecondTurn,
    sessionAnalyseSecondTurn,
    globalAnalyseSecondTurn,
    turn,
    resultData,
    selectedCriteriaSecondTurn,
    setSelectedCriteriaSecondTurn
  } = props;

  const handleSelectCriteriaSecondTurn = (criteria) => {
    setSelectedCriteriaSecondTurn(criteria);
  };
  const groupedData = (dataSecondTurn?.respone_list || []).reduce((acc, e) => {
    if (e.criteria === selectedCriteriaSecondTurn?.number_criteria) {
      const lackFactor = e.criteria_gap?.[0]?.lack_factor; // Get the lack_factor
      if (!lackFactor) return acc; // Skip if undefined
  
      if (!acc[lackFactor]) acc[lackFactor] = [];
      acc[lackFactor].push(...(e.criteria_gap || []));
    }
    return acc;
  }, {});

  return (
    <>
    <Card sx={{ borderRadius: 1, mt: 2 }}>
      <CardContent>
        <Typography
          variant="h5"
          color={(theme) =>
            theme.palette.mode === "dark"
              ? "white"
              : process.env.REACT_APP_PRIMARY_COLOR
          }
          sx={{ mt: 1, mb: 1, ml: 2 }}
        >
          Analyse récapitulative
        </Typography>
        <Divider sx={{ my: 2 }} />
          {resultData && dataSecondTurn?.criterias?.length > 0 ? (
            <Box>
              <Grid
                container
                display="flex"
                spacing={2}
                marginTop={2}
                sx={{ justifyContent: "flex-start" }}
              >
                {/* Affichage des critères */}
                <Grid
                  item
                  xs={12} md={3} lg={3}
                  sx={{
                    overflowY: "auto",
                    // maxHeight: "300px",
                    // border: "1px solid #ccc",
                    // borderRadius: 2,
                  }}
                >
                  <Typography
                    variant="h6"
                    color={(theme) =>
                      theme.palette.mode === "dark"
                        ? "white"
                        : process.env.REACT_APP_PRIMARY_COLOR
                    }
                  >
                    Liste des critères
                  </Typography>
                  {/* <Box sx={{ margin: 1 }}>
                    {turn === 2 &&
                      (dataSecondTurn.length !== 0 &&
                      dataSecondTurn.criterias ? (
                        dataSecondTurn.criterias.map((criteria, index) => (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor:
                                selectedCriteriaSecondTurn === criteria
                                  ? "rgba(0, 0, 0, 0.1)"
                                  : "inherit",
                            }}
                          >
                            <Button
                              onClick={() =>
                                handleSelectCriteriaSecondTurn(criteria)
                              }
                            >
                              Critère {index + 1}
                            </Button>
                            <Divider />
                          </Box>
                        ))
                      ) : (
                        <Typography>
                          Pas de données pour le deuxième tour
                        </Typography>
                      ))}
                  </Box> */}
                  <Box sx={{ margin: 1 }}>
                    {turn === 2 && (
                      <Grid
                        container
                        display="flex"
                        spacing={2}
                        marginTop={2}
                        sx={{ justifyContent: "flex-start" }}
                      >
                        {/* Affichage des critères */}
                        {dataSecondTurn.criterias.map((criteria, index) => (
                          <Grid item xs={6} md={6} lg={6} key={index}>
                            <Box
                              sx={{
                                backgroundColor:
                                selectedCriteriaSecondTurn === criteria
                                    ? "rgba(0, 0, 0, 0.1)"
                                    : "inherit",
                              }}
                            >
                              <Button
                                onClick={() => handleSelectCriteriaSecondTurn(criteria)}
                              >
                                Critère {index + 1}
                              </Button>
                              <Divider />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    )}
                  </Box>
                </Grid>

                {/* Affichage des détails du critère sélectionné Tour 2 */}
                {selectedCriteriaSecondTurn && turn === 2 && (
                  <Grid item xs={12} md={9} lg={9}>
                    {/* <Card> */}
                      <Box sx={{ margin: 1 }}>
                        <Typography
                          variant="h6"
                          color={(theme) =>
                            theme.palette.mode === "dark"
                              ? "white"
                              : process.env.REACT_APP_PRIMARY_COLOR
                          }
                        >
                          Nom du critère :
                        </Typography>
                        <Typography>
                          {selectedCriteriaSecondTurn.personal.criteria}
                        </Typography>
                        <Typography variant="h6">Réponses :</Typography>
                        {/* Affichage des barres de progression */}
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            margin: 1,
                            marginTop: 2,
                          }}
                        >
                          <Box
                            sx={{
                              width: "30%",
                              height: "50%",
                              backgroundColor: "#f0f0f0",
                            }}
                            borderRadius="1px"
                            p={2}
                          >
                            <Typography variant="h6">Apprenant :</Typography>
                            <Typography
                              sx={{ color: process.env.REACT_APP_YES_COLOR }}
                            >
                              OUI : {selectedCriteriaSecondTurn.personal.oui} %
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={selectedCriteriaSecondTurn.personal.oui}
                              sx={{
                                height: "20px",
                                borderRadius: "3px",
                                "& .MuiLinearProgress-barColorPrimary": {
                                  backgroundColor:
                                    process.env.REACT_APP_YES_COLOR,
                                },
                              }}
                            />
                            <Typography
                              sx={{ color: process.env.REACT_APP_NO_COLOR }}
                            >
                              NON : {selectedCriteriaSecondTurn.personal.non} %
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={selectedCriteriaSecondTurn.personal.non}
                              sx={{
                                height: "20px",
                                borderRadius: "3px",
                                "& .MuiLinearProgress-barColorPrimary": {
                                  backgroundColor:
                                    process.env.REACT_APP_NO_COLOR,
                                },
                              }}
                            />
                            <Typography
                              sx={{ color: process.env.REACT_APP_PNC_COLOR }}
                            >
                              NA : {selectedCriteriaSecondTurn.personal.na} %
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={selectedCriteriaSecondTurn.personal.na}
                              sx={{
                                height: "20px",
                                borderRadius: "3px",
                                "& .MuiLinearProgress-barColorPrimary": {
                                  backgroundColor:
                                    process.env.REACT_APP_PNC_COLOR,
                                },
                              }}
                            />
                          </Box>
                          {/* Affichage des barres de progression */}
                          {sessionAnalyseSecondTurn.results &&
                            sessionAnalyseSecondTurn.results.map(
                              (result) =>
                                result.number ===
                                  selectedCriteriaSecondTurn?.number_criteria && (
                                  <Box
                                    key={result.id}
                                    sx={{
                                      backgroundColor: "#f0f0f0",
                                      width: "30%",
                                    }}
                                    borderRadius="1px"
                                    p={2}
                                  >
                                    <Typography variant="subtitle1">
                                      Session :
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_YES_COLOR,
                                      }}
                                    >
                                      OUI : {result.oui} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.oui}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_YES_COLOR,
                                          },
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_NO_COLOR,
                                      }}
                                    >
                                      NON : {result.non} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.non}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_NO_COLOR,
                                          },
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_PNC_COLOR,
                                      }}
                                    >
                                      NA : {result.na} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.na}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_PNC_COLOR,
                                          },
                                      }}
                                    />
                                  </Box>
                                )
                            )}
                          {/* Affichage des barres de progression */}
                          {globalAnalyseSecondTurn.results &&
                            globalAnalyseSecondTurn.results.map(
                              (result) =>
                                result.number ===
                                  selectedCriteriaSecondTurn?.number_criteria && (
                                  <Box
                                    key={result.id}
                                    sx={{
                                      backgroundColor: "#f0f0f0",
                                      width: "30%",
                                    }}
                                    borderRadius="1px"
                                    p={2}
                                  >
                                    <Typography variant="subtitle1">
                                      Total :
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_YES_COLOR,
                                      }}
                                    >
                                      OUI : {result.oui} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.oui}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_YES_COLOR,
                                          },
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_NO_COLOR,
                                      }}
                                    >
                                      NON : {result.non} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.non}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_NO_COLOR,
                                          },
                                      }}
                                    />
                                    <Typography
                                      sx={{
                                        color: process.env.REACT_APP_PNC_COLOR,
                                      }}
                                    >
                                      NA : {result.na} %
                                    </Typography>
                                    <LinearProgress
                                      variant="determinate"
                                      value={result.na}
                                      sx={{
                                        height: "20px",
                                        borderRadius: "3px",
                                        "& .MuiLinearProgress-barColorPrimary":
                                          {
                                            backgroundColor:
                                              process.env.REACT_APP_PNC_COLOR,
                                          },
                                      }}
                                    />
                                  </Box>
                                )
                            )}
                        </Grid>
                        {dataSecondTurn.respone_list
                       .filter((e) => e.criteria === selectedCriteriaSecondTurn.number_criteria).length>0 &&<Typography variant="h6">Causes de l'écart :</Typography>}
                       {/* { dataSecondTurn.respone_list
                       .filter((e) => e.criteria === selectedCriteriaSecondTurn.number_criteria)
                       .map((gap, i) => (
                        <Typography key={i} variant="h6">
                        facteur {gap.criteria_gap?.[0]?.lack_factor}
                        {gap.criteria_gap?.[0]?.lack_factor !== 'Autre' &&
                          ` : Manque de ${gap.criteria_gap?.[0]?.lack}`}
                      </Typography>
                            ))
                          } */}
                            {Object.entries(groupedData).map(([lackFactor, gaps], i) => 
                         (
                          <>    {lackFactor!== "Autre"?<>

      <Typography key={i}  color={process.env.REACT_APP_PRIMARY_COLOR}>  Cause {lackFactor} :</Typography>
      {gaps.map((gap, index) => (
        <ul key={index} style={{margin:"0px"}}>
          {gap.lack_factor !== "Autre" && ` Manque de ${gap.lack}`}
        </ul>
      ))}
</>:<Typography key={i} > Il y a d'autres causes non catégorisées</Typography>
}</>
    ))}
                      </Box>
                    {/* </Card> */}
                  </Grid>
                )}
              </Grid> 
            </Box>
          ) : (
            <Grid
              container
              spacing={2}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Aucun résultat trouvé
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                  Il n'y a pas de données disponibles pour le moment.
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
};